<template>
  <div
    class="w-full flex justify-center items-center h-full"
    v-if="isProjectDurationSettingsLoading || isProjectLoading"
  >
    <LoadingSpinner />
  </div>
  <div
    v-else-if="projectDurationSettings && project"
    class="flex flex-col gap-12 min-w-min 2xl:w-2/3 3xl:w-3/5"
  >
    <WorkingHours ref="workingHoursRef" :projectDurationSettings="projectDurationSettings" />
    <NonWorkingDays
      ref="nonWorkingDaysRef"
      :projectDurationSettings="projectDurationSettings"
      :project="project"
    />
  </div>
</template>

<script lang="ts" setup>
import { useProjectDurationSettings } from "oai-planner";
import { Form } from "vee-validate";
import { ref } from "vue";
import { NavigationGuard, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import NonWorkingDays from "@/components/working_days/NonWorkingDays.vue";
import { useLoadCurrentProject } from "@/composables/project";
import { useSaveBeforeLeaveConfirmationModal } from "@/composables/toast";
import { apiClient } from "@/repositories/clients";
import WorkingHours from "@/views/settings_project/components/WorkingHours.vue";

const { projectDurationSettings, isProjectDurationSettingsLoading } =
  useProjectDurationSettings(apiClient);
const { project, isProjectLoading } = useLoadCurrentProject();
const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();

const workingHoursRef = ref<{ form: typeof Form | null } | null>(null);
const nonWorkingDaysRef = ref<{ form: typeof Form | null } | null>(null);

const isDirty = () => {
  const workingHoursFormMeta = workingHoursRef.value?.form?.meta;
  const nonWorkingDaysFormMeta = nonWorkingDaysRef.value?.form?.meta;
  return workingHoursFormMeta?.dirty || nonWorkingDaysFormMeta?.dirty;
};

const navigationGuard: NavigationGuard = (to, from, next) => {
  if (!isDirty()) {
    return next();
  }
  showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
    if (confirmed) {
      next();
    }
  });
};

onBeforeRouteLeave(navigationGuard);
onBeforeRouteUpdate(navigationGuard);
</script>
