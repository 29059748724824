<template>
  <div
    class="fixed bottom-0 left-0 right-0 top-0 z-50 h-full w-full bg-gray-500/75 transition-opacity"
  >
    <div
      class="absolute left-1/2 top-1/2 flex items-center justify-center text-center text-lg text-white"
      style="transform: translate(-50%, -50%)"
    >
      <LoadingSpinner color="text-white" class="mr-3" />
      {{ $t("report.general.processing") }}...
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";

export default {
  name: "LoadingOverlay",
  components: {
    LoadingSpinner,
  },
};
</script>
