import axios from "axios";
import qs from "qs";
import { getConfig } from "@/services/config";

const config = getConfig();

const apiClient = axios.create({
  baseURL: config.API_BASE,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

export { apiClient };
