<template>
  <!-- Modal background -->
  <div
    class="fixed inset-0 z-10 overflow-y-auto bg-gray-500/75 transition-opacity"
    v-show="open"
    @click="!loading && closeModal()"
  >
    <div class="flex min-h-screen items-center justify-center p-4">
      <!-- Modal content -->
      <div class="relative mx-auto w-full max-w-3xl rounded-md bg-white shadow-lg" @click.stop>
        <!-- Modal header -->
        <div class="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 class="text-lg font-medium">{{ $t("report.bulk_export.title") }}</h2>
          <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
            <button
              type="button"
              class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              @click="!loading && closeModal()"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div v-if="!loading">
          <!-- Modal body -->
          <div class="z-20 px-12 py-10 text-center" @click="error = false">
            <h3 class="sm:pb-4 pb-2 sm:text-lg font-medium leading-6 text-gray-900">
              {{ $t("report.bulk_export.main_label") }}
            </h3>
            <div class="grid">
              <VueDatePicker
                input-class-name="dp-custom-input"
                menu-class-name="dp-custom-menu"
                v-model="dateRange"
                :columns="4"
                :enable-time-picker="false"
                :placeholder="`${$t('report.bulk_export.date_range_placeholder')}`"
                :year-range="yearRange"
                :format="format"
                model-type="yyyy-MM-dd"
                :locale="lang"
                auto-apply
                reverse-years
                range
                :preset-ranges="presetRanges"
                :max-range="dayLimit"
                :max-date="maxDate"
                :min-date="minDate"
                @internal-model-change="adjustMinMaxDate($event)"
                @cleared="clearMinMax"
              >
                <template>
                  <span @click="presetDateRange(range)">{{ label }}</span>
                </template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Export Selection -->
          <div class="w-full border-t py-8 text-center" @click="error = false">
            <h3 class="sm:pb-4 pb-2 sm:text-lg font-medium leading-6 text-gray-900">
              {{ $t("report.bulk_export.file_type_label") }}
            </h3>
            <div class="flex justify-center">
              <RadioGroup v-model="selectedExportOption" class="mt-2">
                <div class="grid grid-cols-2 gap-8">
                  <RadioGroupOption
                    as="template"
                    v-for="option in exportOptions"
                    :key="option.name"
                    :value="option"
                    v-slot="{ checked }"
                  >
                    <div
                      :class="[
                        checked
                          ? 'relative border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white'
                          : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                        'focus:outline-non flex cursor-pointer items-center justify-center rounded-md border sm:px-8 px-4 py-3 sm:text-sm text-xs sm:flex-1',
                      ]"
                    >
                      <CheckCircleIcon
                        :class="[
                          !checked ? 'invisible' : '',
                          'absolute left-0 top-0 sm:h-5 sm:w-5 h-4 w-4 text-yellow-600',
                        ]"
                        aria-hidden="true"
                      />
                      <RadioGroupLabel as="span"
                        >{{ $t(`report.bulk_export.${option.name}`) }}
                      </RadioGroupLabel>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>

          <!-- Export Selection -->
          <div class="w-full border-t sm:py-8 py-4 text-center" @click="error = false">
            <h3 class="sm:pb-4 pb-2 sm:text-lg font-medium sm:leading-6 text-gray-900">
              {{ $t("report.bulk_export.status_type_label") }}
            </h3>
            <div class="flex justify-center">
              <RadioGroup v-model="selectedStatusOption" class="mt-2">
                <div class="grid grid-cols-2 gap-8">
                  <RadioGroupOption
                    as="template"
                    v-for="option in statusOptions"
                    :key="option.name"
                    :value="option"
                    v-slot="{ checked }"
                  >
                    <div
                      :class="[
                        checked
                          ? 'relative border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white'
                          : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                        'focus:outline-non flex cursor-pointer items-center justify-center rounded-md border m:px-8 px-4 py-3 sm:text-sm text-xs sm:flex-1',
                      ]"
                    >
                      <CheckCircleIcon
                        :class="[
                          !checked ? 'invisible' : '',
                          'absolute left-0 top-0 sm:h-5 sm:w-5 h-4 w-4 text-yellow-600',
                        ]"
                        aria-hidden="true"
                      />
                      <RadioGroupLabel as="span"
                        >{{ $t(`report.bulk_export.${option.name}`) }}
                      </RadioGroupLabel>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>

          <div class="flex items-center justify-center border-t border-gray-200 px-4 py-8">
            <button
              type="button"
              @click="bulkExport()"
              :disabled="isInputEmpty"
              :class="[
                'animated-button rounded-md px-3 py-2 text-white',
                { 'pointer-events-none bg-gray-300': isInputEmpty, 'bg-yellow-600': !isInputEmpty },
              ]"
            >
              <ArrowDownTrayIcon class="m-0 box-border block h-6 w-6 w-full shrink-0" />
              <span>{{ $t("buttons.bulk_export") }}</span>
            </button>
          </div>
        </div>
        <div v-else class="h-64 flex items-center justify-center">
          <LoadingSpinner size="w-6 h-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { XMarkIcon, ArrowDownTrayIcon, CheckCircleIcon } from "@heroicons/vue/20/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  subDays,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { saveAs } from "file-saver";
import { ref } from "vue";
import "@/assets/styles/datePicker.css";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { useTrackEvent } from "@/composables/tracking";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import logger from "@/services/logger";
import textService from "@/services/textService";

export default {
  components: {
    LoadingSpinner,
    XMarkIcon,
    ArrowDownTrayIcon,
    VueDatePicker,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
  },
  props: ["open"],
  emits: ["onCloseModal"],
  data() {
    return {
      dateRange: [],
      exportOptions: [{ name: "pdf" }, { name: "zip" }],
      statusOptions: [
        { name: "approved_reports", value: "APPROVED" },
        { name: "all_reports", value: "ALL" },
      ],
      selectedExportOption: {},
      selectedStatusOption: {},
      format: "dd.MM.yyyy",
      lang: localStorage.getItem("lang") || "de",
      presetRanges: [],
      minDate: "",
      maxDate: new Date(),
      dayLimit: 31,
      loading: false,
    };
  },
  mounted() {
    this.presetRanges = ref([
      // {label: this.$t('report.bulk_export.today'), range: [new Date(), new Date()]},
      {
        label: this.$t("report.bulk_export.this_week"),
        range: [startOfWeek(new Date()), new Date()],
      },
      {
        label: this.$t("report.bulk_export.last_week"),
        range: [startOfWeek(subWeeks(new Date(), 1)), endOfWeek(subWeeks(new Date(), 1))],
      },
      {
        label: this.$t("report.bulk_export.last_30_days"),
        range: [subDays(new Date(), 29), new Date()],
      },

      {
        label: this.$t("report.bulk_export.this_month"),
        range: [startOfMonth(new Date()), new Date()],
      },
      {
        label: this.$t("report.bulk_export.last_month"),
        range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
      },
    ]);
  },

  computed: {
    yearRange() {
      const end = new Date().getFullYear();
      const start = end - 5;

      return [start, end];
    },
    isInputEmpty() {
      return (
        !this.dateRange ||
        !this.dateRange.length ||
        !Object.keys(this.selectedExportOption).length ||
        !Object.keys(this.selectedStatusOption).length
      );
    },
  },

  methods: {
    closeModal() {
      this.dateRange = [];
      this.selectedExportOption = {};
      this.selectedStatusOption = {};
      this.clearMinMax();
      this.$emit("onCloseModal");
    },
    clearMinMax() {
      this.maxDate = new Date();
      this.minDate = "";
    },
    adjustMinMaxDate(event) {
      try {
        if (event !== null) {
          if (event.length === 1) {
            const minDate = structuredClone(event[0]);
            const maxDate = structuredClone(event[0]);
            const dateOffset = 24 * 60 * 60 * 1000 * this.dayLimit;
            minDate.setTime(minDate.getTime() - dateOffset);
            maxDate.setTime(maxDate.getTime() + dateOffset);

            const today = new Date();
            if (maxDate.getTime() > today.setHours(0, 0, 0, 0)) {
              this.maxDate = new Date();
            } else {
              this.maxDate = maxDate;
            }

            this.minDate = minDate;
          }
          if (event.length === 2) {
            this.clearMinMax();
          }
        }
      } catch (error) {
        logger.error(error);
      }
    },
    bulkExport() {
      if (this.dateRange.length !== 2) {
        return;
      }

      if (Object.keys(this.selectedExportOption).length === 0) {
        return;
      }

      if (Object.keys(this.selectedStatusOption).length === 0) {
        return;
      }
      this.loading = true;
      this.trackEvent("dcr_export-bulk_click");

      const responseType = this.selectedExportOption.name === "zip" ? "arraybuffer" : "json";

      return DailyReportRepository.exportDailyReportBulk(
        this.currentCustomerName,
        this.currentSiteId,
        this.dateRange[0],
        this.dateRange[1],
        this.lang,
        this.selectedExportOption.name,
        this.selectedStatusOption.value,
        "2",
        responseType,
      )
        .then((response) => {
          if (response.status === 200) {
            const baseFileName = `oculai_${textService.toNormalizedFileName(
              `${this.$t("report.title")}_${this.dateRange[0]}_${this.dateRange[1]}`,
            )}`;

            if (this.selectedExportOption.name === "zip") {
              const blob = new Blob([response.data], { type: "application/zip" });
              const fileName = `${baseFileName}.zip`;
              saveAs(blob, fileName);
            }
            if (this.selectedExportOption.name === "pdf") {
              const a = document.createElement("a"); //Create <a>
              a.href = "data:application/pdf;base64," + response.data["report"];
              a.download = `${baseFileName}.pdf`;
              a.click(); //Downloaded file
            }
          }
          if (response.status === 204) {
            this.showToast("warning", this.$t("err.export.no_reports_found"));
          }
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
          if (!this.error) {
            this.closeModal();
          }
        });
    },
  },
  setup() {
    const trackEvent = useTrackEvent();
    return {
      trackEvent,
    };
  },
};
</script>
