import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-3 sm:w-full" }
const _hoisted_2 = { class: "text-lg font-medium leading-6 text-gray-900 text-center" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "flex sm:flex-row flex-col justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_BasicModal = _resolveComponent("BasicModal")!

  return (_openBlock(), _createBlock(_component_BasicModal, {
    open: _ctx.open,
    customClass: "xl:w-4/12 md:w-3/5 w-full",
    onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("report.general.old_data_title")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "date",
              max: "9999-12-12T00:00:00.00",
              id: "searchDate",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchDate) = $event)),
              class: "sm:w-1/2 form-control block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-xs border-gray-300 rounded-md sm:mr-2"
            }, null, 512), [
              [_vModelText, _ctx.searchDate]
            ]),
            _createVNode(_component_MainButton, {
              label: _ctx.$t('buttons.copy'),
              type: "button",
              color: "yellow",
              class: "sm:mr-0",
              onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('copySectionData', _ctx.searchDate)))
            }, null, 8, ["label"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}