<template>
  <NavigationComponent :activeTag="$t('app_features.daily_report')">
    <div class="h-full">
      <PageHeader
        >{{ $t("report.title") }}
        <template #content>
          <div class="flex items-center">
            <router-link
              :to="{
                name: 'ProjectSettings',
                params: {
                  customer_name: $route.params.customer_name,
                  site_id: $route.params.site_id,
                  tab: 'daily_report',
                },
              }"
            >
              <Cog8ToothIcon
                class="md:h-8 md:w-8 w-6 block m-0 box-border shrink-0 text-gray-700 hover:text-orange-700"
              />
            </router-link>
          </div> </template
      ></PageHeader>
      <div v-if="!isLoading">
        <BulkExport :open="openExport" @onCloseModal="openExport = false" />
        <div class="md:px-6 px-4 mx-auto">
          <CalendarMonth
            v-if="year && month"
            :days="days"
            :currentMonth="month"
            :currentYear="year"
            @bulkExport="openExport = !openExport"
            @nextMonth="nextMonth"
            @prevMonth="prevMonth"
            @resetPag="resetPag"
            @initializeReport="initializeReport"
            @deleteReport="deleteReport"
          />
        </div>
      </div>
      <LoadingSection v-else :loading="isLoading" />
    </div>
  </NavigationComponent>
</template>
<script lang="ts">
import { Cog8ToothIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import LoadingSection from "@/components/loading_state/LoadingSection.vue";
import { useCurrentProject } from "@/composables/project";
import { useTrackEvent } from "@/composables/tracking";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import logger from "@/services/logger";
import { OverviewItem } from "@/types/DailyReport";
import BulkExport from "@/views/daily_report/components/BulkExport.vue";
import CalendarMonth from "@/views/daily_report/components/CalendarMonth.vue";

export default defineComponent({
  setup() {
    const project = useCurrentProject();
    const trackEvent = useTrackEvent();
    return { project, trackEvent };
  },
  name: "DailyReportOverview",
  data() {
    return {
      days: [] as OverviewItem[],
      openExport: false,
      isLoading: false,
    };
  },
  mounted() {
    this.trackEvent("dcr_overview_view");
    this.getDailyReportOverview();
  },
  components: {
    PageHeader,
    NavigationComponent,
    CalendarMonth,
    BulkExport,
    LoadingSection,
    Cog8ToothIcon,
  },

  computed: {
    today() {
      return new Date();
    },
    date() {
      return String(this.today.getDate()).padStart(2, "0");
    },
    year() {
      return Number(this.$route.query.year as string) || null;
    },
    month() {
      return Number(this.$route.query.month as string) || null;
    },
  },
  watch: {
    year(newValue) {
      if (!newValue) {
        this.getDailyReportOverview();
      }
    },
  },
  methods: {
    getDailyReportOverview() {
      this.isLoading = true;

      return DailyReportRepository.getDailyReportOverview(
        this.currentCustomerName,
        this.currentSiteId,
        this.year,
        this.month,
      )
        .then((data) => {
          this.days = data.entries;
          if (!this.year && !this.month) {
            this.$router.replace({
              query: { ...this.$route.query, year: data.year, month: data.month },
            });
          }
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async nextMonth() {
      if (!this.year || !this.month) {
        return;
      }
      let year = this.year;
      let month = this.month;
      if (month === 12) {
        year = year + 1;
        month = 1;
      } else {
        month = month + 1;
      }
      await this.$router.replace({
        query: { ...this.$route.query, year: year, month: month },
      });

      this.trackEvent("dcr_overview_paginate_click");
      this.getDailyReportOverview();
    },

    async prevMonth() {
      if (!this.year || !this.month) {
        return;
      }
      let year = this.year;
      let month = this.month;
      if (month === 1) {
        year = year - 1;
        month = 12;
      } else {
        month = month - 1;
      }
      await this.$router.replace({
        query: { ...this.$route.query, year: year, month: month },
      });

      await this.trackEvent("dcr_overview_paginate_click");
      this.getDailyReportOverview();
    },

    async resetPag() {
      await this.$router.replace({
        query: {},
      });
      this.trackEvent("dcr_overview_paginate_click");
      this.getDailyReportOverview();
    },

    initializeReport(date: string) {
      const { customer_name, site_id } = this.$route.params;

      return DailyReportRepository.initializeDailyReport(
        customer_name as string,
        site_id as string,
        date,
      )
        .then((data) => {
          const item = this.days.find((item) => item.date === date);
          if (item) {
            item.data = data;
          }
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    deleteReport(date: string) {
      const { customer_name, site_id } = this.$route.params;

      return DailyReportRepository.deleteDailyReport(
        customer_name as string,
        site_id as string,
        date,
      )
        .then(() => {
          const item = this.days.find((item) => item.date === date);
          if (item) {
            item.data.report.status = "NOT_AVAILABLE";
          }
        })
        .catch((error) => {
          logger.error(error);
        });
    },
  },
});
</script>

<style></style>
