<template>
  <Listbox
    as="div"
    v-model="selected"
    :style="{ minWidth: `${minWidth}px` }"
    :multiple="multiple"
    v-bind="controller"
    :disabled="readonly"
  >
    <ListboxLabel class="text-sm" v-if="label">{{ label }}</ListboxLabel>
    <div class="relative">
      <ListboxButton
        :class="[inputFieldClass, readonly ? 'bg-gray-50 cursor-default' : 'bg-white']"
      >
        <span
          class="block truncate"
          :class="{
            'text-gray-400':
              !options.find((type) => type.value === selected || type.value === controller.value) &&
              placeholder,
          }"
          >{{
            multiple
              ? placeholder
              : options.find((type) => type.value === selected || type.value === controller.value)
                  ?.name || placeholder
          }}</span
        >
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-full"
        >
          <ListboxOption
            as="template"
            v-for="option in options"
            :key="option.value"
            :value="option.value"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                (active || selected) && 'bg-yellow-100',
                'relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900',
              ]"
              @click="handleDisplayTypeChange(option.value)"
            >
              <span class="block truncate font-normal">{{ option.name }}</span>

              <span
                v-if="selected && multiple"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span></li
          ></ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/vue/24/outline";
import { ref, PropType, watch } from "vue";

const props = defineProps({
  options: {
    type: Array as unknown as PropType<{ value: string; name: string }[]>,
    required: true,
  },
  label: {
    type: String,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  defaultSelected: {
    type: [String, Array] as unknown as PropType<string | string[]>,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  controller: {
    type: Object,
    default: () => ({}),
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: Number,
    required: false,
    default: 180,
  },
  inputFieldClass: {
    type: String,
    default:
      "relative rounded-md py-1.5 pl-3 pr-10 text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full",
  },
});

const emits = defineEmits(["update:selected"]);
const selected = ref(props.defaultSelected);

watch(
  () => props.defaultSelected,
  (value) => {
    selected.value = value;
  },
);

const handleDisplayTypeChange = (value: string) => {
  if (props.multiple) {
    if (selected.value.includes(value)) {
      selected.value = (selected.value as string[]).filter((item) => item !== value);
    } else {
      selected.value = [...selected.value, value];
    }
  } else {
    selected.value = value;
  }

  emits("update:selected", selected.value);
};
</script>
