<template>
  <div v-if="loading" class="absolute inset-0 bg-white opacity-60" style="z-index: 9999">
    <LoadingSection :loading="loading" />
  </div>
  <SlideOver
    :open="open"
    @closed="!isConfirmationModalOpen && !galleryDownloadModalOpen && $emit('closed')"
    maxWidth="1000px"
  >
    <template #title> {{ resourceFullName }} </template>
    <template #content>
      <div class="border-b pt-2 pb-4 my-3 border-gray-500">
        <div
          v-for="event in sortedEvents"
          :key="event.id"
          class="py-1 flex items-center gap-1 justify-between flex-wrap"
        >
          <div
            :class="[
              event.getData('type') === 'planned' ? 'plannedBg' : 'bg-green',
              'whitespace-nowrap rounded-lg px-2 py-0.5 my-1 text-sm text-white font-normal',
            ]"
          >
            {{
              event.getData("type") === "planned"
                ? $t("analytics.planner.target_period")
                : $t("analytics.planner.actual_period")
            }}
          </div>
          <div class="flex text-sm text-gray-600 font-normal">
            <div v-if="event.startDate">
              {{
                event.startDate
                  ? format(event.getData("startDate"), "dd.MM.yyyy")
                  : $t("analytics.planner.not_started_event")
              }}
            </div>
            <span class="px-1">-</span>
            <div v-if="event.endDate">
              {{ event.endDate ? format(event.getData("endDate"), "dd.MM.yyyy") : "" }}
            </div>
            <div>
              &nbsp;({{
                `${formatNumber(getWorkingDays(event))} ${$t(
                  "analytics.planner.working_days_label",
                  getWorkingDays(event),
                )}`
              }})
            </div>
          </div>
        </div>
      </div>
      <div class="py-4">
        <PlannerComments
          :sourceId="sourceId"
          :key="plannerCommentsKey"
          @confirmationModalIsOpen="isConfirmationModalOpen = true"
          @confirmationModalIsClosed="isConfirmationModalOpen = false"
          @commentAdded="$emit('commentAdded', $event)"
          @commentDeleted="$emit('commentDeleted', $event)"
        />
      </div>
      <div v-if="cameraList.length && actualEvent">
        <div v-for="camera in cameraList" :key="camera" class="my-6 border-t py-4">
          <div class="flex justify-between items-center">
            <h2 class="font-bold text-md">{{ $t("app_features.compare") }}</h2>
            <button
              @click="
                galleryDownloadStreamsToInitialze = [camera];
                galleryDownloadModalOpen = true;
              "
            >
              <ArrowDownTrayIcon class="h-6" />
            </button>
          </div>
          <ImagesComparison
            :camera="camera"
            :initialAfterDate="initialAfterDate"
            :initialBeforeDate="initialBeforeDate"
          />
        </div>
      </div>
      <div class="rounded-md p-4 bg-yellow-100" v-else-if="actualEvent">
        <div class="flex">
          <div class="shrink-0">
            <component
              class="h-5 w-5 text-yellow-400"
              aria-hidden="true"
              :is="InformationCircleIcon"
            />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">
              {{ $t("err.planner.no_camera_per_item") }}
            </h3>
          </div>
        </div>
      </div>
      <GalleryDownload
        v-if="galleryDownloadModalOpen"
        :streamsToInitialize="galleryDownloadStreamsToInitialze"
        :dateRangeToInitialize="
          initialBeforeDate && initialAfterDate ? [initialBeforeDate, initialAfterDate] : undefined
        "
        @close="galleryDownloadModalOpen = false"
      ></GalleryDownload>
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { EventModel } from "@bryntum/schedulerpro";
import { ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { durationService } from "oai-planner";
import { defineComponent, PropType } from "vue";
import LoadingSection from "@/components/loading_state/LoadingSection.vue";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import { useProcessClasses } from "@/composables/process";
import { ProjectDurationSettings } from "@/types/ProjectDurationSettings";
import GalleryDownload from "@/views/camera/components/GalleryDownload.vue";
import PlannerComments from "@/views/planner/components/PlannerComments.vue";
import SlideOver from "@/views/planner/components/SlideOver.vue";

export default defineComponent({
  name: "ActualSideBar",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    cameraList: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    resourceFullName: {
      type: String,
      required: true,
    },
    events: {
      type: Array as PropType<Array<EventModel>>,
    },
    sourceId: {
      type: String as PropType<string>,
      required: true,
    },
    projectDurationSettings: {
      type: Object as PropType<ProjectDurationSettings>,
      required: false,
    },
  },
  emits: ["closed", "commentAdded", "commentDeleted"],
  components: {
    PlannerComments,
    LoadingSection,
    ImagesComparison,
    SlideOver,
    ArrowDownTrayIcon,
    GalleryDownload,
  },
  data() {
    return {
      videoUrls: {} as Record<string, string>,
      videoUrlLoading: false as boolean,
      selectedProcessIndex: 0 as number,
      processTabSkip: 0 as number,
      hasDailyReportPermission: false as boolean,
      loading: false as boolean,
      actualEvent: {} as EventModel,
      galleryDownloadModalOpen: false as boolean,
      galleryDownloadStreamsToInitialze: [] as string[],
      isConfirmationModalOpen: false as boolean,
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    return {
      processClasses,
    };
  },
  watch: {
    events(value) {
      if (value) {
        this.actualEvent = this.events?.find(
          (item) => item.getData("type") === "actual",
        ) as EventModel;
      }
    },
  },

  computed: {
    encodedLabelsToDecodedLabel() {
      return this.processClasses.reduce((acc, processClass) => {
        acc[processClass.encodedLabel] = processClass.decodedLabel;

        return acc;
      }, {} as Record<string, string>);
    },
    initialBeforeDate() {
      return this.actualEvent ? format(this.actualEvent.getData("startDate"), "yyyy-MM-dd") : "";
    },
    initialAfterDate() {
      return this.actualEvent ? format(this.actualEvent.getData("endDate"), "yyyy-MM-dd") : "";
    },
    sortedEvents() {
      if (this.events?.length === 1) {
        return this.events;
      }
      let temp = [] as EventModel[];
      if (this.events && this.events?.length > 1) {
        temp = [this.events[1], this.events[0]];
      }

      return temp;
    },
    plannerCommentsKey() {
      return `${this.currentCustomerName}-${this.currentSiteId}-${this.sourceId},
      )}`;
    },
    durationSettings() {
      return this.projectDurationSettings
        ? durationService.calculateSettings(
            this.projectDurationSettings.working_hours,
            this.projectDurationSettings.non_working_days,
          )
        : null;
    },
  },

  methods: {
    InformationCircleIcon,
    format,
    getWorkingDays(event: EventModel) {
      if (!this.durationSettings) {
        return 0;
      }
      return durationService.calculateDuration(
        this.durationSettings,
        event.startDate as Date,
        event.endDate as Date,
      ).workingDays;
    },
    formatNumber(value: number) {
      return value.toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      });
    },
  },
});
</script>

<style scoped>
#headlessui-dialog-panel-10 {
  @apply max-w-2xl;
}
</style>
