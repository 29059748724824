import { PlanConfig, PlanConfigWithoutIndex } from "oai-planner";
import { ShortenedProcess } from "@/types/Process";
import { ReportSummaryContext } from "@/types/Report";
import {
  ReportDateFilter,
  ReportLocationFilter,
  ReportProcessFilter,
  ReportUnitValueTypeFilter,
} from "@/types/reports/filters";

export type QueryValueMetric =
  | "working_hours"
  | "unit_value"
  | "utilization"
  | "productive_days"
  | "active_days"
  | "inactive_days"
  | "delta_planner"
  | "project_progress";

export type QueryValueAggregation = "sum" | "average" | "median" | "latest";

export type QueryValueFormattingRuleOperator =
  | "greater"
  | "greater_or_equal"
  | "lesser"
  | "lesser_or_equal"
  | "equal";

export type QueryValueFormattingRuleAction =
  | "yellow_background"
  | "red_background"
  | "green_background";

export type QueryValueReportFilters<TDate = Date> = {
  location: ReportLocationFilter;
  processes: ReportProcessFilter;
  unit_value_type: ReportUnitValueTypeFilter;
  daterange: ReportDateFilter<TDate>;
};

export type QueryValueFormattingRule = {
  operator: QueryValueFormattingRuleOperator;
  value: number | null;
  action: QueryValueFormattingRuleAction;
};

export type QueryValueReportConfig = {
  metric: QueryValueMetric;
  show_previous_period: boolean;
  formatting_rules: QueryValueFormattingRule[];
};

export type QueryValueReportSummarySingleValue<TDate = Date> = {
  type: "single_value";
  value: number | null;
  value_text?: string;
  previous_period_value: number | null;
  context: ReportSummaryContext<TDate>;
};

export type QueryValueReportSummaryProcessesValue<TDate = Date> = {
  type: "processes";
  processes: ShortenedProcess<TDate>[];
  previous_period_processes: ShortenedProcess<TDate>[];
  context: ReportSummaryContext<TDate>;
};

export type QueryValueReportSummaryPlanValue<TDate = Date> = {
  type: "plan";
  plan: TDate extends string ? PlanConfigWithoutIndex<string> : PlanConfig<TDate>;
  context: ReportSummaryContext<TDate>;
};

export type QueryValueReportSummary<TDate = Date> =
  | QueryValueReportSummarySingleValue<TDate>
  | QueryValueReportSummaryProcessesValue<TDate>
  | QueryValueReportSummaryPlanValue<TDate>;

export const isQueryValueReportSummarySingleValue = <TDate>(
  reportSummary: QueryValueReportSummary<TDate>,
): reportSummary is QueryValueReportSummarySingleValue<TDate> =>
  reportSummary.type === "single_value";

export const isQueryValueReportSummaryProcessesValue = <TDate>(
  reportSummary: QueryValueReportSummary<TDate>,
): reportSummary is QueryValueReportSummaryProcessesValue<TDate> =>
  reportSummary.type === "processes";

export const isQueryValueReportSummaryPlanValue = <TDate>(
  reportSummary: QueryValueReportSummary<TDate>,
): reportSummary is QueryValueReportSummaryPlanValue<TDate> => reportSummary.type === "plan";
