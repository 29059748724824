import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { computed } from "vue";
import { useStore } from "vuex";
import ProjectRepository, { ProjectToUpdateAsProjectAdmin } from "@/repositories/ProjectRepository";
import { Project, ProjectFeature, ProjectThumbnailUrl } from "@/types/Project";

export const useCurrentProject = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const store = useStore();

  return (store.state.projects as Project[] | undefined)?.find(
    (project) => project.customer_name === customerName && project.site_id === siteId,
  ) as Project;
};

export const useHasProjectFeature = (feature: ProjectFeature) => {
  const project = useCurrentProject();
  if (!project) {
    return false;
  }
  return project.features.some((item) => item === feature);
};

export const useLoadCurrentProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data: project, isLoading: isProjectLoading } = useQuery<Project | null>({
    queryKey: ["project", currentCustomerName, currentSiteId],
    queryFn: () => ProjectRepository.loadProject(currentCustomerName, currentSiteId),
  });

  return { project, isProjectLoading };
};

export const useUpdateCurrentProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateProject,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<Project, Error, ProjectToUpdateAsProjectAdmin>({
    mutationFn: (projectToUpdate) =>
      ProjectRepository.updateProjectAsProjectAdmin(
        currentCustomerName,
        currentSiteId,
        projectToUpdate,
      ),
    onSuccess: (project) => {
      queryClient.setQueryData(["project", currentCustomerName, currentSiteId], project);
    },
  });

  return { updateProject, isUpdateLoading, isUpdateError };
};

export const useLogoUrl = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const {
    data,
    isLoading: isLogoUrlLoading,
    isFetching: isLogoUrlFetching,
  } = useQuery<string | null>({
    queryKey: ["project-logo-url", currentCustomerName, currentSiteId],
    queryFn: () => ProjectRepository.loadLogoUrl(currentCustomerName, currentSiteId),
    staleTime: 2 * 60 * 60 * 1000,
    refetchOnMount: true,
  });

  const logoUrl = computed(() => data.value ?? null);

  return { logoUrl, isLogoUrlLoading, isLogoUrlFetching };
};

export const useDeleteLogo = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const {
    mutate: deleteLogo,
    isLoading: isLogoDeleting,
    isError: isDeleteError,
  } = useMutation<void, Error>({
    mutationFn: () => ProjectRepository.deleteLogo(currentCustomerName, currentSiteId),
    onSuccess: () => {
      queryClient.setQueryData(["project-logo-url", currentCustomerName, currentSiteId], null);
    },
  });

  return { deleteLogo, isLogoDeleting, isDeleteError };
};

export const useUploadLogo = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const {
    mutate: uploadLogo,
    isLoading: isLogoUploading,
    isError: isLogoUploadError,
  } = useMutation<string, Error, string>({
    mutationFn: (fileString) =>
      ProjectRepository.uploadLogo(currentCustomerName, currentSiteId, fileString),
    onSuccess: (logoUrl) => {
      queryClient.setQueryData(["project-logo-url", currentCustomerName, currentSiteId], logoUrl);
    },
  });
  return { uploadLogo, isLogoUploading, isLogoUploadError };
};

export const useThumbnailUrls = () => {
  const { data, isLoading } = useQuery<ProjectThumbnailUrl[]>({
    queryKey: ["project-thumbnail-urls"],
    queryFn: () => ProjectRepository.loadThumbnailUrls(),
  });

  const projectThumbnailUrls = computed(() => data.value || []);

  return { projectThumbnailUrls, isLoading };
};
