<template>
  <ReportFormSection number="2" :label="t('analytics.reports.metric')">
    <SelectList
      :options="metricOptions"
      :defaultSelected="config.metric"
      :onUpdate:selected="handleMetricChange"
    />
  </ReportFormSection>
  <ReportFormSection
    number="3"
    :label="t('analytics.reports.filters')"
    v-if="filterKeys.length > 0"
  >
    <div class="flex gap-2 flex-col">
      <ReportLocationFilterField
        v-if="filterKeys.includes('location')"
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
      <ReportProcessFilterField
        v-if="filterKeys.includes('processes')"
        :filter="filters.processes"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, processes: $event } })"
      />
      <ReportDateFilterField
        v-if="filterKeys.includes('daterange')"
        :filter="filters.daterange"
        @change="emit('change', { filters: { ...filters, daterange: $event } })"
      />
      <ReportUnitValueTypeFilterField
        v-if="filterKeys.includes('unit_value_type')"
        :filter="filters.unit_value_type"
        @change="emit('change', { filters: { ...filters, unit_value_type: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection
    :key="config.metric"
    :number="numberAfterFilter"
    :label="t('analytics.reports.aggregation')"
    :defaultOpen="config.metric === 'unit_value'"
  >
    <SelectList
      :options="aggregationOptions"
      :defaultSelected="aggregation"
      :onUpdate:selected="handleAggregationChange"
    />
  </ReportFormSection>
  <ReportFormSection
    :number="numberAfterFilter + 1"
    :label="t('analytics.reports.query_value_formatting_rules.header')"
    :defaultOpen="false"
  >
    <QueryValueFormattingRules
      :metric="config.metric"
      :rules="config.formatting_rules"
      @update="emit('change', { config: { ...props.config, formatting_rules: $event } })"
    />
  </ReportFormSection>
  <ReportFormSection
    :number="numberAfterFilter + 2"
    :label="t('analytics.reports.plot_config')"
    :defaultOpen="false"
  >
    <QueryValueConfigFields
      :config="config"
      :filters="filters"
      :aggregation="aggregation"
      @configChange="emit('change', { config: $event })"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { useHasPermission } from "oai-planner";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import SelectList from "@/components/forms/SelectList.vue";
import { useCurrentProject } from "@/composables/project";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report } from "@/types/Report";
import {
  QueryValueAggregation,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
} from "@/types/reports/PlotQueryValue";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportDateFilterField from "@/views/reports/filters/ReportDateFilterField.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import ReportProcessFilterField from "@/views/reports/filters/ReportProcessFilterField.vue";
import ReportUnitValueTypeFilterField from "@/views/reports/filters/ReportUnitValueTypeFilterField.vue";
import QueryValueConfigFields from "@/views/reports/plots/query_value/QueryValueConfigFields.vue";
import QueryValueFormattingRules from "@/views/reports/plots/query_value/QueryValueFormattingRules.vue";
import {
  aggregationsByMetric,
  defaultFilters,
  filterKeysByMetric,
  metrics,
  previousPeriodForByMetric,
} from "@/views/reports/plots/query_value/queryValue";

const props = defineProps<{
  filters: QueryValueReportFilters;
  config: QueryValueReportConfig;
  aggregation: QueryValueAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();

const currentProject = useCurrentProject();
const isAdmin = useHasPermission("app_admin");

const metricOptions = computed(() =>
  metrics
    .map((metric) => ({
      name: t(`analytics.reports.query_value_metrics.${metric}`),
      value: metric,
    }))
    .filter(
      ({ value }) => currentProject.process_groups.includes("default") || value !== "unit_value",
    )
    .filter(({ value }) => isAdmin || value !== "project_progress"),
);

const aggregationOptions = computed(() =>
  aggregationsByMetric[props.config.metric].map((aggregation) => ({
    name: t(`analytics.reports.query_value_aggregations.${aggregation}`),
    value: aggregation,
  })),
);

const filterKeys = computed(() => filterKeysByMetric[props.config.metric]);

const numberAfterFilter = computed(() => (filterKeys.value.length === 0 ? 3 : 4));

const handleMetricChange = (metric: QueryValueMetric) => {
  const aggregation = aggregationsByMetric[metric].includes(props.aggregation)
    ? props.aggregation
    : aggregationsByMetric[metric][0];
  const validFilters = Object.fromEntries(
    Object.entries(props.filters).filter(([key]) =>
      filterKeysByMetric[metric].includes(key as keyof QueryValueReportFilters),
    ),
  );
  const showPreviousPeriod = previousPeriodForByMetric[metric].includes(aggregation)
    ? props.config.show_previous_period
    : false;
  emit("change", {
    config: { ...props.config, metric, show_previous_period: showPreviousPeriod },
    aggregation,
    filters: {
      ...structuredClone(defaultFilters),
      ...validFilters,
    },
  });
};

const handleAggregationChange = (aggregation: QueryValueAggregation) => {
  emit("change", { aggregation });
};
</script>
