<template>
  <div class="mt-4 max-w-3xl gap-4 px-2 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
    <div class="space-y-6 lg:col-span-2 lg:col-start-1">
      <router-link
        :to="{
          name: 'ProjectOverview',
          params: {
            customer_name: projectInfo.customer_name,
            site_id: projectInfo.site_id,
          },
        }"
      >
        <!-- Card content project -->
        <div
          class="shadow sm:rounded-lg"
          v-bind:class="[projectInfo.status === 'active' ? 'bg-white ' : 'bg-gray-200']"
        >
          <div class="px-4 py-5 sm:px-6">
            <h2 id="applicant-information-title" class="text-xl font-bold text-gray-700">
              {{ projectInfo.name }}
            </h2>
          </div>
          <div
            class="border-t px-4 py-5 sm:px-4"
            v-bind:class="[projectInfo.status === 'active' ? 'border-gray-200' : 'border-gray-300']"
          >
            <div class="grid grid-cols-3">
              <div class="col-span-3 xl:col-span-2">
                <div class="pr-2 lg:pr-6">
                  <div
                    class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100"
                  >
                    <img
                      :src="thumbnailUrl"
                      alt=""
                      class="bg-transparent bg-no-repeat bg-center bg-contain object-cover"
                      :style="{
                        backgroundImage: `url('${defaultProjectThumbnailUrl}')`,
                        textIndent: '-1000px',
                      }"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-3 pt-4 xl:col-span-1 xl:pt-0">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1">
                  <div class="">
                    <dt class="text-sm font-medium text-gray-500">
                      {{
                        projectInfo.planned_end
                          ? $t("dashboard.home.project_card.period")
                          : $t("dashboard.home.project_card.start_date")
                      }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ formatStartEnd(projectInfo) }}
                    </dd>
                  </div>
                  <div class="" v-if="projectInfo.zip_code && projectInfo.street">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $t("dashboard.home.project_card.address") }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ projectInfo.zip_code }} {{ projectInfo.street }}
                    </dd>
                  </div>
                  <div v-if="projectInfo.status === 'completed'">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $t("dashboard.home.project_card.status") }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ $t("dashboard.home.project_card.status_completed") }}
                    </dd>
                  </div>
                  <div v-else class="">
                    <dt class="text-sm font-medium text-gray-500">
                      {{ $t("dashboard.home.project_card.status") }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ $t("dashboard.home.project_card.status_active") }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div
            class="border-t"
            v-bind:class="[
              projectInfo.status === 'active' ? 'border-gray-200' : 'border-gray-300 bg-gray-200',
            ]"
          >
            <a
              href="/"
              class="block px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
              >{{ $t("dashboard.home.project_card.project_link") }}</a
            >
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { format } from "date-fns";
import defaultProjectThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import { SimplifiedProject } from "@/types/Project";

export default {
  name: "ProjectCard",
  props: ["projectInfo", "thumbnailUrl"],
  data() {
    return {
      defaultProjectThumbnailUrl,
    };
  },
  methods: {
    formatStartEnd(project: SimplifiedProject) {
      if (project.planned_end) {
        return `${format(project.planned_start, "MM/yyyy")} - ${format(
          project.planned_end,
          "MM/yyyy",
        )}`;
      } else {
        return format(project.planned_start, "MM/yyyy");
      }
    },
  },
};
</script>
