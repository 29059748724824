import * as XLSX from "xlsx";
import i18n from "@/i18n";
import textService from "@/services/textService";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { UnitValue, UnitValueRowTag, UnitValueType } from "@/types/UnitValue";
import {
  calculateDurationSum,
  calculateQuantitySum,
  calculateRowTags,
  calculateTypes,
  calculateUnitValueSum,
  calculateWorkforceSum,
  getFullUnitValueId,
  precision,
} from "@/views/unit_values/services/unitValues";

const { t } = i18n.global;

const emptyBuilding = "";
const emptyLevel = "";
const emptySection = "";
const emptyPlannedStart = "";
const emptyPlannedEnd = "";
const emptyValueCell = "";
const emptyNumber = "";

const formatNumber = (value: number | null) => {
  if (value === null || !Number.isFinite(value)) {
    return emptyNumber;
  }
  return parseFloat(value.toFixed(precision));
};

const createTypesHeaderRow = (types: UnitValueType[]) => [
  emptyBuilding,
  emptyLevel,
  emptySection,
  emptyPlannedStart,
  emptyPlannedEnd,
  ...types.flatMap((type) => [
    t(`unit_values.types.${type}.label`),
    emptyValueCell,
    emptyValueCell,
    emptyValueCell,
  ]),
];

const createTagHeaderRow = (tags: UnitValueRowTag[], types: UnitValueType[]) => [
  t("unit_values.building_label"),
  t("unit_values.level_label"),
  t("unit_values.section_label"),
  t("unit_values.planned_start"),
  t("unit_values.planned_end"),
  ...types.flatMap((type) => [
    `${t("unit_values.duration_label")} [${t("unit_values.duration_unit")}]`,
    `${t("unit_values.workforce_label")} [${t("unit_values.workforce_unit")}]`,
    `${t("unit_values.quantity_label")} [${t(`unit_values.types.${type}.unit`)}]`,
    `${t("unit_values.unit_value_label")} [${t("unit_values.unit_value_unit")}/${t(
      `unit_values.types.${type}.unit`,
    )}]`,
  ]),
];

const createTagHeader = (tag: UnitValueRowTag) => [
  tag.building,
  tag.level,
  tag.section,
  tag.actual_start,
  tag.actual_end,
];

const createUnitValueCells = (
  tag: UnitValueRowTag,
  type: UnitValueType,
  unitValuesByFullId: Record<string, UnitValue>,
) => {
  const unitValue = unitValuesByFullId[`${type}_${tag.id}`];
  return [
    formatNumber(unitValue.duration),
    formatNumber(unitValue.workforce),
    formatNumber(unitValue.quantity),
    formatNumber(unitValue.value),
  ];
};

const createUnitValueRow = (
  tags: UnitValueRowTag[],
  types: UnitValueType[],
  unitValuesByFullId: Record<string, UnitValue>,
) => {
  return tags.map((tag) => [
    ...createTagHeader(tag),
    ...types.flatMap((type) => createUnitValueCells(tag, type, unitValuesByFullId)),
  ]);
};

const createSummaryRow = (types: UnitValueType[], unitValues: UnitValue[]) => {
  return [
    emptyBuilding,
    emptyLevel,
    emptySection,
    emptyPlannedStart,
    emptyPlannedEnd,
    ...types.flatMap((type) => [
      formatNumber(calculateDurationSum(unitValues, type)),
      formatNumber(calculateWorkforceSum(unitValues, type)),
      formatNumber(calculateQuantitySum(unitValues, type)),
      formatNumber(calculateUnitValueSum(unitValues, type)),
    ]),
  ];
};

const createMerges = (types: UnitValueType[], hasBuilding: boolean) => {
  const row = 0;
  const baseStartColumn = hasBuilding ? 5 : 4;
  const valueCells = 4;
  return types.map((type, index) => {
    const startColumn = baseStartColumn + index * valueCells;
    return {
      s: { r: row, c: startColumn },
      e: { r: row, c: startColumn + valueCells - 1 },
    };
  });
};

const createExcelData = (
  unitValues: UnitValue[],
  hierarchyTags: HierarchyTagStore[] | undefined,
) => {
  const tags = calculateRowTags(unitValues, hierarchyTags);
  const types = calculateTypes(unitValues);
  const hasBuilding = unitValues.some((unitValue) => unitValue.tags.some((tag) => tag.building_id));
  const unitValuesByFullId = unitValues.reduce((acc, unitValue) => {
    acc[getFullUnitValueId(unitValue)] = unitValue;
    return acc;
  }, {} as Record<string, UnitValue>);

  const excelData = [
    createTypesHeaderRow(types),
    createTagHeaderRow(tags, types),
    ...createUnitValueRow(tags, types, unitValuesByFullId),
    createSummaryRow(types, unitValues),
  ].map((row) => (hasBuilding ? row : row.slice(1)));

  const merges = createMerges(types, hasBuilding);

  return { excelData, merges };
};

export const useExcelExport = () => {
  return (unitValues: UnitValue[], hierarchyTags: HierarchyTagStore[] | undefined) => {
    const { excelData, merges } = createExcelData(unitValues, hierarchyTags);
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    worksheet["!merges"] = merges;
    XLSX.utils.book_append_sheet(workbook, worksheet, t("unit_values.header"));
    XLSX.writeFile(
      workbook,
      `oculai_${textService.toNormalizedFileName(t("unit_values.header"))}.xlsx`,
    );
  };
};
