<template>
  <SelectList
    :options="modes"
    name="mode"
    :defaultSelected="mode"
    :label="t('analytics.reports.modes')"
    :onUpdate:selected="(value: string) => (mode = value, handleConfigChange()) "
  />
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import SelectList from "@/components/forms/SelectList.vue";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";

const props = defineProps<{ config: MilestoneReportConfig; filters: MilestoneReportFilters }>();
const emit = defineEmits<{ (eventName: "configChange", config: MilestoneReportConfig): void }>();

const { t } = useI18n();
const { defineField } = useForm();

const [mode] = defineField("mode");

const modes = [
  { value: "sequence", name: t("analytics.reports.sequence_mode") },
  { value: "end_date", name: t("analytics.reports.end_date_mode") },
];

watch(
  () => props.config,
  () => {
    mode.value = props.config.mode;
  },
  { immediate: true },
);

const handleConfigChange = () => {
  const config: MilestoneReportConfig = {
    mode: mode.value,
  };
  emit("configChange", config);
};
</script>
