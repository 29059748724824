<template>
  <Combobox
    as="div"
    multiple
    v-model="selectedDashboards"
    @update:modelValue="handleUpdate"
    class="relative flex items-center min-w-0"
  >
    <ComboboxButton
      class="rounded-md bg-white px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex gap-1 items-center truncate lg:min-w-[260px]"
      :style="{ height: '38px' }"
    >
      <PlusIcon class="w-5 h-5 shrink-0" />
      <span class="truncate">
        {{ t("dashboard.add_to_dashboard") }} ({{ selectedDashboards.length }})
      </span>
    </ComboboxButton>
    <ComboboxOptions
      v-if="categories.length > 0"
      class="absolute top-10 left-0 z-10 mt-1 max-h-60 w-[275px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg border ring-black ring-opacity-5 focus:outline-none sm:text-sm flex flex-col gap-3"
    >
      <div v-for="category in categories" :key="category.type">
        <div
          class="text-gray-600 font-semibold px-4 pt-2 pb-1 mr-4 flex items-center justify-between gap-2 text-base"
        >
          <div class="truncate">
            {{ t(`dashboard.menu_categories.${category.type}`) }}
          </div>
        </div>
        <ComboboxOption
          v-for="dashboard in category.dashboards"
          :key="dashboard._id"
          :value="dashboard"
          as="template"
          v-slot="{ selected }"
          :disabled="!canEdit(dashboard)"
        >
          <div
            class="py-2 pl-4 pr-3 rounded-md cursor-pointer w-full flex items-center"
            :class="isDashboardCreatingOrUpdating ? '' : 'hover:bg-yellow-200'"
          >
            <input
              type="checkbox"
              :checked="selected"
              :disabled="!canEdit(dashboard)"
              class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer disabled:text-gray-300"
            />
            <label
              class="ml-3 text-sm truncate cursor-pointer"
              :class="{ 'text-gray-400': isLoading }"
              >{{ dashboard.name }}</label
            >
          </div>
        </ComboboxOption>
      </div>
    </ComboboxOptions>
  </Combobox>
</template>

<script lang="ts" setup>
import { Combobox, ComboboxButton, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/20/solid";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useCreateOrUpdateDashboard } from "@/composables/dashboard";
import { useReports } from "@/composables/report";
import { useTrackEvent } from "@/composables/tracking";
import { Dashboard, DashboardWidgetToAddOrRemove, Widget } from "@/types/Dashboard";
import {
  useHasPermissionToEditDashboard,
  useDashboardsWithDefault,
  useLayoutContext,
} from "@/views/dashboard/composables";
import {
  addWidgetToDashboard,
  createDashboardMenuCategories,
  removeWidgetFromDashboard,
} from "@/views/dashboard/services/projectOverviewV2Layout";

const props = defineProps<{ type: Exclude<Widget, "LoadingWidget">; arg: string | null }>();

const { t } = useI18n();
const { layoutContext, isLoading: isLayoutContextLoading } = useLayoutContext();
const { dashboardsWithDefault, isLoading: areDashboardsLoading } =
  useDashboardsWithDefault(layoutContext);
const selectedDashboards = ref<Dashboard[]>([]);
const { createOrUpdateDashboard, isLoading: isDashboardCreatingOrUpdating } =
  useCreateOrUpdateDashboard();
const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();
const { reports } = useReports();
const isLoading = computed(
  () =>
    areDashboardsLoading.value ||
    isLayoutContextLoading.value ||
    isDashboardCreatingOrUpdating.value,
);

const initializeSelectedDashboards = () => {
  selectedDashboards.value = dashboardsWithDefault.value.filter((dashboard) =>
    dashboard.widgets.some((widget) => widget.type === "ReportWidget" && widget.arg === props.arg),
  );
};

const categories = computed(() =>
  createDashboardMenuCategories(dashboardsWithDefault.value).filter(
    (category) => category.dashboards.length > 0 && hasPermissionToEditDashboard(category),
  ),
);

const canEdit = (dashboard: Dashboard) =>
  !isLoading.value && !!layoutContext.value && hasPermissionToEditDashboard(dashboard);

const trackEvent = useTrackEvent();

const update = async (dashboard: Dashboard) => {
  if (!layoutContext.value) {
    return;
  }
  const widget: DashboardWidgetToAddOrRemove = { type: props.type, arg: props.arg };

  const updatedDashboard = selectedDashboards.value.includes(dashboard)
    ? addWidgetToDashboard(dashboard, widget, layoutContext.value)
    : removeWidgetFromDashboard(dashboard, widget, layoutContext.value);

  if (updatedDashboard && props.type === "ReportWidget") {
    const report = reports.value.find((item) => item._id === props.arg);
    const reportType = report ? report.type : undefined;
    if (selectedDashboards.value.includes(dashboard) && props.type === "ReportWidget") {
      trackEvent("reports_to-dashboard_apply", { type: reportType });
    } else {
      trackEvent("reports_remove-from-dashboard_apply", { type: reportType });
    }
    return createOrUpdateDashboard(updatedDashboard);
  }
};

const handleUpdate = () => {
  Promise.allSettled(dashboardsWithDefault.value.map((dashboard) => update(dashboard))).then(
    (results) => {
      if (results.some((result) => result.status === "rejected")) {
        initializeSelectedDashboards();
      }
    },
  );
};

watch([dashboardsWithDefault, () => props.type, () => props.arg], () => {
  initializeSelectedDashboards();
});

onMounted(() => {
  initializeSelectedDashboards();
});
</script>
