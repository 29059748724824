<template>
  <!-- Mobile / Small Screen -->
  <div class="pr-2 sm:hidden">
    <label for="selected-tab" class="sr-only">Select a tab</label>
    <select
      id="selected-tab"
      name="selected-tab"
      class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 focus:border-yellow-500 focus:ring-yellow-500 text-sm"
      @change="changeRoute($event)"
      v-model="activeTag"
    >
      <option v-for="tab in collapsedTabs" :key="tab.name" :value="tab.name">
        {{ tab.name }}
      </option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          :class="[
            tab.current
              ? 'border-yellow-500 text-yellow-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
          ]"
        >
          <a v-if="tab.timelapse">
            <Menu as="div" class="relative z-20 inline-block text-left">
              <div>
                <MenuButton class="inline-flex">
                  <div class="font-medium">{{ tab.name }}</div>
                  <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="left-mt-2 focus:outline-none absolute w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-2 ring-gray/5"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="this.dailyTimelapsePath"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.daily_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="this.customizableTimelapsePath"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.customizable_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="this.projectTimelapsePath"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.project_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </a>
          <div v-else>
            <router-link :to="{ name: tab.href }"> {{ tab.name }} </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";
import "@/assets/styles/skeleton.css";

export default defineComponent({
  name: "CameraNavbar",
  props: ["initialActiveTag", "stream"],
  data() {
    return {
      tabs: [],
      collapsedTabs: [],
      dailyTimelapsePath: "",
      customizableTimelapsePath: "",
      projectTimelapsePath: "",
      streamId: this.$route.params.camera_id,
      activeTag: this.initialActiveTag,
    };
  },
  mounted() {
    let tabs;
    const path = `/${this.currentCustomerName}/${this.currentSiteId}/${this.streamId}/`;
    let collapsedTabs;
    this.dailyTimelapsePath = path + "daily_timelapse";
    this.customizableTimelapsePath = path + "customizable_timelapse";
    this.projectTimelapsePath = path + "project_timelapse";

    // Check if contractor or construction company
    const projectStatus = this.currentProjectV2.status;

    if (this.hasPermission("all_camera_features_user")) {
      tabs = [
        {
          name: this.$t("app_features.live"),
          href: "Live",
          current: false,
          timelapse: false,
        },
        {
          name: this.$t("app_features.period"),
          href: "Period",
          timelapse: false,
        },
        {
          name: this.$t("app_features.gallery"),
          href: "Gallery",
          timelapse: false,
        },
        {
          name: this.$t("app_features.compare"),
          href: "Compare",
          timelapse: false,
          new: true,
        },
        {
          name: this.$t("camera.components.camera_navbar.timelapse"),
          href: "#",
          timelapse: true,
        },
      ];

      if (
        this.activeTag === this.$t("app_features.daily_timelapse") ||
        this.activeTag === this.$t("app_features.project_timelapse") ||
        this.activeTag === this.$t("app_features.customizable_timelapse")
      ) {
        tabs[4]["name"] = this.activeTag; // hard coded to position 3
      }
      collapsedTabs = [
        { name: this.$t("app_features.live"), route: "Live" },
        { name: this.$t("app_features.period"), route: "Period" },
        { name: this.$t("app_features.gallery"), route: "Gallery" },
        { name: this.$t("app_features.compare"), route: "Compare" },
        {
          name: this.$t("app_features.daily_timelapse"),
          route: "DailyTimelapse",
        },
        {
          name: this.$t("app_features.customizable_timelapse"),
          route: "CustomizableTimelapse",
        },
        {
          name: this.$t("app_features.project_timelapse"),
          route: "ProjectTimelapse",
        },
      ];

      // Remove first two tabs if stream is archived
      if (!this.stream.aws_stream_id) {
        tabs.shift();
        tabs.shift();
        collapsedTabs.shift();
        collapsedTabs.shift();
      }
    } else {
      // Contractor that only sees live in active project and project timelapse in completed project
      if (projectStatus === "active") {
        tabs = [
          {
            name: this.$t("app_features.live"),
            href: "Live",
            current: false,
            timelapse: false,
          },
        ];
        collapsedTabs = [
          {
            name: this.$t("app_features.live"),
            href: "Live",
            current: false,
          },
        ];
      } else {
        tabs = [
          {
            name: this.$t("app_features.project_timelapse"),
            href: "ProjectTimelapse",
            current: false,
            timelapse: false,
          },
        ];
        collapsedTabs = [
          {
            name: this.$t("app_features.project_timelapse"),
            href: "ProjectTimelapse",
            current: false,
          },
        ];
      }
    }

    // Add active tag
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i]["name"] === this.activeTag) {
        tabs[i]["current"] = true;
      }
    }

    // Add active tag
    for (let i = 0; i < collapsedTabs.length; i++) {
      if (collapsedTabs[i]["name"] === this.activeTag) {
        collapsedTabs[i]["current"] = true;
      }
    }

    this.tabs = tabs;
    this.collapsedTabs = collapsedTabs;
  },
  methods: {
    changeRoute(event) {
      const route = this.collapsedTabs.find((element) => element.name === event.target.value).route;

      this.$router.push({
        name: route,
        params: {
          camera_id: this.streamId,
          customer_name: this.currentCustomerName,
          site_id: this.currentSiteId,
        },
      });
    },
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
});
</script>
