<template>
  <div
    class="bg-gray-100 md:px-6 px-3 py-4 text-center cursor-grab"
    v-if="leftList"
    style="min-height: 530px"
  >
    <h3 class="drag-drop-title">{{ $t("general.active") }}</h3>
    <draggable
      class="list-group"
      :list="leftList"
      group="element"
      itemKey="name"
      :animation="200"
      direction="horizontal"
      tag="ul"
      draggable=".dragItem"
      filter=".action-button"
      drag-class="dragClass"
      ghost-class="ghost"
      @change="onChange"
    >
      <template #item="{ element }">
        <div
          class="list-group-item cursor-grab dragItem my-2 rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus-within:ring-2 focus-within:ring-yellow-500 focus-within:ring-offset-2 hover:border-gray-400"
          v-if="element.order != -1"
        >
          {{ $t(`report.section_title.${element.section}`) }}
        </div>
        <div
          v-else
          class="list-group-item my-2 rounded-lg bg-oaiGray-300 p-2 text-white cursor-default"
          :animation="0"
        >
          {{ $t(`report.section_title.${element.section}`) }}
        </div>
      </template>
    </draggable>
  </div>

  <div class="h-500px container bg-gray-100 md:px-6 px-3 py-8 text-center cursor-grab">
    <h3 class="drag-drop-title">{{ $t("general.inactive") }}</h3>
    <draggable
      class="list-group h-full"
      :list="rightList"
      group="element"
      itemKey="name"
      ghost-class="ghost"
      :animation="200"
      drag-class="dragClass"
      @end="onDragEnd"
      @start="onDragStart"
    >
      <template #item="{ element }">
        <div
          class="list-group-item cursor-grab shadow=sm my-2 rounded-lg border border-gray-300 bg-white p-2 shadow-sm focus-within:ring-2 focus-within:ring-yellow-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          {{ $t(`report.section_title.${element.section}`) }}
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SectionDragAndDrop",
  props: ["sectionsList"],
  emits: ["onUpdate"],
  data: function () {
    return {
      leftList: [],
      rightList: [],
    };
  },
  components: {
    draggable,
  },
  //
  mounted() {
    this.leftList = this.sectionsList.filter((el) => {
      return el.visible;
    });
    this.rightList = this.sectionsList.filter((el) => {
      return !el.visible;
    });
  },
  methods: {
    onChange() {
      for (let i = 2; i < this.leftList.length; i++) {
        this.leftList[i].order = i - 1;
        this.leftList[i].visible = true;
      }
      for (let i = 0; i < this.rightList.length; i++) {
        this.rightList[i].visible = false;
      }

      const arr = this.leftList.concat(this.rightList);
      this.$emit("onUpdate", arr);
    },
    setDragCursor(value) {
      const html = document.getElementsByTagName("html").item(0);
      html.classList.toggle("grabbing", value);
    },
  },
};
</script>

<style scoped>
.drag-drop-title {
  @apply pb-3 text-left text-lg font-medium text-yellow-900;
}

.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #d8632b;
  cursor: grab !important;
}

.dragClass {
  @apply cursor-grab bg-yellow-600  !important;
}
</style>
