<template>
  <NavigationComponent :activeTag="$t('app_features.processes')">
    <div class="mainContainer flex flex-col">
      <div v-if="isLoading" class="flex items-center justify-center flex-1">
        <LoadingSpinner />
      </div>
      <div v-if="!isLoading && !hasData" class="flex items-center justify-center flex-1">
        {{ $t("unit_values.no_unit_values") }}
      </div>
      <Form
        ref="formRef"
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema as (typeof Form)['validationSchema']"
        class="flex-1 flex flex-col overflow-hidden relative"
        v-slot="{ isSubmitting, values, setFieldValue, meta, errors, setValues }"
        v-if="!isLoading && hasData"
      >
        <UnitValuesEditorHeader
          :approved="getApproved(getUnitValuesListFromForm(values))"
          :isSubmitting="isSubmitting"
          :isSubmitDisabled="isSubmitting || !meta.dirty || !meta.valid"
          :isAdminMode="isAdminMode"
          @addTypeClicked="
            addTypeClicked = true;
            columnModalUnitValue = null;
            isColumnModalOpen = true;
          "
          @excelExportClicked="
            trackEvent('unit-values_export-excel_click');
            exportToExcel(getUnitValuesListFromForm(values), hierarchyTags);
          "
          @toggleApproveClicked="toggleApproved(values, setValues)"
          @toggleAdminMode="isAdminMode = !isAdminMode"
        />
        <div class="flex-1 flex overflow-hidden ml-5 mb-5">
          <UnitValuesEditorTags
            ref="unitValuesTagsRef"
            :tags="values.tags"
            :selectedTagIds="selectedTagIds"
            :hasBuilding="!!hasBuilding"
            :focusedUnitValue="focusedUnitValue"
            :tagColumnWidth="tagColumnWidth"
            :firstHeaderRowHeight="firstHeaderRowHeight"
            :secondHeaderRowHeight="secondHeaderRowHeight"
            :rowHeight="rowHeight"
            :scrollBarHeight="scrollBarHeight"
            @scrolled="handleVerticalScroll"
            @selectedTagIdsChanged="selectedTagIds = $event"
            @merged="handleMergeRowsClick(values, setValues)"
            @split="handleSplitRowsClick(values, setValues)"
            @tagColumnWidthChanged="
              tagColumnWidth = $event === null ? defaultTagColumnWidth : $event
            "
          />
          <div
            class="flex flex-col relative"
            :style="`max-width: calc(100% - ${
              tagColumnWidth + scrollBarWidth + rightBorderWidth
            }px)`"
          >
            <div
              class="flex shrink-0 overflow-auto unitValueHideScrollBar z-30"
              ref="valuesHeaderRef"
              @scroll="handleHorizontalScroll"
            >
              <div class="flex flex-row">
                <TypeHeader
                  v-for="(type, index) in values.types"
                  :key="type"
                  :firstHeaderRowHeight="firstHeaderRowHeight"
                  :secondHeaderRowHeight="secondHeaderRowHeight"
                  :columnWidth="columnWidth"
                  :type="type"
                  :isLastType="index !== values.types.length - 1"
                  :unitValue="values[`uw_${type}_${values.tags[0].id}`]"
                  @editClicked="
                    columnModalUnitValue = values[`uw_${type}_${values.tags[0].id}`];
                    isColumnModalOpen = true;
                  "
                />
              </div>
            </div>
            <div
              class="overflow-auto overscroll-none flex select-none z-40 unitValueHideScrollBar"
              ref="mainContentRef"
              @scroll="handleMainScroll"
              @mousemove="handleMouseMove"
              @mousedown="enablePan"
              @mouseup="disablePan"
            >
              <div class="flex flex-row">
                <div
                  :style="`width: ${columnWidth}px`"
                  v-for="(type, index) in (values.types as UnitValueType[])"
                  :key="type"
                >
                  <div
                    class="unitValueValueColumns bg-white"
                    v-for="tag in values.tags"
                    :key="tag.id"
                    :style="`height: ${rowHeight}px`"
                  >
                    <ValueCells
                      :unitValue="values[`uw_${type}_${tag.id}`]"
                      :tag="tag"
                      :isAdminMode="isAdminMode"
                      :rowHeight="rowHeight"
                      :valueCellDetailContext="valueCellDetailContext"
                      :errorField="getErrorField(type, tag, errors)"
                      :showLastRightBorder="index !== values.types.length - 1"
                      :unitValueAggregate="unitValueAggregatesByType[type]"
                      @changed="setFieldValue(`uw_${type}_${tag.id}`, $event)"
                      @detailShown="valueCellDetailContext = $event"
                      @detailHidden="valueCellDetailContext = null"
                      @focused="focusedUnitValue = $event"
                      @blurred="focusedUnitValue = null"
                      @navigated="
                        valueCellFocusService.focusCell({
                          currentField: $event.field,
                          direction: $event.direction,
                          type,
                          types: values.types,
                          tag,
                          tags: values.tags,
                          fields: $event.fields,
                        })
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="border-t border-gray-600" />
            <div
              class="flex shrink-0 overflow-x-auto overflow-y-hidden z-30"
              ref="summaryFooterRef"
              @scroll="handleHorizontalScroll"
            >
              <div class="flex flex-row">
                <div
                  :style="`width: ${columnWidth}px`"
                  v-for="(type, index) in values.types"
                  :key="type"
                >
                  <div
                    class="unitValueValueColumns border-b border-gray-600 bg-white"
                    :style="`height: ${rowHeight}px`"
                  >
                    <SummaryCells
                      :type="type"
                      :unitValues="getUnitValuesListFromForm(values)"
                      :showLastRightBorder="index !== values.types.length - 1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col overflow-x-hidden overflow-y-auto shrink-0"
            ref="rightBorderRef"
            :style="`z-index: 50; max-height: calc(100% - ${scrollBarHeight}px); width: ${
              scrollBarWidth ? scrollBarWidth + 1 : rightBorderWidth
            }px;${scrollBarWidth === 0 ? 'direction: rtl' : ''}`"
            @scroll="handleVerticalScroll"
          >
            <div
              class="p-1 text-md border-b border-transparent border-l border-l-gray-600"
              :style="`height: ${firstHeaderRowHeight}px`"
            >
              &nbsp;
            </div>
            <div
              class="p-1 text-md text-center border-b border-transparent border-t border-l border-l-gray-600"
            >
              &nbsp;<br />&nbsp;
            </div>
            <div>
              <div class="flex flex-col">
                <div
                  class="p-1 text-md text-center border-b border-transparent border-l border-l-gray-600"
                  v-for="tag in [...values.tags, { id: 'summary' }]"
                  :key="tag.id"
                  :style="`height: ${rowHeight}px`"
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div class="border-b border-transparent border-l border-l-gray-600" />
          </div>
        </div>
        <ColumnModal
          :existingTypes="values.types"
          :unitValue="columnModalUnitValue"
          v-if="isColumnModalOpen"
          :canDelete="values.types.length > 1"
          @closed="
            isColumnModalOpen = false;
            columnModalUnitValue = null;
          "
          @confirmed="handleColumModalConfirmed($event, values, setValues)"
          @deleted="handleColumnModalDeleted($event, values, setValues)"
        />
      </Form>
    </div>
    <ValueCellDetail
      class="fixed"
      v-if="valueCellDetailContext"
      :unitValue="valueCellDetailContext.unitValue"
      :unitValueAggregate="valueCellDetailContext.unitValueAggregate"
      :field="valueCellDetailContext.field"
      :style="`z-index: 999999999; left: ${valueCellDetailContext.x}px; top: ${valueCellDetailContext.y}px; transform: translate(-100%, -100%)`"
      @mouseleave="valueCellDetailContext = null"
    />
  </NavigationComponent>
</template>

<script lang="ts" setup>
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { Form, FormContext, GenericObject, SubmissionHandler, useForm } from "vee-validate";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { useHierarchyTags } from "@/composables/hierarchyTags";
import { useSaveBeforeLeaveConfirmationModal } from "@/composables/toast";
import { useTrackEvent } from "@/composables/tracking";
import { useSaveUnitValues, useUnitValueAggregates, useUnitValues } from "@/composables/unitValues";
import UnitValueRepository from "@/repositories/UnitValueRepository";
import logger from "@/services/logger";
import {
  UnitValue,
  UnitValueRowTag,
  UnitValueType,
  UnitValueColumnModalForm,
  UnitValueDetailContext,
} from "@/types/UnitValue";
import ColumnModal from "@/views/unit_values/components/ColumnModal.vue";
import SummaryCells from "@/views/unit_values/components/SummaryCells.vue";
import TypeHeader from "@/views/unit_values/components/TypeHeader.vue";
import UnitValuesEditorHeader from "@/views/unit_values/components/UnitValuesEditorHeader.vue";
import UnitValuesEditorTags from "@/views/unit_values/components/UnitValuesEditorTags.vue";
import ValueCellDetail from "@/views/unit_values/components/ValueCellDetail.vue";
import ValueCells from "@/views/unit_values/components/ValueCells.vue";
import { useExcelExport } from "@/views/unit_values/services/excelExport";
import {
  addUnitValuesType,
  calculateUnitValueAggregatesByType,
  deleteUnitValuesByType,
  getApproved,
  mergeRows,
  modifyUnitValuesType,
  setApproved,
  setTypeAdjustmentFields,
  splitRows,
} from "@/views/unit_values/services/unitValues";
import valueCellFocusService from "@/views/unit_values/services/valueCellFocusService";
import { useScroll, useUnitValuesForm } from "./composables";
import "./styles.css";

const firstHeaderRowHeight = 35;
const secondHeaderRowHeight = 57;
const rowHeight = 33;
const columnWidth = 300;
const rightBorderWidth = 20;
const defaultTagColumnWidth = 350;

const isColumnModalOpen = ref(false);
const columnModalUnitValue = ref<UnitValue | null>(null);
const selectedTagIds = ref<string[]>([]);
const isAdminMode = ref(false);
const valueCellDetailContext = ref<UnitValueDetailContext | null>(null);
const focusedUnitValue = ref<UnitValue | null>(null);
const formRef = ref<FormContext | null>(null);
const unitValuesTagsRef = ref<{ tagHeaderRef: Ref<HTMLDivElement | null> } | null>(null);
const tagColumnWidth = ref(defaultTagColumnWidth);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const { unitValues, isLoading: areUnitValuesLoading } = useUnitValues();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { unitValueAggregates, areUnitValueAggregatesLoading } = useUnitValueAggregates({
  failSilently: true,
});
const { saveUnitValues } = useSaveUnitValues();
const { schema, initialValues, getUnitValuesListFromForm, setUnitValuesInForm } = useUnitValuesForm(
  unitValues,
  hierarchyTags,
);
const {
  mainContentRef,
  tagHeaderRef,
  valuesHeaderRef,
  summaryFooterRef,
  rightBorderRef,
  calculateScrollbarSize,
  handleMainScroll,
  handleVerticalScroll,
  handleHorizontalScroll,
  enablePan,
  disablePan,
  handleMouseMove,
  scrollBarWidth,
  scrollBarHeight,
} = useScroll();
const { setValues: _useFormSetValues } = useForm();
const exportToExcel = useExcelExport();
const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
const trackEvent = useTrackEvent();

const addTypeClicked = ref(false);

const hasData = computed(
  () =>
    unitValues.value &&
    unitValues.value.length > 0 &&
    hierarchyTags.value &&
    hierarchyTags.value.length > 0,
);

const isLoading = computed(
  () =>
    areUnitValuesLoading.value ||
    areHierarchyTagsLoading.value ||
    areUnitValueAggregatesLoading.value,
);

const hasBuilding = computed(
  () =>
    unitValues.value &&
    unitValues.value.some((unitValue) => unitValue.tags.some((tag) => tag.building_id)),
);

const unitValueAggregatesByType = computed(() => {
  if (!unitValueAggregates.value) {
    return {} as Record<UnitValueType, undefined>;
  }
  return calculateUnitValueAggregatesByType(unitValueAggregates.value);
});

const getSelectedRowTagsFromForm = (values: GenericObject) =>
  (values.tags as UnitValueRowTag[])
    .filter((rowTag) => selectedTagIds.value.includes(rowTag.id))
    .map((rowTag) => rowTag.tags);

const handleSubmit: SubmissionHandler = async (genericObject, { resetForm }) => {
  try {
    trackEvent("unit-values_save");
    await saveUnitValues(getUnitValuesListFromForm(genericObject));
    resetForm(
      {
        values: initialValues.value,
      },
      { force: true },
    );
    selectedTagIds.value = [];
  } catch {
    // have to catch the error here, otherwise it's propagated as an unhandled error
    // it's already logged by vue-query
    return undefined;
  }
};

const handleMergeRowsClick = (values: GenericObject, setValues: typeof _useFormSetValues) => {
  trackEvent("unit-values_merge_apply");
  const tagsToMerge = getSelectedRowTagsFromForm(values);
  const newUnitValues = mergeRows(getUnitValuesListFromForm(values), tagsToMerge);
  setUnitValuesInForm(values, setValues, newUnitValues);
  selectedTagIds.value = [];
  setTimeout(() => {
    calculateScrollbarSize();
  }, 20);
};

const handleSplitRowsClick = (values: GenericObject, setValues: typeof _useFormSetValues) => {
  trackEvent("unit-values_split_apply");
  const tagsToSplit = getSelectedRowTagsFromForm(values);
  const newUnitValues = splitRows(getUnitValuesListFromForm(values), tagsToSplit);
  setUnitValuesInForm(values, setValues, newUnitValues);
  selectedTagIds.value = [];
  setTimeout(() => {
    calculateScrollbarSize();
  }, 20);
};

const getModifiedOrAddedUnitValues = (
  unitValues: UnitValue[],
  unitValueColumnModalForm: UnitValueColumnModalForm,
) => {
  if (
    columnModalUnitValue.value &&
    columnModalUnitValue.value.type === unitValueColumnModalForm.type
  ) {
    return unitValues;
  }
  return columnModalUnitValue.value
    ? modifyUnitValuesType(
        unitValues,
        columnModalUnitValue.value.type,
        unitValueColumnModalForm.type,
      )
    : addUnitValuesType(unitValues, unitValueColumnModalForm.type);
};

const getRecalculatedUnitValuesFromBackend = async (
  unitValues: UnitValue[],
  unitValueColumnModalForm: UnitValueColumnModalForm,
) => {
  try {
    if (
      columnModalUnitValue.value &&
      columnModalUnitValue.value.type === unitValueColumnModalForm.type
    ) {
      return unitValues;
    }
    return await UnitValueRepository.calculateUnitValueFields(
      currentCustomerName,
      currentSiteId,
      unitValues,
    );
  } catch (error) {
    logger.error(error);
    return unitValues;
  }
};

const handleColumModalConfirmed = async (
  unitValueColumnModalForm: UnitValueColumnModalForm,
  values: GenericObject,
  setValues: typeof _useFormSetValues,
) => {
  if (addTypeClicked.value) {
    trackEvent("unit-values_value_create");
    addTypeClicked.value = false;
  } else {
    trackEvent("unit-values_value-edit_save");
  }
  const currentUnitValues = getUnitValuesListFromForm(values);
  const newUnitValues = getModifiedOrAddedUnitValues(currentUnitValues, unitValueColumnModalForm);
  const newUnitValuesWithTypeAdjustmentFields = setTypeAdjustmentFields(
    newUnitValues,
    unitValueColumnModalForm.type,
    {
      type_duration_factor: unitValueColumnModalForm.type_duration_factor,
      type_workforce_delta: unitValueColumnModalForm.type_workforce_delta,
      type_value_delta: unitValueColumnModalForm.type_value_delta,
    },
  );
  const recalculatedUnitValuesFromBackend = await getRecalculatedUnitValuesFromBackend(
    newUnitValuesWithTypeAdjustmentFields,
    unitValueColumnModalForm,
  );
  setUnitValuesInForm(values, setValues, recalculatedUnitValuesFromBackend);
  columnModalUnitValue.value = null;
  isColumnModalOpen.value = false;
  setTimeout(() => {
    calculateScrollbarSize();
  }, 100);
};

const handleColumnModalDeleted = (
  type: UnitValueType,
  values: GenericObject,
  setValues: typeof _useFormSetValues,
) => {
  const newUnitValues = deleteUnitValuesByType(getUnitValuesListFromForm(values), type);
  setUnitValuesInForm(values, setValues, newUnitValues);
  columnModalUnitValue.value = null;
  isColumnModalOpen.value = false;
  trackEvent("unit-values_value_delete");
  setTimeout(() => {
    calculateScrollbarSize();
  }, 100);
};

const getErrorField = (
  type: UnitValueType,
  tag: UnitValueRowTag,
  errors: Record<string, string | undefined>,
) => {
  const key = `uw_${type}_${tag.id}`;
  const error = errors[key];
  if (error) {
    const matches = error.match(/^.*?\.(.*?) .*$/);
    if (matches && matches[1]) {
      return matches[1].replace(key, "");
    }
  }
  return null;
};

const toggleApproved = (values: GenericObject, setValues: typeof _useFormSetValues) => {
  const unitValues = getUnitValuesListFromForm(values);
  const isApproved = getApproved(unitValues);
  const newUnitValues = setApproved(unitValues, !isApproved);
  setUnitValuesInForm(values, setValues, newUnitValues);
};

watch(
  () => unitValuesTagsRef.value,
  () => {
    tagHeaderRef.value = unitValuesTagsRef.value?.tagHeaderRef ?? null;
  },
);

onBeforeRouteLeave((to, from, next) => {
  if (!formRef.value || !formRef.value.meta.dirty) {
    return next();
  }
  showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
    if (confirmed) {
      next();
    }
  });
});

onMounted(() => {
  trackEvent("unit-values_view");
});
</script>

<style scoped>
.mainContainer {
  height: calc(100vh - 56px);
}

@media (min-width: 768px) {
  .mainContainer {
    height: calc(100vh - 60px);
  }
}

@media (min-width: 1024px) {
  .mainContainer {
    height: 100vh;
  }
}
</style>
