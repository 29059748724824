<template>
  <ModalTW :open="true" @close="handleClose" customCls="w-full lg:w-[400px] m-2">
    <template #title> {{ t("dashboard.create_dashboard_modal.title") }} </template>
    <template #content>
      <div class="flex flex-col gap-6 text-left">
        <div>
          <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{
            t("dashboard.create_dashboard_modal.name")
          }}</label>
          <div class="mt-2">
            <input
              ref="nameInput"
              type="text"
              name="name"
              autocomplete="one-time-code"
              v-model="dashboardName"
              @keyup.enter="handleSaveClick"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <button
          type="submit"
          @click="handleSaveClick"
          :disabled="isLoading"
          class="focus:outline-none inline-flex gap-2 w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 disabled:bg-gray-200"
        >
          <LoadingSpinner size="w-5 h-5" v-if="isLoading" />
          {{ t("dashboard.create_dashboard_modal.submit") }}
        </button>
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import { useFocus } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";

const props = defineProps<{ isLoading?: boolean }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "update", name: string): void;
}>();

const { t } = useI18n();

const dashboardName = ref("");
const nameInput = ref<HTMLInputElement | null>();
useFocus(nameInput, { initialValue: true });

const handleSaveClick = async () => {
  if (dashboardName.value && !props.isLoading) {
    emit("update", dashboardName.value);
  }
};

const handleClose = () => {
  if (!props.isLoading) {
    emit("close");
  }
};
</script>
