<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title || $t("analytics.reports.working_hour_curve_plot") }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="working_hour_curve"
      :filters="filters"
      :reportSummary="data"
      :height="filterBadgesHeight"
      class="ml-2"
    />
    <WorkingHourCurvePopover
      class="absolute top-1 right-4"
      v-if="!hidePopover"
      @reportInfoClick="emit('reportInfoClick')"
    />
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <Chart :options="chartOptions" :key="props.config" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { parse, format, isValid, addHours, subHours } from "date-fns";
import { Chart } from "highcharts-vue";
import {
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useProjectDurationSettings,
  durationService,
} from "oai-planner";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useOutagesByRange } from "@/composables/outages";
import { useStreams } from "@/composables/stream";
import { apiClient } from "@/repositories/clients";
import processDurationService from "@/services/processDurationService";
import { DashboardGridSize } from "@/types/Dashboard";
import {
  WorkingHourCurveReportSummary,
  WorkingHourCurveReportConfig,
  WorkingHourCurveReportFilters,
  WorkingHourCurveReportSummarySchedule,
} from "@/types/reports/PlotWorkingHourCurve";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import WorkingHourCurvePopover from "@/views/reports/plots/working_hour_curve/WorkingHourCurvePopover.vue";

const defaultFullHeight = 400;

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    data?: WorkingHourCurveReportSummary;
    reportId: string;
    filters: WorkingHourCurveReportFilters;
    config: WorkingHourCurveReportConfig;
    title?: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["widgetRouteClicked", "reportInfoClick"]);

const { headerHeight, chartHeight, filterBadgesHeight, sizeStyles } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 1,
  },
);

const defaultChartOptions = computed(() => ({
  chart: {
    zoomType: "x",
    height: chartHeight.value,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      week: "%e. %B",
      month: "%B '%y",
    },
  },
  yAxis: {
    title: {
      text: t("analytics.reports.working_hours"),
    },
    min: 0,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    outside: true,
    crosshairs: {
      color: "gray",
      dashStyle: "dash",
    },
    formatter: function () {
      const point = (
        this as unknown as {
          point: {
            durations: WorkingHourCurveReportSummarySchedule[number]["durations"];
          } & Record<string, Record<string, unknown>>;
        }
      ).point;
      const x = (this as unknown as { x: number }).x;

      const weekday = new Date(x).getDay() === 0 ? 7 : new Date(x).getDay();

      const holidayInfo = projectDurationSettings.value?.non_working_days.find((day) => {
        if (x >= day.start_date.getTime() && x <= day.end_date.getTime()) {
          return true;
        }
      });

      const outage = outages.value[format(x, "yyyy-MM-dd")];

      const tooltipContent = `
        <p class='text-sm mb-2 pr-1 border-b border-gray-200'>
          ${format(x, "dd.MM.yyyy")}, ${t(`calendar.week_days.${weekday}.long`)}
        </p>

        <p class="mb-2">
          <span style="color: ${point.color}">●</span>
          ${point?.series?.name}: 
          <span class="font-bold">${point.y}</span>
        </p>

        <p>
          ${point.durations
            .map((duration) => {
              const tags = [duration.building_name, duration.level_name, duration.section_name];
              const name = tags.filter(Boolean).join(" - ");

              return `
              <p class="text-xs">
                <span>${name}</span>
                <span class="text-gray-600 ml-2">
                  (${duration.duration} ${t("time.hour", duration.duration)})
                </span>
              </p>`;
            })
            .join("")}
        </p>

        ${generateHolidayTooltip(holidayInfo)}
        ${generateOutagesTooltip(outage)}
      `;

      return `<div class="p-1">${tooltipContent}</div>`;
    },
  },
}));

const { projectDurationSettings } = useProjectDurationSettings(apiClient);
const { streams } = useStreams();
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(apiClient);
const isWorkingDay = useIsWorkingDay(apiClient);

const durationSettings = computed(() =>
  projectDurationSettings.value
    ? durationService.calculateSettings(
        projectDurationSettings.value.working_hours,
        projectDurationSettings.value.non_working_days,
      )
    : null,
);

const outagesInterval = computed(() => {
  if (!props.data) {
    return null;
  }

  const days = Object.keys(props.data).sort((a, b) => a.localeCompare(b));
  if (!days.length) {
    return null;
  }

  const start = parse(days[0], "yyyy-MM-dd", new Date());
  const end = parse(days[days.length - 1], "yyyy-MM-dd", new Date());

  if (!isValid(start) || !isValid(end)) {
    return null;
  }

  return { start, end };
});

const { outagesByRange } = useOutagesByRange(outagesInterval);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const generateOutagesTooltip = (
  outages: { start_time: Date; end_time: Date; camera_id: string }[] | null,
) => {
  if (!outages || outages.length === 0) {
    return "";
  }
  return `
    <div class="flex flex-col mt-4">
      <h4 class="border-b pb-1 text-sm flex gap-2 justify-between items-center">
        ${t("analytics.planner.outages")}
      </h4>
      <div class="flex gap-2 flex-wrap mt-2" style="max-width: 400px">
            ${outages
              .map(
                (outage) => `
              <div class="flex text-sm text-white px-1 py-0.5 rounded w-min whitespace-nowrap bg-red-300">
                <span>${outage.camera_id}:&nbsp;</span>
                <span>${format(outage.start_time, "HH:mm")}-${format(
                  outage.end_time,
                  "HH:mm",
                )}</span>
              </div>
            `,
              )
              .join("")}
    </div>
  `;
};

const generateHolidayTooltip = (holidayInfo?: {
  name: string;
  start_date: Date;
  end_date: Date;
  type: string;
}) => {
  if (holidayInfo) {
    const timeRange =
      holidayInfo.start_date.getTime() === holidayInfo.end_date.getTime()
        ? format(holidayInfo.start_date, "dd.MM.yyyy")
        : `${format(holidayInfo.start_date, "dd.MM.yyyy")}-${format(
            holidayInfo.end_date,
            "dd.MM.yyyy",
          )}`;

    return `
      <div style="${
        holidayInfo.type === "disturbance"
          ? "background-color: #f5e3be; color: #9c7018"
          : "background-color: #bfdbfe4c; color: #1d4ed8"
      }; border-radius: 4px; width: min-content; padding: 2px 4px; font-size: 14px; white-space: nowrap;">
        ${holidayInfo.name} (${timeRange})
      </div>
    `;
  }
  return "";
};

const getHolidaysPlotBands = () => {
  const nonWorkingDays = projectDurationSettings?.value?.non_working_days || [];

  const holidaysPlotBands = nonWorkingDays.map((day) => {
    const start = day.start_date.getTime();
    const end = day.end_date.getTime();

    return {
      from: subHours(start, 12).getTime(),
      to: addHours(end, 12).getTime(),
      color: day.type === "disturbance" ? "#F5E3BE" : "#ECF4FF",
      zIndex: 2,
    };
  });

  return holidaysPlotBands;
};

const getWeekendPlotBands = () => {
  if (!Object.keys(outages.value).length) {
    return [];
  }

  const start = parse(Object.keys(outages.value)[0], "yyyy-MM-dd", new Date());
  const end = parse(Object.keys(outages.value).at(-1) || "", "yyyy-MM-dd", new Date());
  const weekends = [];

  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    if (!durationSettings.value?.workingDaysAndHoursMap[date.getDay()].workingHours) {
      weekends.push({
        from: subHours(date, 12).getTime(),
        to: addHours(date, 12).getTime(),
        color: "#F3F4F6",
        zIndex: 0,
      });
    }
  }

  return weekends;
};

const chartOptions = computed(() => {
  if (!props.data) {
    return defaultChartOptions.value;
  }

  const shouldShowMean = props.config.show_mean;

  const numbers = Object.values(props.data.schedule)
    .map((value) => value.sum_working_hours)
    .filter(Boolean);
  const mean = Math.floor(numbers.reduce((acc, val) => acc + val, 0) / numbers.length);

  const values = Object.entries(props.data.schedule).map(([date, value]) => {
    const isOutageDay = !value.sum_working_hours && outages.value[date]?.length > 0;

    return {
      x: parse(date, "yyyy-MM-dd", new Date()).getTime(),
      y: value.sum_working_hours,
      durations: value.durations,
      color: isOutageDay ? "#FCA5A5" : undefined,
    };
  });

  const plotBands = [...getHolidaysPlotBands(), ...getWeekendPlotBands()];

  return {
    ...defaultChartOptions.value,
    series: [
      {
        name: t("analytics.reports.working_hours"),
        data: values,
        color: "#438F7F",
        tooltip: {
          headerFormat: "<span class='text-md font-bold'>{point.key}</span><br />",
        },
        ...(shouldShowMean
          ? {
              negativeColor: "#6B7280",
              threshold: mean,
              type: "area",
            }
          : {}),
      },
    ],
    xAxis: {
      ...(defaultChartOptions.value.xAxis || {}),
      plotBands,
    },
    yAxis: {
      ...(defaultChartOptions.value.yAxis || {}),
      ...(shouldShowMean
        ? {
            plotLines: [
              {
                value: mean,
                color: "#438F7F",
                width: 1,
                label: {
                  text: t("analytics.reports.mean") + mean,
                  x: -1,
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                },
                zIndex: 2,
              },
            ],
          }
        : {}),
    },

    ...(shouldShowMean
      ? {
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, "#79BFB1"],
                  [1, "#FFFFFF00"],
                ],
              },
              negativeFillColor: {
                linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
                stops: [
                  [0, "#9CA3AF90"],
                  [1, "#FFFFFF00"],
                ],
              },
            },
          },
        }
      : {}),
  };
});
</script>
