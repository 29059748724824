<template>
  <BaseWidget
    :isLoading="isLoading"
    @delete="emit('delete')"
    @trackInteraction="emit('trackInteraction')"
  >
    <div
      class="p-1 h-full flex justify-center gap-2"
      :class="twoColumns ? 'flex-row' : 'flex-col'"
      v-if="weatherData"
    >
      <div class="flex flex-col gap-2 justify-center min-w-0" :class="twoColumns ? 'flex-1' : ''">
        <div class="flex items-center justify-center">
          <img class="rounded-full shrink-0 h-16 w-16" :src="iconUrl" alt="" v-if="height > 138" />
          <div :style="{ fontSize: '32px' }">{{ weatherData.item.temperature }}°C</div>
        </div>
        <div class="text-gray-600 text-thin text-center truncate" v-if="height > 156">
          <FontAwesomeIcon icon="fa-solid fa-droplet" />
          <span class="ml-2 mr-4">{{ weatherData.item.rain }} mm</span>
          <FontAwesomeIcon icon="fa-solid fa-wind" />
          <span class="ml-2"
            >{{ weatherData.item.wind }} {{ $t("dashboard.project.weather_card.kmh") }}</span
          >
        </div>
      </div>
      <div
        class="flex flex-col text-gray-800 text-center mt-2 justify-center min-w-0"
        v-if="height > 171 || twoColumns"
        :class="twoColumns ? 'flex-1' : ''"
      >
        <div class="truncate">
          {{ weatherData.meta.location_name }}
        </div>
        <div class="text-xs truncate" v-if="timestampDate">
          {{ $t(`calendar.week_days.${getDay(timestampDate) || 7}.long`) }},
          {{ format(timestampDate, "dd.MM.yyyy HH:mm") }}
        </div>
      </div>
    </div>
    <div
      v-if="!weatherData"
      class="px-4 py- h-full flex items-center text-sm justify-center text-center"
    >
      {{ $t("dashboard.project.weather_card.no_data") }}
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDroplet, faWind } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { format, getDay, parseISO } from "date-fns";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { onMounted, ref, computed } from "vue";
import WeatherRepository from "@/repositories/WeatherRepository";
import logger from "@/services/logger";
import { CurrentWeatherResponse } from "@/types/Weather";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";

const props = defineProps<{ width: number; height: number }>();
const emit = defineEmits<{ (eventName: "delete"): void; (eventName: "trackInteraction"): void }>();

library.add(faDroplet, faWind);

const isLoading = ref(false);
const weatherData = ref<CurrentWeatherResponse | null>(null);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const iconUrl = computed(() => {
  try {
    if (!weatherData.value) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    return require(`@/assets/imgs/weather_icons/${weatherData.value.item.icon_local}.png`);
  } catch (error) {
    logger.error(error);
    return null;
  }
});

const timestampDate = computed(() =>
  weatherData.value ? parseISO(weatherData.value.item.timestamp) : null,
);

const twoColumns = computed(() => props.width > 400);

const loadData = () => {
  isLoading.value = true;
  return WeatherRepository.getCurrentWeatherForSite(currentCustomerName, currentSiteId)
    .then((data) => {
      weatherData.value = data;
    })
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(() => {
  loadData();
});
</script>
