<template>
  <div class="md:px-6 px-4 sm:my-4">
    <div class="flex items-center justify-between">
      <h1 class="text-md font-extrabold text-gray-900 sm:text-2xl 2xl:text-3xl">
        <slot></slot>
      </h1>
      <slot name="content"></slot>
    </div>
  </div>
</template>
