<template>
  <div class="overflow-auto flex-1">
    <div v-if="openLeafNodes.length > 0">
      <div class="inline-block min-w-full align-middle">
        <table
          class="min-w-full divide-y divide-gray-300"
          :class="width > normalWidgetWidth ? 'text-sm' : 'text-xs'"
        >
          <thead>
            <tr class="py-2 font-semibold text-gray-900 whitespace-nowrap text-left">
              <th scope="col" class="pr-3">
                {{ $t("dashboard.project.planner_card.procedure") }}
              </th>
              <th scope="col">
                {{ $t("dashboard.project.planner_card.start_date") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="node in openLeafNodes" :key="node.id">
              <td class="py-2 pr-3 text-gray-600 whitespace-nowrap">
                {{ `${joinParents(node.parent_names, 2)} / ` }}
                <span class="font-medium">{{ node.name }}</span>
              </td>
              <td class="py-2 text-gray-500">
                {{ format(parseISO(node.actual_start), "dd.MM.yyyy") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-else
      class="py-2 text-sm truncate h-full flex flex-col items-center justify-center text-center"
    >
      {{ $t("dashboard.project.planner_card.no_current_procedures") }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { format, parseISO } from "date-fns";
import { normalWidgetWidth } from "@/views/dashboard/composables";
import { ActiveLeafNode } from "@/views/planner/types";

defineProps<{ openLeafNodes: ActiveLeafNode[]; width: number }>();

const joinParents = (parentList: string[], n: number) => {
  const lastNParents = parentList.slice(-n);
  return lastNParents.join(" > ");
};
</script>
