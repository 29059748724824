import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "oai-planner";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCustomToast } from "@/composables/toast";
import UserRepository from "@/repositories/UserRepository";
import logger from "@/services/logger";
import { User, UserProject, InviteUser, UserRole } from "@/types/User";

export const useUsersForProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasAdminPermissions = useHasPermission("app_admin");

  const { data, isLoading, error } = useQuery<User[]>({
    queryKey: ["usersForProject", currentCustomerName, currentSiteId],
    queryFn: async () => {
      if (hasAdminPermissions) {
        return await UserRepository.loadAllUsersForProject(currentCustomerName, currentSiteId);
      } else {
        return await UserRepository.loadUsersForProject(currentCustomerName, currentSiteId);
      }
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });
  const users = computed(() => data.value || []);
  return { users, isLoading, error };
};

export const useUpdateUserProjectPermission = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateUserProjectPermission,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<User, Error, { username: string; updatedProjectPermission: UserProject }>({
    mutationFn: ({ username, updatedProjectPermission }) =>
      UserRepository.updateUserProjectPermission(
        username,
        currentCustomerName,
        currentSiteId,
        updatedProjectPermission,
      ),
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) =>
          users
            ?.map((user) => (user._id === updatedUser._id ? updatedUser : user))
            .filter((user) =>
              user.projects.some(
                (project) =>
                  project.customer_name === currentCustomerName &&
                  project.site_id === currentSiteId,
              ),
            ),
      );
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.permission_update"));
    },
  });

  return { updateUserProjectPermission, isUpdateLoading, isUpdateError };
};

export const useAdminUpdateUserProperties = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: adminUpdateUserProperties,
    isLoading: isUpdatePropertiesLoading,
    isError: isUpdatePropertiesError,
  } = useMutation<User, Error, { username: string; name: string; company: string; role: UserRole }>(
    {
      mutationFn: ({ username, name, company, role }) =>
        UserRepository.adminUpdateUserProperties(username, name, company, role),
      onSuccess: (updatedUser) => {
        queryClient.setQueryData(
          ["usersForProject", currentCustomerName, currentSiteId],
          (users: User[] | undefined) =>
            users?.map((user) => (user._id === updatedUser._id ? updatedUser : user)),
        );
      },
      onError: (error) => {
        logger.error(error);
        useCustomToast().error(t("admin.project_participants.error.properties_update"));
      },
    },
  );

  return { adminUpdateUserProperties, isUpdatePropertiesLoading, isUpdatePropertiesError };
};

export const useBulkInviteUsers = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: bulkInviteUsers,
    isLoading: isInviteLoading,
    isError: isInviteError,
  } = useMutation<User[], Error, InviteUser[]>({
    mutationFn: (usersToInvite: InviteUser[]) =>
      UserRepository.bulkInviteUsers(currentCustomerName, currentSiteId, usersToInvite),
    onSuccess: (invitedUsers) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) => [...(users || []), ...invitedUsers],
      );
    },
    onError: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.invite"));
    },
  });

  return { bulkInviteUsers, isInviteLoading, isInviteError };
};

export const useDeleteUser = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteUser, isLoading } = useMutation<void, Error, string>({
    mutationFn: (username) => UserRepository.deleteUser(username),
    onSuccess: (result, username) => {
      queryClient.setQueryData(
        ["usersForProject", currentCustomerName, currentSiteId],
        (users: User[] | undefined) => users?.filter((user) => user.username !== username),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("admin.project_participants.error.delete"));
      return false;
    },
  });

  return { deleteUser, isLoading };
};
