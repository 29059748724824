<template>
  <div id="oai-main-container">
    <Sidebar :content="sidebarContent" currentSidebarTab="Projekte">
      <!-- Content area -->
      <Projects v-bind:projects="$store.state.projects" :thumbnailUrls="projectThumbnailUrls" />
    </Sidebar>
  </div>
</template>

<script>
import { BuildingOfficeIcon } from "@heroicons/vue/24/solid";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useThumbnailUrls } from "@/composables/project";
import Projects from "@/views/dashboard/components/Projects";

export default {
  data() {
    return {
      sidebarContent: [],
    };
  },
  components: {
    Sidebar,
    Projects,
  },

  mounted() {
    if (this.$store.state.projects) {
      this.sidebarContent = this.$store.state.projects.map((project) => ({
        name: project.name,
        href: `/${project.customer_name}/${project.site_id}/dashboard`,
        icon: BuildingOfficeIcon,
        current: false,
        section: "project",
      }));
    }

    const user = this.$store.state.user;
    analytics.track("Home Visit", {
      email: user.email,
      name: user.name,
      customer: user.company,
    });
  },
  setup() {
    const { projectThumbnailUrls } = useThumbnailUrls();
    return {
      projectThumbnailUrls,
    };
  },
};
</script>
