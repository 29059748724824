<template>
  <div class="tooltip">
    <InformationCircleIcon class="ml-1 h-5" :class="[color]" />
    <div
      class="tooltiptext ellipsis w-96 text-left text-xs text-white"
      :class="[alignCls]"
      :style="hoverStyle"
    >
      <slot name="text" />
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/24/solid";

export default {
  components: { InformationCircleIcon },
  props: {
    alignCls: {
      type: String,
      required: false,
      default: "tooltip-right",
    },
    color: {
      type: String,
      required: false,
      default: "text-green-500",
    },
    hoverStyle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
/** Tooltip **/
.tooltip {
  position: relative;
  display: inline;
}

.tooltip .tooltiptext {
  visibility: hidden;
  white-space: pre-wrap;
  background-color: black;
  opacity: 0.8;
  color: #fff;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
}

.tooltip-right {
  left: 105%;
}

.tooltip-right::before {
  /* position tooltip correctly */
  right: 100%;
  margin-left: -5px;
  /* the arrow */
  border: 10px solid;
  border-color: transparent black transparent transparent;
}

.tooltip-left {
  right: 105%;
}

.tooltip-left::before {
  /* position tooltip correctly */
  left: 100%;
  margin-right: -5px;
  /* the arrow */
  border: 10px solid;
  border-color: transparent transparent transparent black;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  top: 50%;
  transform: translateY(-50%);
}

.tooltiptext::before {
  content: "";
  position: absolute;
  /* vertically center */
  top: 50%;
  transform: translateY(-50%);
}

.tooltip:hover .tooltip-top {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -105%);
}

.tooltip-top::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: #000;
  transform: translate(-50%, 0);
}

.tooltip:hover .tooltip-bottom {
  position: absolute;
  z-index: 20;
  top: 110%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-bottom::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-bottom-color: #000;
  transform: translate(-50%, 0);
}
</style>
