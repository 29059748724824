<template>
  <ModalTW
    :open="true"
    @onCloseModal="emit('close')"
    customCls="m-4 w-full lg:w-1/2"
    :useContentScrollbars="true"
  >
    <template #content>
      <div class="text-left flex flex-col" :style="{ minHeight: '40vh' }">
        <div
          v-if="isProjectDurationSettingsLoading || areHierarchyTagsLoading"
          class="flex items-center justify-center flex-1"
        >
          <LoadingSpinner />
        </div>
        <div v-else class="pt-3 relative">
          <VueDatePicker
            input-class-name="text-xs"
            v-model="selectedDate"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            :locale="locale"
            auto-apply
            reverse-years
            class="absolute w-[150px] top-1 left-[0px]"
            :clearable="false"
          />
          <div
            class="flex gap-2 border-b text-xs mb-1 pb-3 px-4 overflow-auto"
            :style="{ scrollbarGutter: 'stable' }"
          >
            <div class="flex-1" />
            <OaiTooltip cls="w-[80px] flex items-center justify-center">
              {{ formatPercentage(totalPercentage) }}
              <template #tooltip>
                <div class="text-xs">
                  {{ formatNumber(totalProgress.finishedWorkingDays) }}
                  /
                  {{ formatNumber(totalProgress.totalWorkingDays) }}
                  {{ t("analytics.planner.working_days_label") }}
                </div>
              </template>
            </OaiTooltip>
            <div class="w-[80px] flex items-center justify-end">100%</div>
          </div>
          <div :style="{ maxHeight: '85vh', scrollbarGutter: 'stable' }" class="overflow-auto px-2">
            <ProjectProgressItem
              :key="plannerItem._id"
              v-for="plannerItem in plannerItemsWithChildren"
              :plannerItem="plannerItem"
              :plannerItemProgressById="plannerItemProgressById"
              :hierarchyTags="hierarchyTags"
            />
          </div>
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { startOfDay } from "date-fns";
import { PlanConfig, PlannerItemWithChildren, useProjectDurationSettings } from "oai-planner";
import ModalTW from "pct/src/components/modals/ModalTW.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "@/components/other/OaiTooltip.vue";
import { useHierarchyTags } from "@/composables/hierarchyTags";
import { apiClient } from "@/repositories/clients";
import projectProgressService from "@/services/projectProgressService";
import { PlannerItemProgress } from "@/types/Plan";
import ProjectProgressItem from "@/views/reports/plots/query_value/ProjectProgressItem.vue";

const props = defineProps<{ planConfig: PlanConfig }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const precision = 1;
const { locale, t } = useI18n();

const selectedDate = ref(startOfDay(new Date()));

const { projectDurationSettings, isProjectDurationSettingsLoading } =
  useProjectDurationSettings(apiClient);

const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const plannerItemsWithChildren = computed(() => {
  const roots = [] as PlannerItemWithChildren[];
  const plannerItemsById = props.planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = { ...plannerItem, children: [] };
    return acc;
  }, {} as Record<string, PlannerItemWithChildren>);

  projectProgressService
    .getPlannerItemsToProcess(props.planConfig, hierarchyTags.value)
    .forEach(({ _id }) => {
      const plannerItem = plannerItemsById[_id];
      if (plannerItem.parent_id) {
        const parent = plannerItemsById[plannerItem.parent_id];
        if (parent) {
          parent.children?.push(plannerItem);
        }
      } else {
        roots.push(plannerItem);
      }
    });

  return roots;
});

const plannerItemProgresses = computed(() =>
  projectDurationSettings.value
    ? projectProgressService.calculateProjectProgressItems(
        props.planConfig,
        projectDurationSettings.value,
        hierarchyTags.value,
        selectedDate.value,
      )
    : [],
);

const plannerItemProgressById = computed(() =>
  plannerItemProgresses.value.reduce((acc, plannerItemProgress) => {
    acc[plannerItemProgress._id] = plannerItemProgress;
    return acc;
  }, {} as Record<string, PlannerItemProgress>),
);

const totalProgress = computed(() =>
  projectProgressService.calculateProjectProgress(plannerItemProgresses.value),
);

const totalPercentage = computed(() =>
  projectProgressService.calculatePercentage(
    totalProgress.value.finishedWorkingDays,
    totalProgress.value.totalWorkingDays,
  ),
);
</script>
