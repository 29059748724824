import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import {
  EncodedLabel,
  SectionMaskRemappingChanges,
  SectionRemappingProcess,
  SimplifiedPlannerProcessWorkInterval,
} from "oai-planner";
import { apiClient } from "@/repositories/clients";
import { IssueReport, NewIssueReport } from "@/types/IssueReport";
import { ShortenedProcess } from "@/types/Process";

type BadProcessPatch =
  | { encoded_label: EncodedLabel }
  | { work_intervals: SimplifiedPlannerProcessWorkInterval<string>[] }
  | { section_mask_mapping: { id: null; level_id: string } };

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const remapUpdatedProcess = (process: ShortenedProcess<string> | SectionRemappingProcess) => {
  return {
    ...process,
    start_time: parseUtcDate(process.start_time),
    end_time: parseUtcDate(process.end_time),
    work_intervals: process.work_intervals.map((workInterval) => ({
      ...workInterval,
      start_time: parseUtcDate(workInterval.start_time),
      end_time: parseUtcDate(workInterval.end_time),
    })),
  };
};

const createIssueReport = (
  customerName: string,
  siteId: string,
  issue: NewIssueReport,
): Promise<IssueReport> =>
  apiClient
    .post<IssueReport>(`/data-issue-report/${customerName}/${siteId}`, issue)
    .then((response) => response.data);

const createSelfResolvedIssueReport = (
  customerName: string,
  siteId: string,
  issue: NewIssueReport,
): Promise<IssueReport> =>
  apiClient
    .post<IssueReport>(`/data-issue-report/${customerName}/${siteId}/resolved`, issue)
    .then((response) => response.data);

const resolveProcessIssue = (
  customerName: string,
  siteId: string,
  processId: string,
  payload: BadProcessPatch,
) =>
  apiClient
    .patch<ShortenedProcess<string>>(
      `/prd-validation/processes/${customerName}/${siteId}/${processId}`,
      payload,
    )
    .then((response) => remapUpdatedProcess(response.data));

const remapProcessToSectionMasks = (customerName: string, siteId: string, id: string) =>
  apiClient
    .post<SectionMaskRemappingChanges>(
      `/prd-validation/ops-review/section-remapping/${customerName}/${siteId}`,
      {
        filters: {
          ids: [id],
          camera_ids: [],
          start_date: null,
          end_date: null,
        },
        apply_section_changes: true,
        apply_conflict_changes: true,
      },
    )
    .then(
      (response) =>
        Object.fromEntries(
          Object.entries(response.data).map(
            ([key, value]: [
              string,
              SectionMaskRemappingChanges[keyof SectionMaskRemappingChanges],
            ]) => [key, Array.isArray(value) ? value.map(remapUpdatedProcess) : value],
          ),
        ) as unknown as SectionMaskRemappingChanges<Date>,
    );

export default {
  createIssueReport,
  createSelfResolvedIssueReport,
  resolveProcessIssue,
  remapProcessToSectionMasks,
};
