import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-165440d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sm:mt-0 md:mt-6 md:basis-1/2 basis-1/4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "md:text-lg leading-4 font-semibold text-gray-900 text-center sm:text-left capitalize" }
const _hoisted_4 = { class: "relative w-min" }
const _hoisted_5 = ["disabled", "value"]
const _hoisted_6 = ["disabled", "value"]
const _hoisted_7 = {
  key: 2,
  class: "text-red block"
}
const _hoisted_8 = {
  id: "chart-container",
  class: "md:basis-1/4 basis-3/4",
  style: {"flex-basis":"60%"},
  ref: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editMode ? _ctx.$emit('addHighlight') : '')),
    class: _normalizeClass([
      { 'border-yellow': _ctx.highlightSection === 'header' },
      'border bg-white shadow sm:rounded-lg md:px-6 px-3 py-6 weather sm:flex items-center sm:justify-between',
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("report.section_title.working_hours")), 1),
        (!_ctx.editMode && !_ctx.approved)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('activeEditMode'))),
              class: "pl-2"
            }, [
              _createVNode(_component_PencilIcon, { class: "w-4 h-4 pt-1" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex items-center mb-6 mt-3 sm:mb-0 w-min", _ctx.editMode ? 'editInput md:justify-start justify-center' : 'overviewInput w-min'])
        }, [
          _createElementVNode("input", {
            required: "",
            type: "time",
            class: "oai-inputs text-center min-w-min",
            name: "start_time",
            disabled: !_ctx.editMode,
            value: _ctx.siteDuration.start_time,
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateSiteDuration', $event)))
          }, null, 40, _hoisted_5),
          _createVNode(_component_minus_icon, { class: "pt-1 w-5 mx-3" }),
          _createElementVNode("input", {
            required: "",
            class: "oai-inputs text-center min-w-min",
            type: "time",
            name: "end_time",
            disabled: !_ctx.editMode,
            value: _ctx.siteDuration.end_time,
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('updateSiteDuration', $event)))
          }, null, 40, _hoisted_6)
        ], 2),
        (_ctx.validationError && !_ctx.siteDuration.start_time)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              class: _normalizeClass(['text-red absolute', !_ctx.siteDuration.start_time && 'left-0'])
            }, _toDisplayString(_ctx.$t("err.required")), 3))
          : _createCommentVNode("", true),
        (_ctx.validationError && !_ctx.siteDuration.end_time)
          ? (_openBlock(), _createElementBlock("small", {
              key: 1,
              class: _normalizeClass(['text-red absolute', !_ctx.siteDuration.end_time && 'right-0'])
            }, _toDisplayString(_ctx.$t("err.required")), 3))
          : _createCommentVNode("", true),
        (
            _ctx.validationError && _ctx.siteDuration.start_time?.localeCompare(_ctx.siteDuration.end_time) > 0
          )
          ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.$t("err.time")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, null, 512)
  ], 2))
}