<template>
  <ModalTW :open="true" @close="handleClose" customCls="w-full lg:w-[400px] m-2">
    <template #title> {{ t("dashboard.add_to_dashboard") }} </template>
    <template #content>
      <div
        class="flex flex-col gap-6 text-left overflow-hidden h-full max-h-[300px]"
        v-if="!isLoading"
      >
        <div class="flex flex-col gap-3 overflow-auto">
          <div v-for="category in categories" :key="category.type">
            <div
              class="text-gray-600 font-semibold flex items-center justify-between gap-2 truncate"
            >
              {{ t(`dashboard.menu_categories.${category.type}`) }}
            </div>
            <div>
              <div
                class="py-2 px-4 rounded-md flex items-center cursor-pointer"
                :class="isDashboardCreatingOrUpdating ? '' : 'hover:bg-yellow-200'"
                v-for="dashboard in category.dashboards"
                :key="dashboard._id"
                @click="toggleDashboard(dashboard)"
              >
                <input
                  type="checkbox"
                  :disabled="isDashboardCreatingOrUpdating"
                  :checked="selectedDashboards.includes(dashboard)"
                  class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 cursor-pointer disabled:text-gray-300"
                />
                <label
                  class="ml-3 text-sm truncate cursor-pointer"
                  :class="{ 'text-gray-400': isDashboardCreatingOrUpdating }"
                  >{{ dashboard.name }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          @click="handleSaveClick"
          :disabled="isDashboardCreatingOrUpdating"
          class="shrink-0 focus:outline-none inline-flex gap-2 w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 disabled:bg-gray-200"
        >
          <LoadingSpinner size="w-5 h-5" v-if="isDashboardCreatingOrUpdating" />
          {{ t("dashboard.add_button") }}
        </button>
      </div>
      <div class="flex items-center justify-center h-[200px]" v-else>
        <LoadingSpinner />
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import { useCreateOrUpdateDashboard } from "@/composables/dashboard";
import { useTrackEvent } from "@/composables/tracking";
import { Dashboard, DashboardWidgetToAddOrRemove, Widget } from "@/types/Dashboard";
import {
  useDashboardsWithDefault,
  useHasPermissionToEditDashboard,
  useLayoutContext,
} from "@/views/dashboard/composables";
import {
  addWidgetToDashboard,
  createDashboardMenuCategories,
} from "@/views/dashboard/services/projectOverviewV2Layout";

const props = defineProps<{ type: Exclude<Widget, "LoadingWidget">; arg: string | null }>();
const emit = defineEmits<{
  (eventName: "close"): void;
}>();

const { t } = useI18n();
const { layoutContext, isLoading: isLayoutContextLoading } = useLayoutContext();
const { dashboardsWithDefault, isLoading: areDashboardsLoading } =
  useDashboardsWithDefault(layoutContext);
const selectedDashboards = ref<Dashboard[]>([]);
const { createOrUpdateDashboard, isLoading: isDashboardCreatingOrUpdating } =
  useCreateOrUpdateDashboard();
const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();

const isLoading = computed(() => areDashboardsLoading.value || isLayoutContextLoading.value);

const categories = computed(() =>
  createDashboardMenuCategories(dashboardsWithDefault.value).filter(
    (category) => category.dashboards.length > 0 && hasPermissionToEditDashboard(category),
  ),
);
const trackEvent = useTrackEvent();
const update = async (dashboard: Dashboard) => {
  if (!layoutContext.value) {
    return;
  }
  const widget: DashboardWidgetToAddOrRemove = { type: props.type, arg: props.arg };
  const updatedDashboard = addWidgetToDashboard(dashboard, widget, layoutContext.value);
  if (updatedDashboard && widget.type === "ReportWidget") {
    trackEvent("reports_to-dashboard_apply", {
      widgetType: widget?.type,
    });
  }

  if (updatedDashboard) {
    await createOrUpdateDashboard(updatedDashboard);
  }
};

const toggleDashboard = (dashboard: Dashboard) => {
  if (isDashboardCreatingOrUpdating.value) {
    return;
  }
  if (selectedDashboards.value.includes(dashboard)) {
    const index = selectedDashboards.value.indexOf(dashboard);
    if (index > -1) {
      selectedDashboards.value.splice(index, 1);
    }
  } else {
    selectedDashboards.value.push(dashboard);
  }
};

const handleSaveClick = () => {
  if (isDashboardCreatingOrUpdating.value) {
    return;
  }
  Promise.all(selectedDashboards.value.map((dashboard) => update(dashboard)))
    .then(() => {
      emit("close");
    })
    .catch(() => undefined);
};

const handleClose = () => {
  emit("close");
};
</script>
