import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-4 max-w-3xl gap-4 px-2 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3" }
const _hoisted_2 = { class: "space-y-6 lg:col-span-2 lg:col-start-1" }
const _hoisted_3 = { class: "px-4 py-5 sm:px-6" }
const _hoisted_4 = {
  id: "applicant-information-title",
  class: "text-xl font-bold text-gray-700"
}
const _hoisted_5 = { class: "grid grid-cols-3" }
const _hoisted_6 = { class: "col-span-3 xl:col-span-2" }
const _hoisted_7 = { class: "pr-2 lg:pr-6" }
const _hoisted_8 = { class: "group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "col-span-3 pt-4 xl:col-span-1 xl:pt-0" }
const _hoisted_11 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_14 = { class: "mt-1 text-sm text-gray-900" }
const _hoisted_15 = {
  key: 0,
  class: ""
}
const _hoisted_16 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_17 = { class: "mt-1 text-sm text-gray-900" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_20 = { class: "mt-1 text-sm text-gray-900" }
const _hoisted_21 = {
  key: 2,
  class: ""
}
const _hoisted_22 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_23 = { class: "mt-1 text-sm text-gray-900" }
const _hoisted_24 = {
  href: "/",
  class: "block px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: {
          name: 'ProjectOverview',
          params: {
            customer_name: $props.projectInfo.customer_name,
            site_id: $props.projectInfo.site_id,
          },
        }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["shadow sm:rounded-lg", [$props.projectInfo.status === 'active' ? 'bg-white ' : 'bg-gray-200']])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString($props.projectInfo.name), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["border-t px-4 py-5 sm:px-4", [$props.projectInfo.status === 'active' ? 'border-gray-200' : 'border-gray-300']])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("img", {
                        src: $props.thumbnailUrl,
                        alt: "",
                        class: "bg-transparent bg-no-repeat bg-center bg-contain object-cover",
                        style: _normalizeStyle({
                        backgroundImage: `url('${$data.defaultProjectThumbnailUrl}')`,
                        textIndent: '-1000px',
                      }),
                        loading: "lazy"
                      }, null, 12, _hoisted_9)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("dl", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("dt", _hoisted_13, _toDisplayString($props.projectInfo.planned_end
                          ? _ctx.$t("dashboard.home.project_card.period")
                          : _ctx.$t("dashboard.home.project_card.start_date")), 1),
                      _createElementVNode("dd", _hoisted_14, _toDisplayString($options.formatStartEnd($props.projectInfo)), 1)
                    ]),
                    ($props.projectInfo.zip_code && $props.projectInfo.street)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("dt", _hoisted_16, _toDisplayString(_ctx.$t("dashboard.home.project_card.address")), 1),
                          _createElementVNode("dd", _hoisted_17, _toDisplayString($props.projectInfo.zip_code) + " " + _toDisplayString($props.projectInfo.street), 1)
                        ]))
                      : _createCommentVNode("", true),
                    ($props.projectInfo.status === 'completed')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("dt", _hoisted_19, _toDisplayString(_ctx.$t("dashboard.home.project_card.status")), 1),
                          _createElementVNode("dd", _hoisted_20, _toDisplayString(_ctx.$t("dashboard.home.project_card.status_completed")), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("dt", _hoisted_22, _toDisplayString(_ctx.$t("dashboard.home.project_card.status")), 1),
                          _createElementVNode("dd", _hoisted_23, _toDisplayString(_ctx.$t("dashboard.home.project_card.status_active")), 1)
                        ]))
                  ])
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["border-t", [
              $props.projectInfo.status === 'active' ? 'border-gray-200' : 'border-gray-300 bg-gray-200',
            ]])
            }, [
              _createElementVNode("a", _hoisted_24, _toDisplayString(_ctx.$t("dashboard.home.project_card.project_link")), 1)
            ], 2)
          ], 2)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}