<template>
  <div :style="sizeStyles" class="relative">
    <div :style="{ height: `${headerHeight}px` }" class="text-center pl-6 pr-8 truncate">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title || $t("analytics.reports.cycle_times_plot") }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="cycle_times"
      :filters="filters"
      :reportSummary="data"
      :aggregationLabel="t(`analytics.reports.by_${aggregation}`)"
      :height="filterBadgesHeight"
      class="ml-2"
    />
    <CycleTimesPopover
      v-if="!hidePopover"
      class="absolute top-1 right-4"
      @reportInfoClick="emit('reportInfoClick')"
    />
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <Chart :options="chartOptions" class="font-light text-b" />
    </div>
    <div
      v-if="areOutagesLoading || areHierarchyTagsLoading"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <LoadingSpinner />
    </div>
    <ModalTW
      :open="openWorkingDaysCalendar"
      @close="closeModal()"
      custom-cls="overflow-hidden"
      v-if="openWorkingDaysCalendar"
      ><template #title v-if="selectedColumnName">
        <div class="text-left border-b pb-3 text-ellipsis truncate">
          {{ selectedColumnName }}
        </div></template
      >
      <template #content v-if="selectedColumnProcesses">
        <WorkingDaysCalendar :processes="selectedColumnProcesses" />
      </template>
    </ModalTW>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import {
  durationService,
  useIsWorkingDay,
  useNonWorkingDaysByDaySet,
  useProjectDurationSettings,
} from "oai-planner";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useHierarchyTags } from "@/composables/hierarchyTags";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { useTrackEvent } from "@/composables/tracking";
import { apiClient } from "@/repositories/clients";
import processDurationService from "@/services/processDurationService";
import { DashboardGridSize } from "@/types/Dashboard";
import { HierarchyType } from "@/types/HierarchyTag";
import { ShortenedProcess } from "@/types/Process";
import {
  CycleTimesAggregation,
  CycleTimesReportConfig,
  CycleTimesReportFilters,
  CycleTimesReportSummary,
  CycleTimesReportSummaryProcessGroup,
} from "@/types/reports/PlotCycleTimes";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import CycleTimesPopover from "@/views/reports/plots/cycle_times/CycleTimesPopover.vue";

const defaultFullHeight = 600;

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    data?: CycleTimesReportSummary;
    reportId: string;
    filters: CycleTimesReportFilters;
    config: CycleTimesReportConfig;
    aggregation: CycleTimesAggregation;
    title?: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    hideLegend?: boolean;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["widgetRouteClicked", "reportInfoClick"]);

const { streams } = useStreams();
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(apiClient);
const isWorkingDay = useIsWorkingDay(apiClient);
const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const processClasses = useProcessClasses();
const { projectDurationSettings } = useProjectDurationSettings(apiClient);
const trackEvent = useTrackEvent();

const openWorkingDaysCalendar = ref(false);
const selectedColumnProcesses = ref<ShortenedProcess[]>([]);
const selectedColumnName = ref<string | null>();

const durationSettings = computed(() => {
  return projectDurationSettings.value
    ? durationService.calculateSettings(
        projectDurationSettings.value.working_hours,
        projectDurationSettings.value.non_working_days,
      )
    : null;
});

const closeModal = () => {
  openWorkingDaysCalendar.value = false;
  selectedColumnProcesses.value = [];
  selectedColumnName.value = null;
};

const seriesColors = {
  planned: "#BCCFCC",
  active: "#336260",
  inactive: "#991B1B",
  utilization: "#E7BC66",
  outages: "#FCA5A5",
} as const;

const { headerHeight, chartHeight, sizeStyles, filterBadgesHeight } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 2,
  },
);

const defaultChartOptions = computed(() => ({
  chart: {
    zoomType: "x",
    height: chartHeight.value,
  },
  title: {
    text: undefined,
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  yAxis: [
    {
      title: {
        text: t("working_day.working_day", 2),
      },
    },
  ],
  legend: { enabled: !props.hideLegend },
}));

const outagesInterval = computed(() => {
  if (!props.data) {
    return null;
  }
  const processData = props.data.process_groups.flatMap((item) => item.processes);

  if (!processData[0]) {
    return null;
  }

  const start = new Date(processData[0].start_time);
  const end = new Date(processData[0].end_time);

  processData.forEach((process) => {
    if (process.start_time < start) {
      start.setTime(process.start_time.getTime());
    }

    if (process.end_time > end) {
      end.setTime(process.end_time.getTime());
    }
  });

  return { start, end };
});

const { outagesByRange, isLoading: areOutagesLoading } = useOutagesByRange(outagesInterval);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const chartOptions = computed(() => {
  if (!props.data) {
    return defaultChartOptions.value;
  }

  let shouldShowPlannedChart = false;

  const processesFilter = props.filters.processes;

  if (
    processesFilter.mode === "single_process" &&
    (!processesFilter.single_processes.length ||
      processesFilter.single_processes.length === processClasses.value.length)
  ) {
    shouldShowPlannedChart = true;
  } else if (processesFilter.mode === "component") {
    const isAggregatedBySection = props.aggregation === "section";
    const isAggregatedByPreset =
      hierarchyTags.value.filter((tag) => {
        const componentToCompare = processesFilter.components.find((component) => {
          return component.id === tag._id;
        });

        if (!componentToCompare) {
          return false;
        }

        const isAllProcessesSelected = tag.attached_processes.every((process) => {
          return componentToCompare.processes.includes(process);
        });

        return tag.type === "component" && isAllProcessesSelected;
      }).length === 1;

    const noProcessSelected = !processesFilter.components.some((component) => {
      return component.processes.length;
    });

    shouldShowPlannedChart = (isAggregatedBySection && isAggregatedByPreset) || noProcessSelected;
  }

  const aggregatedData = makeDataAggregation(props.data, props.aggregation);
  return getChartOptions(
    aggregatedData,
    shouldShowPlannedChart && props.config.show_planned,
    props.config,
  );
});

const makeDataAggregation = (data: CycleTimesReportSummary, aggregationLevel: HierarchyType) => {
  const aggregations = {} as Record<string, CycleTimesReportSummaryProcessGroup[]>;

  (data.process_groups || []).forEach((item) => {
    const keys = [];
    const building = item.section_mask_mapping.building_name;
    const level = item.section_mask_mapping.level_name;
    const section = item.section_mask_mapping.section_name;

    if (aggregationLevel === "building") {
      keys.push(building);
    } else if (aggregationLevel === "level") {
      keys.push(building, level);
    } else if (aggregationLevel === "section") {
      keys.push(building, level, section);
    }

    const key = keys.filter(Boolean).join(" - ");

    if (!aggregations[key]) {
      aggregations[key] = [];
    }

    aggregations[key].push(item);
  });

  const prettifiedAggregation = Object.entries(aggregations).reduce(
    (acc, [key, value]) => {
      acc.push({
        name: key,
        processes: value.flatMap((item) => item.processes as ShortenedProcess[]),
        planned_events: value.map((item) => item.planned_event),
        is_active: value.some((item) => item.is_active),
      });

      return acc;
    },
    [] as {
      name: string;
      processes: ShortenedProcess[];
      planned_events: { start: Date; end: Date }[];
      is_active: boolean;
    }[],
  );

  return prettifiedAggregation;
};

const getChartOptions = (
  data: {
    name: string;
    processes: ShortenedProcess[];
    planned_events: { start: Date; end: Date }[];
    is_active: boolean;
  }[],
  showPlannedChart: boolean,
  config: CycleTimesReportConfig,
) => {
  if (!durationSettings.value) {
    return defaultChartOptions.value;
  }

  const categories = data.map((item) => item.name);
  const activeCategories = new Set(data.map((item, i) => item.is_active && i));

  const plannedDuration: (number | string)[][] = [];
  const inactiveDuration: (number | string)[][] = [];
  const activeDuration: (number | string)[][] = [];
  const utilization: (number | string | undefined)[][] = [];
  const outagesDuration: (number | string)[][] = [];

  data.forEach((item) => {
    const total = { days: 0, hours: 0 };
    let planned = 0;

    if (!durationSettings.value || !projectDurationSettings.value) {
      return;
    }

    const firstProcess = new Date();
    const lastProcess = new Date(0);
    const firstEvent = new Date();
    const lastEvent = new Date(0);

    item.processes.forEach((process) => {
      if (process.start_time < firstProcess) {
        firstProcess.setTime(process.start_time.getTime());
      }

      if (process.end_time > lastProcess) {
        lastProcess.setTime(process.end_time.getTime());
      }
    });

    item.planned_events.forEach((event) => {
      if (event.start < firstEvent) {
        firstEvent.setTime(event.start.getTime());
      }

      if (event.end > lastEvent) {
        lastEvent.setTime(event.end.getTime());
      }
    });

    const totalDuration = durationService.calculateDuration(
      durationSettings.value,
      firstProcess,
      lastProcess,
    );

    total.days = totalDuration.workingDays;
    total.hours = totalDuration.workingHours;

    planned = durationService.calculateDuration(
      durationSettings.value,
      firstEvent,
      lastEvent,
    ).workingDays;

    const formattedStart = format(firstProcess, "dd.MM.yyyy");
    const formattedEnd = format(lastProcess, "dd.MM.yyyy");

    const utilizationValue = processDurationService.calculateUtilizationFromDayDuration(
      total.days,
      item.processes,
      durationSettings.value,
    );

    const processDetailMap = processDurationService.getProcessDetailMap(
      item.processes,
      projectDurationSettings.value,
      outages.value,
    );
    const processDetailSummary = processDurationService.getProcessDetailSummary(processDetailMap);

    const metadata = [formattedStart, formattedEnd, total.days];

    plannedDuration.push([planned, t("working_day.working_day", 2), ...metadata]);
    inactiveDuration.push([
      processDetailSummary.inactive,
      t("working_day.working_day", 2),
      ...metadata,
    ]);
    activeDuration.push([
      processDetailSummary.active,
      t("working_day.working_day", 2),
      ...metadata,
    ]);
    utilization.push([utilizationValue, "%", ...metadata]);
    outagesDuration.push([
      processDetailSummary.outage,
      t("working_day.working_day", 2),
      ...metadata,
    ]);
  });

  const seriesConfig = {
    keys: ["y", "suffix", "start", "end", "total"],
    visible: !areOutagesLoading.value,
  };

  const series = [];

  if (showPlannedChart) {
    series.push({
      name: t("analytics.reports.planned"),
      data: plannedDuration,
      color: seriesColors.planned,
      type: "column",
      stacking: undefined,
      yAxis: 0,
      ...seriesConfig,
    });
  }

  if (config.show_outages) {
    series.push({
      name: t("working_day.outage"),
      data: outagesDuration,
      color: seriesColors.outages,
      stacking: "normal",
      type: "column",
      yAxis: 0,
      tooltip: {
        valueSuffix: ` ${t("working_day.working_day", 2)}`,
      },
      ...seriesConfig,
    });
  }

  if (config.show_inactive_days) {
    series.push({
      name: t("working_day.inactive"),
      data: inactiveDuration,
      color: seriesColors.inactive,
      stacking: "normal",
      type: "column",
      yAxis: 0,
      tooltip: {
        valueSuffix: ` ${t("working_day.working_day", 2)}`,
      },
      ...seriesConfig,
    });
  }

  if (config.show_active_days) {
    series.push({
      name: t("working_day.active"),
      data: activeDuration,
      color: seriesColors.active,
      stacking: "normal",
      type: "column",
      yAxis: 0,
      tooltip: {
        valueSuffix: ` ${t("working_day.working_day", 2)}`,
      },
      ...seriesConfig,
    });
  }

  if (workingHoursFeatureEnabled && config.show_utilization) {
    series.push({
      name: t("analytics.reports.utilization"),
      data: utilization,
      color: seriesColors.utilization,
      type: "spline",
      yAxis: 1,
      tooltip: {
        valueSuffix: "%",
      },
      lineWidth: 2,
      zIndex: 1,
      ...seriesConfig,
    });
  }

  return {
    ...defaultChartOptions.value,
    xAxis: {
      categories,
      labels: {
        useHTML: true,
        style: {
          whiteSpace: "no-wrap",
        },
        autoRotationLimit: 1000,
        formatter: function () {
          const position = (this as unknown as { pos: number }).pos;
          const value = (this as unknown as { value: string }).value;

          if (activeCategories.has(position)) {
            return `<span><span class="text-green">●</span> ${value}</span>`;
          }

          return value;
        },
      },
      crosshair: true,
    },
    yAxis: [
      ...defaultChartOptions.value.yAxis,
      ...(workingHoursFeatureEnabled && config.show_utilization
        ? [
            {
              title: {
                text: t("analytics.reports.utilization"),
              },
              labels: {
                format: "{value}%",
              },
              min: 0,
              opposite: true,
            },
          ]
        : []),
    ],
    series: series,
    tooltip: {
      shared: true,
      useHTML: true,
      outside: true,
      positioner: (labelWidth: number, labelHeight: number, point: Record<string, number>) => ({
        x: point.plotX - labelWidth * 0.9,
        y: point.plotY,
      }),
      formatter: function () {
        const tooltipContent = (
          this as unknown as { points: Record<string, Record<string, unknown>>[] }
        ).points.reduce(function (s, point, i) {
          if (i === 0) {
            s += `
          <p>
            ${t("analytics.processes.start")}: <span class="font-bold">${point.point.start}</span>
          </p>
          <p>${t("analytics.processes.end")}: <span class="font-bold">${
              activeCategories.has(point.point.x as number)
                ? `<span class="inline-block font-light text-xs rounded-md px-2 py-0 bg-green text-white">
                      ${t("analytics.reports.in_progress")}
                    </span>`
                : point.point.end
            }</span></p>
          <p class="mb-1">
            ${t("analytics.reports.total")}: <span class="font-bold">
            ${point.point.total} ${t("working_day.working_day", 2)}</span>
          </p>`;
          }

          return `${s}<p>
            <span style="color: ${point.color}">●</span>
              ${point?.series?.name}:
              <span class="font-bold">${point.y} ${point.point.suffix}</span>
          </p>`;
        }, "<p class='text-sm mb-2 pr-1 border-b border-gray-200'>" +
          (this as unknown as { x: string }).x +
          "</p>");

        return `<div class="p-1">${tooltipContent}</div>`;
      },
    },
    plotOptions: {
      column: {
        point: {
          events: {
            click: (e: Highcharts.PointClickEventObject) => {
              trackEvent("activity-calendar_view", { origin: "cycle_times_plot" });
              const aggregatedProcesses =
                props.data && makeDataAggregation(props.data, props.aggregation);
              const item = aggregatedProcesses?.find((item) => item.name === e.point.category) as {
                name: string;
                processes: ShortenedProcess[];
                planned_events: { start: Date; end: Date }[];
                is_active: boolean;
              };
              openWorkingDaysCalendar.value = true;
              selectedColumnProcesses.value = item?.processes;
              selectedColumnName.value = item?.name;
            },
          },
        },
      },
    },
  };
};
</script>
