<template>
  <div class="fixed inset-0 flex justify-center items-center flex-col">
    <img class="mx-auto h-12 w-auto mb-6" :src="oculaiLogoSrc" alt="oculai logo" />
    <div class="mb-3">{{ t("authentication.session_expired.message") }}</div>
    <div @click="refreshPage" class="cursor-pointer text-blue-700 hover:underline">
      {{ t("authentication.session_expired.refresh_page") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import oculaiLogoSrc from "@/assets/imgs/logo/logo-multiple-no-padding.svg";

const { t } = useI18n();
const refreshPage = () => {
  window.location.reload();
};
</script>
