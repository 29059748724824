<template>
  <div class="border text-gray-900 bg-white shadow flex flex-col relative">
    <div class="flex-1 flex flex-col gap-8 overflow-auto px-4 pt-8 pb-3">
      <div class="flex-1 flex flex-col gap-8">
        <div v-for="category in categories" :key="category.name" class="flex flex-col gap-2">
          <div
            :class="[
              'font-semibold text-lg mb-2 gap-2 items-center',
              (category.name !== 'report' || category.widgets.length) && 'flex justify-between',
            ]"
          >
            <div class="flex gap-2 items-center">
              <Component :is="category.icon" class="w-5 h-5 text-yellow-600" />
              <div class="flex-1 truncate">{{ $t(`dashboard.categories.${category.name}`) }}</div>
            </div>

            <div class="font-normal flex items-center shrink-0" v-if="category.name === 'report'">
              <AddReportMenu
                @createReport="addReportModalPlotType = $event"
                :menuPosition="category.widgets.length ? 'left' : 'center'"
                class="w-full"
              >
                <template #icon>
                  <div
                    v-if="category.widgets.length"
                    class="text-xs flex items-center gap-1 text-gray-900 hover:underline cursor-pointer"
                  >
                    <PlusIcon class="w-4 h-4" />
                    <div>{{ t("analytics.reports.dashboard_new_report") }}</div>
                  </div>
                  <div v-else class="bg-gray-50 px-2 py-3 mt-2 border w-full rounded gap-1">
                    <PlusIcon class="h-5 w-5 mx-auto" />
                  </div>
                </template>
              </AddReportMenu>
            </div>
          </div>
          <div
            v-for="widget in category.widgets"
            :key="`${widget.type}_${widget.arg}`"
            class="cursor-grab active:cursor-grabbing bg-gray-50 px-2 py-3 border text-sm rounded gap-1 select-none truncate"
            @dblclick="
              emit('addWidget', widget);
              trackEvent('dashboard_widget_add', { type: widget.type });
            "
            draggable="true"
            @dragstart="handleWidgetDragStart($event, widget)"
            @drag="emit('widgetDrag', $event)"
            @dragend="
              emit('widgetDragEnd', $event);
              trackEvent('dashboard_widget_add', { type: widget.type });
            "
          >
            {{ widget.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-gray-600 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center"
      v-if="widgets.length === 0"
    >
      {{ $t("dashboard.no_widgets_to_add") }}
    </div>
    <XMarkIcon
      class="h-5 w-5 absolute cursor-pointer top-3 right-4 text-gray-600"
      @click="emit('close')"
    />
    <AddReportModal
      v-if="addReportModalPlotType"
      :plotType="addReportModalPlotType"
      @close="addReportModalPlotType = null"
      @done="handleReportDone"
    />
    <div
      ref="widgetPreviewRef"
      class="fixed"
      :style="{
        top: '-10000px',
        left: '-10000px',
      }"
    >
      <BaseWidget class="px-4 py-3">
        <div class="flex flex-col gap-2 h-full">
          <div id="oaiWidgetPreviewTitle" class="truncate font-semibold text-gray-900 text-lg" />
          <div class="bg-gray-300 flex-1 rounded" />
        </div>
      </BaseWidget>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { PlusIcon, ChartBarIcon, CameraIcon, FolderIcon } from "@heroicons/vue/24/solid";
import { useHasPermission } from "oai-planner";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTrackEvent } from "@/composables/tracking";
import { DashboardGridSize, OculaiLayoutItem } from "@/types/Dashboard";
import { Report, ReportPlotType } from "@/types/Report";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import {
  calculateRealWidgetHeight,
  calculateRealWidgetWidth,
} from "@/views/dashboard/services/projectOverviewV2Layout";
import AddReportMenu from "@/views/reports/components/AddReportMenu.vue";
import AddReportModal from "@/views/reports/components/AddReportModal.vue";

const props = defineProps<{
  widgets: OculaiLayoutItem[];
  dashboardGridSize: DashboardGridSize;
}>();
const emit = defineEmits<{
  (eventName: "addWidget", widget: OculaiLayoutItem): void;
  (eventName: "close"): void;
  (eventName: "widgetDragStart", event: DragEvent): void;
  (eventName: "widgetDrag", event: DragEvent): void;
  (eventName: "widgetDragEnd", event: DragEvent): void;
}>();

const { t } = useI18n();

const addReportModalPlotType = ref<ReportPlotType | null>(null);
const widgetPreviewRef = ref<HTMLDivElement | null>(null);

const hasProcessesPermission = useHasPermission("processes_user");

const trackEvent = useTrackEvent();

const categories = computed(() =>
  [
    {
      name: "camera",
      widgets: props.widgets.filter((widget) => widget.type === "StreamWidget"),
      icon: CameraIcon,
    },
    {
      name: "report",
      widgets: props.widgets.filter((widget) => widget.type === "ReportWidget"),
      icon: ChartBarIcon,
    },
    {
      name: "other",
      widgets: props.widgets.filter(
        (widget) => widget.type !== "StreamWidget" && widget.type !== "ReportWidget",
      ),
      icon: FolderIcon,
    },
  ].filter((category) => {
    if (category.name === "report") {
      return hasProcessesPermission;
    }
    return category.widgets.length > 0;
  }),
);

const handleReportDone = (report: Report) => {
  addReportModalPlotType.value = null;
  const widgetToAdd = props.widgets.find(
    (widget) => widget.type === "ReportWidget" && widget.arg === report._id,
  );
  if (widgetToAdd) {
    emit("addWidget", widgetToAdd);
  }
};

const prepareWidgetPreview = (widget: OculaiLayoutItem) => {
  if (!widgetPreviewRef.value) {
    return;
  }
  const width = calculateRealWidgetWidth(props.dashboardGridSize, widget.w);
  const height = calculateRealWidgetHeight(props.dashboardGridSize, widget.h);
  widgetPreviewRef.value.style.width = `${width}px`;
  widgetPreviewRef.value.style.height = `${height}px`;
  const titleElement = widgetPreviewRef.value.querySelector("#oaiWidgetPreviewTitle");
  if (titleElement) {
    titleElement.innerHTML = widget.name;
  }
};

const handleWidgetDragStart = (event: DragEvent, widget: OculaiLayoutItem) => {
  if (!event.dataTransfer) {
    return;
  }

  event.dataTransfer.effectAllowed = "move";

  event.dataTransfer.clearData();
  event.dataTransfer.setData("application/json", JSON.stringify(widget));

  if (widgetPreviewRef.value) {
    prepareWidgetPreview(widget);
    event.dataTransfer.setDragImage(widgetPreviewRef.value, 0, 0);
  }

  emit("widgetDragStart", event);
};
</script>
