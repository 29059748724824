<template>
  <div v-if="!loading">
    <ul role="list" class="space-y-6">
      <li
        v-for="(plannerComment, index) in comments"
        :key="plannerComment._id"
        class="relative flex gap-x-4"
      >
        <div
          :class="[
            index === comments.length - 1 ? 'h-6' : '-bottom-6',
            'absolute left-0 top-0 flex w-6 justify-center',
          ]"
        >
          <div class="w-px bg-gray-200" />
        </div>
        <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
          <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
        </div>
        <div class="flex-auto">
          <div class="flex justify-between gap-x-4">
            <div class="py-0.5 text-xs leading-5 text-gray-500">
              <span class="font-medium text-gray-900">{{
                plannerComment.created_by_name || plannerComment.created_by
              }}</span>
              {{ $t("analytics.planner.comments.commented") }}
            </div>
            <div class="flex flex-row items-center gap-2">
              <div
                :datetime="plannerComment.created"
                class="flex-none py-0.5 text-xs leading-5 text-gray-500"
              >
                {{ formatInTimeAgo(plannerComment.created) }}
              </div>
              <Menu as="div" class="inline-block text-left leading-none">
                <MenuButton
                  class="flex items-center rounded-full text-gray-500 hover:text-gray-600"
                >
                  <EllipsisVerticalIcon class="h-6 w-6" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-5 z-10 mt-2 w-35 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
                  >
                    <div class="py-1 divide-y">
                      <MenuItem
                        v-if="plannerComment.created_by === email"
                        v-slot="{ active }"
                        @click="handleDeleteClick(plannerComment)"
                      >
                        <a
                          href="#"
                          :class="[
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          ]"
                        >
                          <TrashIcon class="w-4 h-4 text-gray-600" />
                          <span class="ml-1">
                            {{ $t("analytics.planner.comments.delete_comment_menu_item") }}
                          </span>
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <p class="text-sm leading-6 text-gray-500 whitespace-pre-wrap">
            {{ plannerComment.comment }}
          </p>
        </div>
      </li>
    </ul>
    <div class="mt-6 flex gap-x-3" :class="[comments.length > 0 ? 'ml-9' : '']">
      <div class="relative flex-auto">
        <div
          class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
        >
          <label for="comment" class="sr-only">{{
            $t("analytics.planner.comments.add_comment_label")
          }}</label>
          <textarea
            rows="2"
            class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            :placeholder="`${$t('analytics.planner.comments.add_comment_label')}...`"
            v-model="comment"
            maxlength="10240"
          />
        </div>
        <div class="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
          <button
            type="button"
            class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300"
            :class="[
              isCommentButtonEnabled
                ? 'hover:bg-gray-50 text-gray-900'
                : 'cursor-default text-gray-300',
            ]"
            @click="createComment"
            :disabled="!isCommentButtonEnabled"
          >
            {{ $t("buttons.comment") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="flex flex-col items-center gap-3">
    <LoadingSpinner />
    <span>{{ $t("analytics.planner.comments.loading_message") }}</span>
  </div>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { TrashIcon } from "@heroicons/vue/24/solid";
import { parseISO } from "date-fns";
import TimeAgo from "javascript-time-ago";
import { PlannerComment } from "oai-planner";
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { useConfirmationModal } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";

export default defineComponent({
  name: "PlannerComments",
  props: {
    sourceId: {
      type: String,
      required: true,
    },
  },
  components: {
    EllipsisVerticalIcon,
    LoadingSpinner,
    TrashIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  mounted() {
    this.load();
  },
  emits: ["commentAdded", "commentDeleted", "confirmationModalIsOpen", "confirmationModalIsClosed"],
  data() {
    return {
      comments: [] as PlannerComment[],
      comment: "" as string,
      loading: false,
      creatingComment: false,
    };
  },
  computed: {
    isCommentButtonEnabled() {
      return !this.creatingComment && this.comment;
    },
    email() {
      return this.$store.state.user.email;
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        this.comments = await PlannerRepository.loadPlannerComments(
          this.currentCustomerName,
          this.currentSiteId,
          this.sourceId,
        );
      } catch (error) {
        logger.error("Unable to load comments", error as Error);
      } finally {
        this.loading = false;
      }
    },
    parseDate(dateString: string) {
      return parseISO(dateString);
    },
    formatInTimeAgo(dateString: string) {
      const timeAgo = new TimeAgo(this.$i18n.locale);
      const date = this.parseDate(dateString);
      // creation date should never lay in the future
      const finalDate = date < new Date() ? date : new Date();

      return timeAgo.format(finalDate);
    },
    async createComment() {
      try {
        this.creatingComment = true;
        const newComment = await PlannerRepository.createComment(
          this.currentCustomerName,
          this.currentSiteId,
          this.comment,
          this.sourceId,
        );
        this.comments.push(newComment);
        this.comment = "";
        this.$emit("commentAdded", newComment);
      } catch (error) {
        logger.error("Unable to create comment", error as Error);
        alert(this.$t("analytics.planner.comments.unable_to_create_error"));
      } finally {
        this.creatingComment = false;
      }
    },

    async handleDeleteClick(comment: PlannerComment) {
      this.$emit("confirmationModalIsOpen");
      const isDeletionConfirmed = await this.showConfirmationModal({
        color: "red",
        header: this.$t("analytics.planner.comments.delete_confirmation_title"),
        confirmAction: this.$t("analytics.planner.comments.delete_confirmation_confirm"),
        cancelAction: this.$t("analytics.planner.comments.delete_confirmation_cancel"),
      });
      this.$emit("confirmationModalIsClosed");

      if (!isDeletionConfirmed || !comment) {
        return;
      }

      const { customer_name, site_id } = this.$route.params;

      try {
        await PlannerRepository.deleteComment(
          customer_name as string,
          site_id as string,
          comment._id,
        );
        this.comments.splice(this.comments.indexOf(comment), 1);
        this.$emit("commentDeleted", comment);
      } catch (error) {
        logger.error("Unable to delete comment", error as Error);
        alert(this.$t("analytics.planner.comments.unable_to_delete_error"));
      }
    },
  },

  setup() {
    const showConfirmationModal = useConfirmationModal();

    return {
      showConfirmationModal,
    };
  },
});
</script>
