<template>
  <div
    class="text-left flex flex-col gap-1 bg-white p-4 rounded border shadow"
    style="width: 370px"
  >
    <div
      class="flex gap-2 items-center mb-2 text-sm"
      v-if="unitValue.actual_start || unitValue.actual_end"
    >
      <span class="truncate col-span-1">{{ $t("buttons.time_range") }}:</span>
      <div class="flex gap-1 items-center">
        <span class="font-semibold truncate text-right" v-if="unitValue.actual_start">
          {{ format(unitValue.actual_start, "dd.MM.yy") }}</span
        >
        <span>-</span>
        <span class="font-semibold truncate text-right">
          {{
            unitValue.actual_end
              ? format(unitValue.actual_end, "dd.MM.yy")
              : $t("analytics.reports.present")
          }}</span
        >
        <span />
      </div>
    </div>
    <div v-if="unitValueAggregate && field === 'value' && hasPermission('app_admin')" class="mb-8">
      <div class="container">
        <span class="truncate" title="Median">Median:</span>
        <span
          class="font-semibold truncate text-right font-mono"
          :title="formatNumberOrFallback(unitValueAggregate.quantile_50th)"
          >{{ formatNumberOrFallback(unitValueAggregate.quantile_50th) }}</span
        >
        <span />
      </div>
      <div class="container">
        <span class="truncate" title="Confidence">Confidence:</span>
        <span
          class="font-semibold truncate text-right font-mono"
          :title="formatNumberOrFallback(unitValueAggregate.confidence)"
          >{{ formatNumberOrFallback(unitValueAggregate.confidence) }}</span
        >
        <span />
      </div>
      <div class="container">
        <span class="truncate" title="Lower limit">Lower limit:</span>
        <span
          class="font-semibold truncate text-right font-mono"
          :title="formatNumberOrFallback(unitValueAggregate.confidence_lower_limit)"
          >{{ formatNumberOrFallback(unitValueAggregate.confidence_lower_limit) }}</span
        >
        <span />
      </div>
      <div class="container">
        <span class="truncate" title="Lower limit">Upper limit:</span>
        <span
          class="font-semibold truncate text-right font-mono"
          :title="formatNumberOrFallback(unitValueAggregate.confidence_upper_limit)"
          >{{ formatNumberOrFallback(unitValueAggregate.confidence_upper_limit) }}</span
        >
        <span />
      </div>
    </div>
    <div class="container" :class="{ 'line-through': detail.oculai !== detail.oculai_adjusted }">
      <span class="truncate" title="oculai">oculai:</span>
      <span
        class="font-semibold truncate text-right font-mono"
        :title="formatNumberOrFallback(detail.oculai)"
      >
        {{ formatNumberOrFallback(detail.oculai) }}
      </span>
      <span />
    </div>
    <div class="container" v-if="detail.oculai !== detail.oculai_adjusted">
      <span class="truncate" :title="$t('unit_values.detail_oculai_new_calculated')"
        >{{ $t("unit_values.detail_oculai_new_calculated") }}:</span
      >
      <span
        class="font-semibold truncate text-right font-mono"
        :title="formatNumberOrFallback(detail.oculai_adjusted)"
      >
        {{ formatNumberOrFallback(detail.oculai_adjusted) }}
      </span>
      <span />
    </div>
    <div class="container" v-if="detail.type_adjustment !== null">
      <span class="truncate" :title="$t('unit_values.detail_delta_label')"
        >{{ $t("unit_values.detail_delta_label") }}:</span
      >
      <span class="font-semibold truncate text-right font-mono"
        ><span
          v-if="detail.type_adjustment_difference !== null && detail.type_adjustment_difference > 0"
          >+</span
        ><span :title="formatNumberOrFallback(detail.type_adjustment_difference)">{{
          formatNumberOrFallback(detail.type_adjustment_difference)
        }}</span>
      </span>
      <span
        v-if="field === 'duration' && unitValue.type_duration_factor !== null"
        class="ml-1 font-mono"
        >({{ formatNumberOrFallback(unitValue.type_duration_factor * 100) }}%)</span
      >
    </div>
    <div class="container" v-if="detail.custom_value !== null">
      <span class="truncate" :title="$t('unit_values.detail_custom_delta_label')"
        >{{ $t("unit_values.detail_custom_delta_label") }}:</span
      >
      <span class="font-semibold truncate text-right font-mono"
        ><span v-if="detail.custom_value_difference !== null && detail.custom_value_difference > 0"
          >+</span
        ><span :title="formatNumberOrFallback(detail.custom_value_difference)">{{
          formatNumberOrFallback(detail.custom_value_difference)
        }}</span>
      </span>
    </div>
    <div class="container border-t pt-1">
      <span class="truncate">{{ fieldLabels[field] }}:</span>
      <span
        class="font-semibold truncate text-right font-mono"
        :title="formatNumberOrFallback(detail.final_value)"
        >{{ formatNumberOrFallback(detail.final_value) }}</span
      >
      <span class="truncate font-semibold ml-1 font-mono" :title="unitLabel">{{ unitLabel }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import numberService from "@/services/numberService";
import { UnitValue, UnitValueAggregate, UnitValueDetailField } from "@/types/UnitValue";
import { createFormatNumber } from "@/views/unit_values/services/unitValues";
import valueCellDetailCalculator from "@/views/unit_values/services/valueCellDetailCalculator";

const props = defineProps({
  unitValue: {
    type: Object as PropType<UnitValue>,
    required: true,
  },
  unitValueAggregate: {
    type: Object as PropType<UnitValueAggregate>,
    required: false,
  },
  field: {
    type: String as PropType<UnitValueDetailField>,
    required: true,
  },
});

const { t } = useI18n();

const formatNumber = createFormatNumber(useI18n().locale.value);
const decimalSeparator = numberService.getDecimalSeparator();

const fieldLabels: Record<UnitValueDetailField, string> = {
  duration: t("unit_values.duration_label"),
  workforce: t("unit_values.workforce_label"),
  value: t("unit_values.unit_value_label"),
};

const unitLabel = computed(() => {
  const labels: Record<UnitValueDetailField, string> = {
    duration: t("unit_values.duration_unit"),
    workforce: "",
    value: `${t("unit_values.unit_value_unit")}/${t(
      `unit_values.types.${props.unitValue.type}.unit`,
    )}`,
  };
  return labels[props.field];
});

const detail = computed(() => valueCellDetailCalculator(props.unitValue)[props.field]);

const formatNumberOrFallback = (value: number | null) =>
  formatNumber(value) ?? `-${decimalSeparator}--`;
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 130px 120px 70px;
}
</style>
