import { createI18n } from "vue-i18n";
import de from "./assets/i18n/de.json";
import en from "./assets/i18n/en.json";
import fr from "./assets/i18n/fr.json";

type MessageSchema = typeof en;
type Languages = "en" | "de" | "fr";

const messages: Record<Languages, MessageSchema> = {
  en: en,
  de: de as unknown as MessageSchema,
  fr: fr as unknown as MessageSchema,
};

const customPluralRule = (choice: number) => {
  return choice === 1 ? 0 : 1;
};

const i18n = createI18n<[MessageSchema], Languages>({
  locale: localStorage.getItem("lang") || "de",
  messages,
  pluralRules: {
    en: customPluralRule,
    de: customPluralRule,
    fr: customPluralRule,
  },
});

export default i18n;
