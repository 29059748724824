<template>
  <div class="border-t border-gray-600" :style="`width: ${columnWidth}px`">
    <div
      class="p-1 text-md text-center border-b border-gray-600 bg-white"
      :class="{
        'border-r': isLastType,
      }"
      :style="`height: ${firstHeaderRowHeight}px`"
    >
      <div
        class="inline-block truncate font-semibold"
        style="max-width: calc(100% - 30px); margin-bottom: -4px"
      >
        <OaiTooltip simple>
          <div class="cursor-default">
            {{ $t(`unit_values.types.${type}.label`) }}
          </div>
          <template #tooltip v-if="processes.length">
            <p class="text-xs mb-0.5">{{ $t("unit_values.header_included_processes") }}:</p>
            <ul class="list-disc list-inside">
              <li v-for="process in processes" :key="process" class="text-xs">
                {{ $t(`process_classes.${process}`) }}
              </li>
            </ul>
          </template>
        </OaiTooltip>
      </div>
      <PencilIcon
        class="h-4 w-4 cursor-pointer inline-block ml-2"
        style="margin-top: -8px"
        @click="$emit('editClicked')"
      />
    </div>
    <div
      class="unitValueValueColumns border-b border-gray-600 bg-white"
      :style="`height: ${secondHeaderRowHeight}px`"
    >
      <div
        class="p-1 text-md text-center border-r relative"
        :title="`${$t('unit_values.duration_label')} [${$t('unit_values.duration_unit')}]`"
      >
        <div class="truncate">
          {{ $t("unit_values.duration_label") }}<br />[{{ $t("unit_values.duration_unit") }}]
        </div>
        <span
          class="absolute bg-yellow-300 text-white rounded text-xs px-1 py-0.5"
          style="top: -11px; left: 50%; transform: translateX(-50%)"
          v-if="formattedTypeAdjustments.duration !== null"
          :title="formattedTypeAdjustments.duration ?? ''"
          >{{ formattedTypeAdjustments.duration }}%</span
        >
      </div>
      <div
        class="p-1 text-md text-center border-r relative"
        :title="`${$t('unit_values.workforce_label')} [${$t('unit_values.workforce_unit')}]`"
      >
        <div class="truncate">
          {{ $t("unit_values.workforce_label") }}<br />[{{ $t("unit_values.workforce_unit") }}]
        </div>
        <span
          class="absolute bg-yellow-300 text-white rounded text-xs px-1 py-0.5"
          style="top: -11px; left: 50%; transform: translateX(-50%)"
          v-if="formattedTypeAdjustments.workforce !== null"
          :title="formattedTypeAdjustments.workforce ?? ''"
          >{{ formattedTypeAdjustments.workforce }}</span
        >
      </div>
      <div
        class="p-1 truncate text-md text-center border-r"
        :title="`${$t('unit_values.quantity_label')} [${$t(`unit_values.types.${type}.unit`)}]`"
      >
        {{ $t("unit_values.quantity_label") }}<br />[{{ $t(`unit_values.types.${type}.unit`) }}]
      </div>
      <div
        class="p-1 text-md text-center bg-gray-100 relative"
        :class="{
          'border-r border-r-gray-600': isLastType,
        }"
        :title="`${$t('unit_values.unit_value_label')} [${$t(`unit_values.types.${type}.unit`)}]`"
      >
        <div class="truncate">
          {{ $t("unit_values.unit_value_label") }}<br />[{{ $t("unit_values.unit_value_unit") }}/{{
            $t(`unit_values.types.${type}.unit`)
          }}]
        </div>
        <span
          class="absolute bg-yellow-300 text-white rounded text-xs px-1 py-0.5"
          style="top: -11px; left: 50%; transform: translateX(-50%)"
          v-if="formattedTypeAdjustments.value !== null"
          :title="formattedTypeAdjustments.value ?? ''"
          >{{ formattedTypeAdjustments.value }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PencilIcon } from "@heroicons/vue/24/solid";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "@/components/other/OaiTooltip.vue";
import { useProcessClasses } from "@/composables/process";
import { UnitValue, UnitValueType } from "@/types/UnitValue";
import { getProcesses } from "@/views/unit_values/services/processTableLinkService";
import { createFormatNumber } from "@/views/unit_values/services/unitValues";
import "../styles.css";

const formatNumber = createFormatNumber(useI18n().locale.value);

const props = defineProps({
  firstHeaderRowHeight: {
    type: Number,
    required: true,
  },
  secondHeaderRowHeight: {
    type: Number,
    required: true,
  },
  columnWidth: {
    type: Number,
    required: true,
  },
  type: {
    type: String as PropType<UnitValueType>,
    required: true,
  },
  isLastType: {
    type: Boolean,
    required: false,
  },
  unitValue: {
    type: Object as PropType<UnitValue>,
    required: true,
  },
});

defineEmits(["editClicked"]);

const formatAndAddPlus = (value: number | null) => {
  if (!value) {
    return null;
  }
  const plus = value > 0 ? "+" : "";
  return `${plus}${formatNumber(value)}`;
};

const formattedTypeAdjustments = computed(() => {
  return {
    duration: formatAndAddPlus(
      props.unitValue.type_duration_factor && props.unitValue.type_duration_factor * 100,
    ),
    workforce: formatAndAddPlus(props.unitValue.type_workforce_delta),
    value: formatAndAddPlus(props.unitValue?.type_value_delta),
  };
});

const processClasses = useProcessClasses();

const processes = computed(
  () => getProcesses(props.unitValue.type, processClasses.value).processes,
);
</script>
