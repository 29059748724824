<template>
  <div
    class="relative rounded-md bg-white py-1.5 pr-1.5 pl-3 shadow-sm ring-1 ring-inset ring-gray-300 w-full group/filter-box"
  >
    <div class="flex items-center justify-between">
      <p class="font-normal mb-1">
        {{ t("buttons.time_range") }}
      </p>
      <div
        class="cursor-pointer p-1 invisible group-hover/filter-box:visible rounded-md hover:bg-red-200 active:bg-red-300 transition-colors duration-100"
        @click="emit('change', { type: 'total', start_date: null, end_date: null })"
      >
        <XMarkIcon class="h-4 w-4 select-none" aria-hidden="true" />
      </div>
    </div>

    <div class="text-sm flex items-center">
      <span class="mr-3 text-gray-400">
        {{ t("analytics.reports.is") }}
      </span>

      <Popover class="relative flex flex-1">
        <PopoverButton class="outline-none flex flex-1">
          <button
            type="button"
            :class="[
              'flex flex-1 py-0.5 px-4 border rounded-full max-w-60 cursor-pointer',
              timeRangeLabel ? 'border-gray-300' : 'border-yellow-400',
            ]"
          >
            <span
              class="max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis mr-1 text-gray-500"
              >{{ timeRangeLabel }}</span
            >
          </button>
        </PopoverButton>

        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel class="absolute left-0 z-10 mt-7" v-slot="{ close }">
            <div
              class="rounded-lg shadow-lg min-w-[220px] ring-1 bg-white ring-gray-300 text-sm px-1 py-2"
            >
              <p
                v-for="preset in presetRanges"
                class="px-5 py-1.5 hover:bg-gray-100 rounded-md cursor-pointer"
                @click="
                  emit('change', { type: preset.value, start_date: null, end_date: null });
                  close();
                "
                :key="preset.value"
              >
                {{ preset.label }}
              </p>

              <VueDatePicker
                :modelValue="datePickerRange"
                :columns="4"
                :enable-time-picker="false"
                :placeholder="`${t('report.bulk_export.main_label')}`"
                :locale="locale"
                format="dd.MM.yyyy"
                auto-apply
                range
                @update:modelValue="
                  handleDateChange($event);
                  close();
                "
              >
                <template #trigger>
                  <p class="px-5 py-1.5 hover:bg-gray-100 rounded-md cursor-pointer">
                    {{ t("analytics.reports.absolute_date") }}
                  </p>
                </template>
              </VueDatePicker>

              <p
                class="px-5 py-1.5 hover:bg-gray-100 rounded-md cursor-pointer"
                @click="
                  emit('change', { type: 'total', start_date: null, end_date: null });
                  close();
                "
              >
                {{ t("time.all_time") }}
              </p>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import VueDatePicker from "@vuepic/vue-datepicker";
import { startOfDay } from "date-fns";
import { format } from "date-fns";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { ReportDateFilter } from "@/types/reports/filters";

const props = defineProps<{ filter: ReportDateFilter }>();
const emit = defineEmits<{ (eventName: "change", payload: ReportDateFilter): void }>();

const { t, locale } = useI18n();

const presetRanges: Ref<
  { label: string; value: Exclude<ReportDateFilter["type"], "date_range" | "total"> }[]
> = computed(() => [
  {
    label: t("time.last_day"),
    value: "last_day",
  },
  {
    label: t("time.last_week", 1),
    value: "last_week",
  },
  {
    label: t("time.last_calendar_week"),
    value: "last_calendar_week",
  },
  {
    label: t("time.last_week", 2),
    value: "last_2_weeks",
  },
  {
    label: t("time.last_month", 1),
    value: "last_month",
  },
  {
    label: t("time.last_month", 3),
    value: "last_3_months",
  },
  {
    label: t("time.last_month", 6),
    value: "last_6_months",
  },
  {
    label: t("time.last_month", 12),
    value: "last_12_months",
  },
]);

const timeRangeLabel = computed(() => {
  if (props.filter.type === "total") {
    return t("time.all_time");
  }
  if (props.filter.type === "date_range" && props.filter.start_date && props.filter.end_date) {
    return `${format(props.filter.start_date, "dd.MM.yyyy")} - ${format(
      props.filter.end_date,
      "dd.MM.yyyy",
    )}`;
  }
  return presetRanges.value.find((preset) => preset.value === props.filter.type)?.label;
});

const datePickerRange = computed(() => {
  const now = new Date();
  return [props.filter.start_date ?? now, props.filter.end_date ?? now];
});

const handleDateChange = (range: [Date, Date]) => {
  emit("change", {
    type: "date_range",
    start_date: startOfDay(range[0]),
    end_date: startOfDay(range[1]),
  });
};
</script>
