<template>
  <div :style="{ paddingLeft: '22px', paddingRight: '20px' }">
    <div class="flex items-center pt-4 pb-2 flex-row gap-6">
      <DashboardMenu
        :canEdit="canEdit"
        :currentDashboard="currentDashboard"
        :dashboards="dashboards"
        @selectDashboard="emit('selectDashboard', $event)"
        @showAddWidgetsSidebar="emit('showAddWidgetsSidebar', $event)"
        @resetCurrentDashboard="emit('resetCurrentDashboard')"
      />
      <div class="flex items-center gap-4">
        <button
          v-if="canEdit && currentDashboard && hasPermissionToEditDashboard(currentDashboard)"
          type="button"
          @click="$emit('toggleAddWidgetSidebar')"
          class="flex items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        >
          <PlusIcon class="h-5 w-5" />
          {{ $t("dashboard.add_widget") }}
        </button>
        <LiveSwitch class="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/outline";
import LiveSwitch from "@/components/live_video/LiveSwitch.vue";
import { Dashboard } from "@/types/Dashboard";
import DashboardMenu from "@/views/dashboard/componentsV2/DashboardMenu.vue";
import { useHasPermissionToEditDashboard } from "@/views/dashboard/composables";

defineProps<{ currentDashboard?: Dashboard; canEdit: boolean; dashboards: Dashboard[] }>();
const emit = defineEmits<{
  (eventName: "toggleAddWidgetSidebar"): void;
  (eventName: "selectDashboard", dashboard: Dashboard): void;
  (eventName: "showAddWidgetsSidebar", open: boolean): void;
  (eventName: "resetCurrentDashboard"): void;
}>();

const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();
</script>
