<template>
  <BaseWidget
    :isLoading="isLoading"
    @delete="emit('delete')"
    @trackInteraction="emit('trackInteraction')"
    hideDragHandle
    :showZoomIcon="true"
  >
    <div class="px-4 py-3 h-full flex flex-col">
      <div class="mb-3 mr-4 flex items-center gap-2">
        <DashboardDragHandle class="shrink-0" />
        <RouterLink
          :to="{
            name: 'PlannerV2',
            params: {
              customer_name: currentCustomerName,
              site_id: currentSiteId,
            },
          }"
          class="font-semibold text-gray-900 truncate hover:text-yellow-800 pr-4"
          :class="width > normalWidgetWidth ? 'text-lg' : 'text-base'"
          @click.prevent="emit('widgetRouteClicked')"
        >
          {{ $t("dashboard.project.planner_card.current_procedures") }}
        </RouterLink>
      </div>
      <PlannerWidgetTable :openLeafNodes="openLeafNodes" :width="width" />
    </div>

    <template #zoomedView>
      <PlannerWidgetTable :openLeafNodes="openLeafNodes" :width="width" />
    </template>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { onMounted, ref } from "vue";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";
import PlannerWidgetTable from "@/views/dashboard/componentsV2/PlannerWidgetTable.vue";
import { normalWidgetWidth } from "@/views/dashboard/composables";
import { ActiveLeafNode } from "@/views/planner/types";

defineProps<{ width: number; height: number }>();
const emit = defineEmits(["delete", "widgetRouteClicked", "trackInteraction"]);

const openLeafNodes = ref<ActiveLeafNode[]>([]);
const isLoading = ref(false);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const getOpenLeafNodesData = () => {
  isLoading.value = true;

  return PlannerRepository.loadActiveLeafNodes(currentCustomerName, currentSiteId)
    .then((data) => {
      openLeafNodes.value = data;
    })
    .catch((error) => {
      if (error?.response?.status !== 404) {
        logger.error(error);
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(() => {
  getOpenLeafNodesData();
});
</script>
