<template>
  <Sidebar :content="sidebarContent" currentSidebarTab="SettingsLanguage" :hideProjectsTab="true">
    <div class="flex flex-1 flex-col">
      <PageHeader> {{ $t("admin.settings.language.title") }}</PageHeader>

      <div class="md:px-6 px-4 py-6">
        <Listbox as="div" v-model="selected" class="w-96">
          <ListboxLabel class="block text-sm font-medium text-gray-700">
            {{ $t("admin.settings.language.language_choice") }}
          </ListboxLabel>
          <div class="relative mt-1">
            <ListboxButton
              class="focus:outline-none relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-yellow-300 focus:ring-1 focus:ring-yellow-300 sm:text-sm"
            >
              <span class="flex items-center">
                <img
                  :src="require(`@/assets/imgs/flag_icons/${selected.icon}.svg`)"
                  alt=""
                  class="h-6 w-6 flex-shrink-0"
                />
                <span class="ml-3 block truncate">{{ selected.name }}</span>
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
              >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="focus:outline-none absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-800 ring-opacity-5 sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  v-for="language in languages"
                  :key="language.id"
                  :value="language"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      active ? 'text-black' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    ]"
                  >
                    <div class="flex items-center" @click="changeLanguage(language)">
                      <img
                        :src="require(`@/assets/imgs/flag_icons/${language.icon}.svg`)"
                        alt=""
                        class="h-6 w-6 flex-shrink-0"
                      />
                      <span
                        :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']"
                      >
                        {{ language.name }}
                      </span>
                    </div>

                    <span
                      v-if="selected"
                      :class="[
                        active ? 'text-black' : 'text-yellow-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import {
  LanguageIcon,
  UserIcon,
  CheckIcon,
  HomeIcon,
  ChevronUpDownIcon,
} from "@heroicons/vue/24/solid";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useTrackEvent } from "@/composables/tracking";

const languages = [
  {
    id: 1,
    name: "Deutsch",
    identifier: "de",
    icon: "de",
  },
  {
    id: 2,
    name: "English",
    identifier: "en",
    icon: "gb",
  },
  {
    id: 3,
    name: "Français",
    identifier: "fr",
    icon: "fr",
  },
];

export default {
  name: "SettingsLanguage",

  data() {
    return {
      sidebarContent: [],
      lang: localStorage.getItem("lang") || "de",
    };
  },
  mounted() {
    this.addSidebarContent();
  },
  methods: {
    changeLanguage(event) {
      localStorage.setItem("lang", event.identifier);
      this.trackEvent("user_language-change_apply");
      window.location.reload();
    },
    getImage(path) {
      return path;
    },
    addSidebarContent() {
      let listEntry = {
        name: this.$t("partials.sidebar.projects"),
        href: "/",
        icon: HomeIcon,
        current: false,
        section: "",
      };
      this.sidebarContent.push(listEntry);

      listEntry = {
        name: this.$t("app_features.settings.user"),
        href: "/settings/user",
        icon: UserIcon,
        current: false,
        section: "",
      };
      this.sidebarContent.push(listEntry);

      listEntry = {
        name: this.$t("app_features.settings.language"),
        href: "/settings/language",
        icon: LanguageIcon,
        current: true,
        section: "",
      };
      this.sidebarContent.push(listEntry);
    },
  },
  components: {
    PageHeader,
    Sidebar,
    CheckIcon,
    ChevronUpDownIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  },

  setup() {
    const current_language = localStorage.getItem("lang") || "de";
    let selected = {};

    for (let i = 0; i < languages.length; i++) {
      if (languages[i].identifier == current_language) {
        selected = languages[i];
        break;
      }
    }

    const trackEvent = useTrackEvent();

    return {
      languages,
      selected,
      trackEvent,
    };
  },
};
</script>

<style scoped></style>
