import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useCustomToast } from "@/composables/toast";
import DashboardRepository from "@/repositories/DashboardRepository";
import logger from "@/services/logger";
import { Dashboard, DashboardToCreate, DashboardToUpdate } from "@/types/Dashboard";

export const useDashboards = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const { t } = useI18n();
  const store = useStore();

  const { data, isLoading, error } = useQuery<Dashboard[]>({
    queryKey: ["dashboards", currentCustomerName, currentSiteId],
    queryFn: async () => {
      if (store.getters.isSessionExpired()) {
        // since this runs on window focus, we make an explicit check to avoid 403 errors
        return [];
      }
      return DashboardRepository.loadDashboards(currentCustomerName, currentSiteId);
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("dashboard.loading_error"));
      return false;
    },
    // it seems like that turning on refetchOnWindowFocus will cause a refetch on unmount
    // seems like this is a bug
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    staleTime: 30000,
  });

  const dashboards = computed(() => data.value || []);

  return { dashboards, isLoading, error };
};

export const useDeleteDashboard = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteDashboard, isLoading } = useMutation<void, Error, string>({
    mutationFn: (dashboardId) =>
      DashboardRepository.deleteDashboard(currentCustomerName, currentSiteId, dashboardId),
    onSuccess: (result, dashboardId) => {
      queryClient.setQueryData(
        ["dashboards", currentCustomerName, currentSiteId],
        (dashboards: Dashboard[] | undefined) =>
          dashboards?.filter((dashboard) => dashboard._id !== dashboardId),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("dashboard.reset_error"));
      return false;
    },
  });

  return { deleteDashboard, isLoading };
};

export const useCreateOrUpdateDashboard = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: createOrUpdateDashboard, isLoading } = useMutation<
    Dashboard | null,
    Error,
    DashboardToCreate & DashboardToUpdate & Partial<Pick<Dashboard, "generated" | "_id">>
  >({
    mutationFn: async (dashboard) => {
      if (dashboard.generated || !dashboard._id) {
        return DashboardRepository.createDashboard(currentCustomerName, currentSiteId, {
          name: dashboard.name,
          type: dashboard.type,
          version: dashboard.version,
          widgets: dashboard.widgets,
          default: dashboard.default,
        });
      }
      return DashboardRepository.updateDashboard(
        currentCustomerName,
        currentSiteId,
        dashboard._id,
        {
          name: dashboard.name,
          version: dashboard.version,
          widgets: dashboard.widgets,
        },
      );
    },
    onSuccess: (newDashboard, dashboardRequest) => {
      queryClient.setQueryData(
        ["dashboards", currentCustomerName, currentSiteId],
        (dashboards: Dashboard[] | undefined) => {
          if (!dashboards) {
            return undefined;
          }
          if (newDashboard) {
            if (!dashboards.some((dashboard) => dashboard._id === newDashboard._id)) {
              return [...dashboards, newDashboard];
            }
            return dashboards.map((dashboard) =>
              dashboard._id === newDashboard._id && newDashboard.version > dashboard.version
                ? newDashboard
                : dashboard,
            );
          }
          return dashboards.filter((dashboard) => dashboard._id !== dashboardRequest._id);
        },
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("dashboard.create_or_update_error"));
      return false;
    },
  });

  return { createOrUpdateDashboard, isLoading };
};

export const useLastSelectedDashboardId = () => {
  const key = "oculai_lastSelectedDashboardId";
  const lastSelectedDashboardId = ref<string | null>(window.localStorage.getItem(key));
  const setLastSelectedDashboardId = (dashboardId: string) => {
    lastSelectedDashboardId.value = dashboardId;
    window.localStorage.setItem(key, dashboardId);
  };
  return { lastSelectedDashboardId, setLastSelectedDashboardId };
};
