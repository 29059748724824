<template>
  <ModalTW :open="true" @close="emit('close')" customCls="w-full lg:w-2/3 m-2">
    <template #content>
      <div>
        <div v-if="!isLoading" class="flex flex-col gap-2">
          <ReportFormWithPlot
            :hierarchyTags="hierarchyTags"
            :report="editedReport"
            @change="handleReportChange"
          />
          <MainButton
            :label="t('buttons.save')"
            type="button"
            color="yellow"
            :style="{ margin: 0 }"
            class="self-end"
            @click="handleUpdateClick"
            :isDisabled="isReportUpdating"
          >
            <LoadingSpinner size="w-5 h-5 mr-2" v-if="isReportUpdating" />
          </MainButton>
        </div>
        <div v-else class="flex items-center justify-center h-[400px]">
          <LoadingSpinner />
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import MainButton from "@/components/other/MainButton.vue";
import { useHierarchyTags } from "@/composables/hierarchyTags";
import { useUpdateReport } from "@/composables/report";
import { Report, ReportToCreateOrUpdate } from "@/types/Report";
import ReportFormWithPlot from "@/views/reports/components/ReportFormWithPlot.vue";

const props = defineProps<{ report: Report }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "done", report: Report): void;
}>();

const { t } = useI18n();

const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { updateReport, isLoading: isReportUpdating } = useUpdateReport();

const editedReport = ref<Report>({ ...props.report });

const isLoading = computed(() => areHierarchyTagsLoading.value);

const handleReportChange = (change: Partial<Report>) => {
  editedReport.value = { ...editedReport.value, ...change };
};

const handleUpdateClick = () => {
  if (isReportUpdating.value) {
    return;
  }
  const reportToUpdate: ReportToCreateOrUpdate = {
    _id: editedReport.value._id,
    type: editedReport.value.type,
    name: editedReport.value.name || props.report.name,
    filters: editedReport.value.filters,
    config: editedReport.value.config,
    aggregation: editedReport.value.aggregation,
  };
  updateReport(reportToUpdate)
    .then((updatedReport) => {
      emit("done", updatedReport);
    })
    .catch(() => undefined);
};

watch(
  () => props.report,
  () => {
    editedReport.value = { ...props.report };
  },
);
</script>
