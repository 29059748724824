<template>
  <div class="grid grid-cols-1 px-2 pb-10 lg:grid-cols-2">
    <div v-for="(project, index) in projects" :key="index">
      <ProjectCard
        :id="'project-link-' + index"
        v-bind:project-info="project"
        :thumbnailUrl="thumbnailUrlsByProject[project._id]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Project, ProjectThumbnailUrl } from "@/types/Project";
import ProjectCard from "@/views/dashboard/components/ProjectCard.vue";

const props = defineProps<{ projects: Project[]; thumbnailUrls: ProjectThumbnailUrl[] }>();

const thumbnailUrlsByProject = computed(() =>
  props.thumbnailUrls.reduce((acc, projectThumbnailUrl) => {
    acc[projectThumbnailUrl._id] = projectThumbnailUrl.thumbnail_url;
    return acc;
  }, {} as Record<string, string>),
);
</script>
