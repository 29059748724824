<template>
  <ModalTW :open="true" @close="closeModal()" customCls="w-3/4">
    <template #title>{{ $t("camera.gallery.download.title") }}</template>
    <template #content>
      <hr />
      <div aria-hidden="true">
        <div class="flex justify-center py-6">
          <div
            class="grid gap-10"
            :class="
              streams.length > 1
                ? streams.length > 2
                  ? 'grid-cols-3'
                  : 'grid-cols-2'
                : 'grid-cols-1'
            "
          >
            <button
              v-for="stream in streams"
              :key="stream._id"
              @click="toggleSelectedStream(stream.camera_id)"
              :disabled="isDownloading"
              :class="[
                isSelectedStream(stream.camera_id)
                  ? 'relative border-yellow-600 text-yellow-600'
                  : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                'focus:outline-non flex items-center justify-center rounded-md border sm:px-8 px-4 py-3 sm:text-sm text-xs sm:flex-1',
                {
                  'cursor-not-allowed': isDownloading,
                },
              ]"
            >
              <CheckCircleIcon
                :class="[
                  !isSelectedStream(stream.camera_id) ? 'invisible' : '',
                  'absolute left-0 top-0 sm:h-5 sm:w-5 h-4 w-4 text-yellow-600',
                ]"
                aria-hidden="true"
              />
              {{ stream.name }}
            </button>
          </div>
        </div>
        <hr />
        <div class="flex justify-center">
          <div class="w-1/3 my-6">
            <VueDatePicker
              input-class-name="dp-custom-input"
              menu-class-name="dp-custom-menu"
              v-model="dateRange"
              :enable-time-picker="false"
              :placeholder="
                t('camera.gallery.download.date_picker_placeholder', galleryDownloadItems.length)
              "
              format="dd.MM.yyyy"
              model-type="yyyy-MM-dd"
              :locale="lang"
              :disabled="isDownloading"
              auto-apply
              reverse-years
              range
            >
            </VueDatePicker>
          </div>
        </div>
        <hr />
        <div class="flex justify-center py-6">
          <div class="grid gap-10 grid-cols-3">
            <button
              v-for="(nImgs, index) in [1, 3, null]"
              :key="nImgs !== null ? nImgs : `key-${index}`"
              @click="imgsPerDay = nImgs"
              :disabled="isDownloading"
              :class="[
                nImgs === imgsPerDay
                  ? 'relative border-yellow-600 text-yellow-600'
                  : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                'focus:outline-non flex items-center justify-center rounded-md border sm:px-8 px-4 py-3 sm:text-sm text-xs sm:flex-1',
                {
                  'cursor-not-allowed': isDownloading,
                },
              ]"
            >
              {{
                nImgs
                  ? `${nImgs} ${t("camera.gallery.download.imgs_per_day", nImgs)}`
                  : `${t("camera.gallery.download.all_imgs")}`
              }}
            </button>
          </div>
        </div>
        <div class="overflow-hidden rounded-full bg-gray-200 mt-6">
          <div
            class="h-2 rounded-full bg-yellow-600"
            :style="{
              width: `${
                totalFileSizeInBytes > 0 ? (downloadedBytes / totalFileSizeInBytes) * 100 : 0
              }%`,
            }"
          />
        </div>
        <div class="mt-4 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
          <div>
            {{
              `${downloadedFiles} ${$t("camera.gallery.download.files")} / ${
                downloadedBytes > 0 ? getTotalFileSizeInGB(downloadedBytes).toFixed(2) : 0
              } GB `
            }}
          </div>
          <div class="text-center">
            {{
              downloadedBytes
                ? `${Math.round((downloadedBytes / totalFileSizeInBytes) * 100)}%`
                : ""
            }}
          </div>
          <div class="flex justify-end">
            <span v-if="galleryDownloadItems.length > 0 || !isLoadingDownloadItems">
              {{
                `${totalAmountFiles} ${$t(
                  "camera.gallery.download.files",
                )} / ${getTotalFileSizeInGB(totalFileSizeInBytes).toFixed(2)} GB`
              }}
            </span>
            <LoadingSpinner size="w-5 h-5" v-else />
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <button
          v-if="!isDownloading"
          :disabled="isLoadingDownloadItems"
          :class="[
            'bg-yellow-600 mt-4 focus:outline-none flex justify-center rounded-md border border-transparent px-3 py-2 text-sm font-medium text-white shadow-sm',
            {
              'cursor-not-allowed': isLoadingDownloadItems,
              'hover:bg-yellow-700': !isLoadingDownloadItems,
            },
          ]"
          @click="streamZipArchiveFromDownloadItems()"
        >
          <ArrowDownTrayIcon class="h-5 w-5 mr-2" aria-hidden="true" />

          {{ $t("camera.gallery.download.start_download") }}
        </button>
        <button
          v-if="isDownloading && !isStopped"
          @click="isStopped = true"
          class="mt-4 focus:outline-none flex justify-center rounded-md border border-transparent bg-red-400 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500"
        >
          <StopIcon class="h-5 w-5 mr-2" aria-hidden="true" />
          {{ $t("camera.gallery.download.stop_download") }}
        </button>
        <button
          v-if="isDownloading && isStopped"
          @click="continueDownload()"
          class="mt-4 focus:outline-none flex justify-center rounded-md border border-transparent bg-gray-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-600"
        >
          <PlayIcon class="h-5 w-5 mr-2" aria-hidden="true" />
          {{ $t("camera.gallery.download.resume_download") }}
        </button>
      </div>
    </template>
  </ModalTW>
</template>

<script setup lang="ts">
import { ArrowDownTrayIcon, StopIcon, PlayIcon, CheckCircleIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { ZipWriter } from "@zip.js/zip.js";
import axios from "axios";
import PromisePool from "es6-promise-pool";
import { computed, onMounted, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import { useGalleryDownloadItems } from "@/composables/camera";
import { useStreams } from "@/composables/stream";
import { useCustomToast } from "@/composables/toast";
import { useTrackEvent } from "@/composables/tracking";
import logger from "@/services/logger";
import textService from "@/services/textService";

const { t } = useI18n();
const route = useRoute();
const { streams } = useStreams();

const props = defineProps({
  streamsToInitialize: {
    type: Array as PropType<string[]>,
    required: false,
  },
  dateRangeToInitialize: {
    type: Array as PropType<string[]>,
    required: false,
  },
  imgsPerDayToInitialize: {
    type: Number as PropType<number | null>,
    required: false,
  },
});

const downloadedFiles = ref<number>(0);
const downloadedBytes = ref<number>(0);
const isDownloading = ref<boolean>(false);
const isStopped = ref<boolean>(false);
const forceEndDownload = ref<boolean>(false);
const storeResolve = ref<((value: unknown) => void)[]>([]);
const dateRange = ref<string[]>([]);
const selectedStreams = ref<string[]>([route.params.camera_id as string]);
const imgsPerDay = ref<number | null>(1);
const lang = ref<string>(localStorage.getItem("lang") || "de");

const emit = defineEmits(["close"]);

const startDate = computed(() =>
  dateRange.value && dateRange.value.length > 0 ? dateRange.value[0] : null,
);
const endDate = computed(() =>
  dateRange.value && dateRange.value.length > 1 ? dateRange.value[1] : null,
);

onMounted(() => {
  selectedStreams.value = props.streamsToInitialize || selectedStreams.value;
  dateRange.value = props.dateRangeToInitialize || dateRange.value;
  imgsPerDay.value = props.imgsPerDayToInitialize || imgsPerDay.value;
});

const { galleryDownloadItems, isLoading: isLoadingDownloadItems } = useGalleryDownloadItems(
  selectedStreams,
  startDate,
  endDate,
  imgsPerDay,
);

const totalAmountFiles = computed(() => {
  return galleryDownloadItems.value.length;
});

const totalFileSizeInBytes = computed(() => {
  return galleryDownloadItems.value.reduce((sum, obj) => sum + obj.size, 0);
});

const getTotalFileSizeInGB = (bytes: number) => {
  return bytes / 1024 ** 3;
};

const closeModal = () => {
  if (isDownloading.value && !isStopped.value) {
    useCustomToast().warning(t("camera.gallery.download.ongoing_download"));
    return;
  }
  if (isDownloading.value) {
    endDownload();
  }
  emit("close");
};

const isSelectedStream = (cameraId: string) => {
  return selectedStreams.value.includes(cameraId);
};

const toggleSelectedStream = (cameraId: string) => {
  if (!selectedStreams.value.includes(cameraId)) {
    selectedStreams.value.push(cameraId);
  } else {
    if (selectedStreams.value.length > 1) {
      selectedStreams.value = selectedStreams.value.filter((item) => item !== cameraId);
    }
  }
};

const trackEvent = useTrackEvent();

const streamZipArchiveFromDownloadItems = async (concurrency = 5) => {
  trackEvent("camera_gallery_bulk-download_click");

  let newHandle: FileSystemFileHandle | null = null;
  try {
    newHandle = await window.showSaveFilePicker({
      suggestedName: `oculai_${textService.toNormalizedFileName(
        t("camera.gallery.download.base_file_name"),
      )}${startDate.value && endDate.value ? `_${startDate.value}_${endDate.value}` : ""}.zip`,
      types: [
        {
          accept: {
            "text/plain": [".zip"],
          },
        },
      ],
    });
  } catch (error) {
    const typedError = error as Error;
    if (typedError.name !== "AbortError") {
      logger.error("Unable to create zip archive", typedError);
    }
    return;
  }

  let items = galleryDownloadItems.value.slice();
  downloadedBytes.value = 0;
  downloadedFiles.value = 0;
  isDownloading.value = true;
  isStopped.value = false;
  forceEndDownload.value = false;
  storeResolve.value = [];

  const writableStream = await newHandle.createWritable();
  const zipWriter = new ZipWriter(writableStream);

  const promiseProducer = () => {
    if (forceEndDownload.value) {
      items = [];
      return;
    }

    if (isStopped.value) {
      return new Promise((resolve) => {
        storeResolve.value.push(resolve);
      });
    }

    const item = items.pop();
    if (!item) {
      return;
    }
    return axios
      .get(item.url, { responseType: "blob" })
      .then((data) => {
        zipWriter.add(item.file_name, data.data.stream());
        downloadedBytes.value += item.size;
        downloadedFiles.value += 1;
      })
      .catch((error) => logger.error(error));
  };

  const pool = new PromisePool(promiseProducer, concurrency);
  await pool.start();
  await zipWriter.close();
  isDownloading.value = false;

  if (!isStopped.value) {
    useCustomToast().success(t("camera.gallery.download.success"));
    closeModal();
  } else {
    useCustomToast().warning(t("camera.gallery.download.partial_download"));
  }
  trackEvent("camera_gallery_bulk-download_success");
};

const continueDownload = () => {
  isStopped.value = false;
  storeResolve.value.forEach((fn) => fn(undefined));
};

const endDownload = () => {
  forceEndDownload.value = true;
  storeResolve.value.forEach((fn) => fn(undefined));
};
</script>
