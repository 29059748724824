<template>
  <LiveBaseImage
    :cameraId="props.cameraId"
    :noSrcMessage="noSrcMessage"
    v-if="useLiveImage"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
  />
  <LiveBaseVideo
    :cameraId="props.cameraId"
    :noSrcMessage="noSrcMessage"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
    v-else
  />
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import LiveBaseImage from "@/components/live_video/LiveBaseImage.vue";
import LiveBaseVideo from "@/components/live_video/LiveBaseVideo.vue";
import { useLiveImageSetting } from "@/composables/stream";

const { useLiveImage } = useLiveImageSetting();

const props = defineProps({
  cameraId: {
    type: String,
    required: true,
  },
  noSrcMessage: {
    type: String as PropType<string | null>,
    required: false,
  },
  addRightSpaceToControlBar: {
    type: Boolean,
    required: false,
  },
});
</script>
