import mobile from "is-mobile";
import { User } from "oai-planner";
import { createStore } from "vuex";
import { SimplifiedProject } from "@/types/Project";
import { ConfirmationModalContext } from "@/types/modals";

export default createStore({
  state: {
    user: undefined as User | undefined,
    userLoaded: false,
    projects: undefined as SimplifiedProject[] | undefined,
    projectsLoaded: false,
    useLiveImageByProjects: {} as Record<string, boolean>,
    confirmationModal: undefined as ConfirmationModalContext | undefined,
    isSessionExpired: false,
    sessionStartDate: null as Date | null,
    sessionTimer: null as NodeJS.Timer | null,
  },
  getters: {
    useLiveImage: (state) => (customerName: string, siteId: string) => {
      const key = `${customerName}_${siteId}`;
      return state.useLiveImageByProjects[key] ?? mobile();
    },
    isSessionExpired: (state) => () => {
      if (state.isSessionExpired) {
        return true;
      }
      if (!state.sessionStartDate) {
        return false;
      }
      const duration = new Date().getTime() - state.sessionStartDate.getTime();
      return duration > 24 * 60 * 60 * 1000 - 30 * 1000;
    },
  },
  mutations: {
    setUser(state, user: User | undefined) {
      state.user = user;
      state.userLoaded = true;
    },
    setProjects(state, projects: SimplifiedProject[] | undefined) {
      state.projects = projects;
      state.projectsLoaded = true;
    },
    setUseLiveImagByProjects(state, data: Record<string, boolean>) {
      state.useLiveImageByProjects = data;
    },
    setUseLiveImage(
      state,
      {
        customerName,
        siteId,
        useLiveImage,
      }: { customerName: string; siteId: string; useLiveImage: boolean },
    ) {
      const key = `${customerName}_${siteId}`;
      state.useLiveImageByProjects[key] = useLiveImage;
    },
    setConfirmationModalArgs(state, confirmationModalArgs) {
      state.confirmationModal = {
        args: confirmationModalArgs,
        confirmed: null,
      };
    },
    setConfirmationModalConfirmed(state, confirmed) {
      if (!state.confirmationModal) {
        throw new Error("Confirmation modal not initialized");
      }
      state.confirmationModal.confirmed = confirmed;
    },
    clearConfirmationModal(state) {
      state.confirmationModal = undefined;
    },
    setIsSessionExpired(state, newIsSessionExpired) {
      state.isSessionExpired = newIsSessionExpired;
    },
    setSessionStartDate(state, newSessionStartDate) {
      state.sessionStartDate = newSessionStartDate;
    },
    setSessionTimer(state, newSessionTimer: NodeJS.Timer | null) {
      state.sessionTimer = newSessionTimer;
    },
  },
});
