<template>
  <ModalTW :open="open" @close="handleCloseModal" customCls="h-fit max-w-[90vw] l:w-[120vh]">
    <template #content>
      <div
        class="w-full flex justify-center items-center h-full"
        v-if="isProjectDurationSettingsLoading || isProjectLoading"
      >
        <LoadingSpinner />
      </div>
      <div v-else-if="projectDurationSettings && project" class="flex flex-col gap-12 min-w-min">
        <NonWorkingDays
          :projectDurationSettings="projectDurationSettings"
          :project="project"
          ref="nonWorkingDaysRef"
        />
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts" setup>
import { useProjectDurationSettings } from "oai-planner";
import { ref } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import NonWorkingDays from "@/components/working_days/NonWorkingDays.vue";
import { useLoadCurrentProject } from "@/composables/project";
import { useSaveBeforeLeaveConfirmationModal } from "@/composables/toast";
import { apiClient } from "@/repositories/clients";

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close"]);
const { projectDurationSettings, isProjectDurationSettingsLoading } =
  useProjectDurationSettings(apiClient);
const { project, isProjectLoading } = useLoadCurrentProject();
const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
const nonWorkingDaysRef = ref<typeof NonWorkingDays | null>(null);
const handleCloseModal = () => {
  const hasChanges = nonWorkingDaysRef.value?.form?.meta.dirty;
  if (hasChanges) {
    showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
      if (confirmed) {
        emits("close");
      }
    });
  } else {
    emits("close");
  }
};
</script>
