import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";
import Amplify from "aws-amplify";
import Highcharts from "highcharts";
import accessibility from "highcharts/modules/accessibility";
import TimeAgo from "javascript-time-ago";
import de from "javascript-time-ago/locale/de";
import en from "javascript-time-ago/locale/en";
import fr from "javascript-time-ago/locale/fr";
import { ComponentOptions, createApp, Plugin } from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import GlobalMixins from "@/mixins/GlobalMixins";
import ToastMixin from "@/mixins/ToastMixin";
import cacheService from "@/services/cacheService";
import App from "./App.vue";
import "./assets/styles/highcharts.css";
import awsconfig from "./aws-exports";
import i18n from "./i18n";
import "./index.css";
import router from "./router";
import logger from "./services/logger";
import store from "./store";

const { t } = i18n.global;

accessibility(Highcharts);

Amplify.configure(awsconfig);

const app = createApp(App);

logger.init(app);

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(de);
TimeAgo.addLocale(fr);

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        staleTime: 15000,
        useErrorBoundary: (error) => {
          logger.error(error);
          return false;
        },
      },
      mutations: {
        onError: (error) => {
          logger.error(error);
        },
      },
    },
  },
};

Highcharts.setOptions({
  accessibility: {
    enabled: true,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  lang: {
    resetZoom: t("analytics.reports.reset_zoom"),
    months: [
      t("calendar.months.1.short"),
      t("calendar.months.2.short"),
      t("calendar.months.3.short"),
      t("calendar.months.4.short"),
      t("calendar.months.5.short"),
      t("calendar.months.6.short"),
      t("calendar.months.7.short"),
      t("calendar.months.8.short"),
      t("calendar.months.9.short"),
      t("calendar.months.10.short"),
      t("calendar.months.11.short"),
      t("calendar.months.12.short"),
    ],
  },
  xAxis: {
    labels: {
      style: {
        fontSize: "11px",
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontSize: "11px",
      },
    },
  },
});

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(cacheService.cachePlugin)
  .use(Toast as unknown as Plugin)
  .mixin(GlobalMixins as ComponentOptions)
  .mixin(ToastMixin as ComponentOptions)
  .mount("#app");
