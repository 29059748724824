<template>
  <div
    class="flex flex-col gap-8 pl-5 pr-6 py-5 bg-white border shadow sm:rounded-lg border-gray-200 lg:min-w-[410px]"
  >
    <ReportFormSection number="1" :label="t('analytics.reports.name')">
      <input
        :value="report.name"
        @input="handleReportNameChange"
        type="text"
        autocomplete="one-time-code"
        class="outline-none block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-sm sm:leading-6 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-300 pr-10,"
      />
    </ReportFormSection>
    <Component
      :is="formComponents[report.type]"
      :config="report.config"
      :filters="report.filters"
      :aggregation="report.aggregation"
      :hierarchyTags="props.hierarchyTags"
      @change="emit('change', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { Component as VueComponent } from "vue";
import { useI18n } from "vue-i18n";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report, ReportPlotType } from "@/types/Report";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import CycleTimesForm from "@/views/reports/plots/cycle_times/CycleTimesForm.vue";
import MilestoneForm from "@/views/reports/plots/milestone/MilestoneForm.vue";
import QueryValueForm from "@/views/reports/plots/query_value/QueryValueForm.vue";
import UnitValuesForm from "@/views/reports/plots/unit_values/UnitValuesForm.vue";
import WorkingHourCurveForm from "@/views/reports/plots/working_hour_curve/WorkingHourCurveForm.vue";

const { t } = useI18n();

const props = defineProps<{ report: Report; hierarchyTags: HierarchyTagStore[] }>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const formComponents: Record<ReportPlotType, VueComponent> = {
  cycle_times: CycleTimesForm,
  working_hour_curve: WorkingHourCurveForm,
  milestone: MilestoneForm,
  unit_values: UnitValuesForm,
  query_value: QueryValueForm,
};

const handleReportNameChange = (event: Event) => {
  const name = (event.target as HTMLInputElement).value;
  emit("change", { name });
};
</script>
