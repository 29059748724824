import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { CurrentWeatherResponse, WeatherForDateResponse } from "@/types/Weather";

const getCurrentWeatherForSite = (
  customerName: string,
  siteId: string,
): Promise<CurrentWeatherResponse | null> =>
  apiClient
    .get<void, AxiosResponse<CurrentWeatherResponse>>(`/weather/current/${customerName}/${siteId}`)
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const getWeatherForDate = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<WeatherForDateResponse | null> =>
  apiClient
    .get<void, AxiosResponse<WeatherForDateResponse>>(
      `/weather/date/${customerName}/${siteId}/${date}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

export default {
  getCurrentWeatherForSite,
  getWeatherForDate,
};
