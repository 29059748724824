<template>
  <div
    class="relative mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 py-5"
    :class="{ 'bg-gray-200': isDragging }"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="space-y-1 text-center">
      <img v-if="logoSrc" :src="logoSrc" alt="fileName" style="max-height: 100px" />
      <div v-else>
        <svg
          class="mx-auto mb-3 h-12 w-12 text-orange-700"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
          @click="handleClick"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="text-sm text-gray-600">
          <label
            for="file-input"
            class="relative cursor-pointer rounded-md border border-orange-700 px-3 py-1.5 text-orange-700"
          >
            <span>{{ $t("general.label_upload_image") }}</span>
            <input
              ref="imageInput"
              id="file-input"
              class="hide-file-input p-o absolute h-1 w-1 overflow-hidden whitespace-nowrap border-0"
              type="file"
              accept=".png, .jpeg, .jpg"
              @change="onFileChange"
            />
          </label>
          <p class="mt-4 text-xs text-gray-500">{{ $t("report.logo_upload_desc") }}</p>
        </div>
      </div>
      <div class="mx-auto mt-6 sm:block" v-if="logoSrc">
        <button
          type="button"
          class="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="deleteLogo"
        >
          <span class="sr-only">Close</span>
          <TrashIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TrashIcon } from "@heroicons/vue/24/solid";
import { PropType, ref, Ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCustomToast } from "@/composables/toast";
import logger from "@/services/logger";

const props = defineProps({
  savedLogo: {
    type: String as PropType<string | null>,
  },
});
const emit = defineEmits(["deleteFile", "updateFile"]);
const isDragging = ref(false) as Ref<boolean>;
const logoSrc = ref(props.savedLogo ? props.savedLogo : null) as Ref<string | null>;
const allowedExtensions = [".jpg", ".jpeg", ".png"];
const { t } = useI18n();
const toast = useCustomToast();

const deleteLogo = () => {
  logoSrc.value = "";
  emit("deleteFile");
};
const fileUrl = (file: File) => {
  const reader = new FileReader();
  if (file) {
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Set a new property on the captured `file` and set it to the converted base64 image
      logoSrc.value = reader.result as string;
      // Emit the file with the new previewBase64 property on it
      emit("updateFile", logoSrc.value);
    };
    reader.onerror = (error) => {
      logger.error(error);
      toast.error(t("err.file_type_image"));
    };
  }
};

const onFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (!target.files || !target.files[0]) {
    return;
  }
  const file = target.files[0];
  if (!checkFileExtension(file)) {
    return;
  }
  fileUrl(file);
};
const dragover = (e: DragEvent) => {
  isDragging.value = true;
  e.preventDefault();
};
const dragleave = () => {
  isDragging.value = false;
};
const drop = (e: DragEvent) => {
  e.preventDefault();
  isDragging.value = false;
  const file = e?.dataTransfer?.files[0];
  if (!file || !checkFileExtension(file)) {
    return;
  }
  fileUrl(file);
};

const imageInput = ref(null) as Ref<HTMLInputElement | null>;
const imageInputComputed = computed(() => (imageInput?.value ? imageInput.value : null));

const handleClick = () => {
  if (imageInputComputed.value) {
    imageInputComputed?.value.click();
  }
};
//function to check file extension
const checkFileExtension = (file: File) => {
  const fileExtension = file.name.substring(file.name.lastIndexOf("."));
  if (!allowedExtensions.includes(fileExtension)) {
    toast.error(t("err.file_type_image"));
    return false;
  }
  return true;
};
</script>

<style scoped>
.hide-file-input {
  clip: rect(0, 0, 0, 0);
}
</style>
