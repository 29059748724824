<template>
  <div v-if="hasPermission('live_camera_feature_user')" class="flex items-center gap-2 min-w-0">
    <div class="text-xs text-gray-600 truncate">{{ $t("video_player.low_bandwidth_mode") }}</div>
    <Switch
      :modelValue="useLiveImage"
      @update:modelValue="
        setUseLiveImage($event);
        trackEvent('dashboard_low-band-width-mode-toggle_apply');
      "
      class="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    >
      <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md" />
      <span
        aria-hidden="true"
        :class="[
          useLiveImage ? 'bg-yellow-500' : 'bg-gray-200',
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
        ]"
      />
      <span
        aria-hidden="true"
        :class="[
          useLiveImage ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
        ]"
      />
    </Switch>
  </div>
</template>

<script lang="ts" setup>
import { Switch } from "@headlessui/vue";
import { useLiveImageSetting } from "@/composables/stream";
import { useTrackEvent } from "@/composables/tracking";

const { useLiveImage, setUseLiveImage } = useLiveImageSetting();
const trackEvent = useTrackEvent();
</script>
