import { GenericObject, useForm } from "vee-validate";
import { onMounted, onUnmounted, ref, watch, computed, Ref } from "vue";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { UnitValue } from "@/types/UnitValue";
import {
  calculateRowTags,
  calculateTypes,
  getFullUnitValueId,
} from "@/views/unit_values/services/unitValues";

export const useScroll = () => {
  const scrollBarWidth = ref(0);
  const scrollBarHeight = ref(0);

  const pan = ref<number[] | null>(null);

  const mainContentRef = ref<HTMLDivElement | null>(null);
  const tagHeaderRef = ref<HTMLDivElement | null>(null);
  const valuesHeaderRef = ref<HTMLDivElement | null>(null);
  const summaryFooterRef = ref<HTMLDivElement | null>(null);
  const rightBorderRef = ref<HTMLDivElement | null>(null);

  const handleResize = () => {
    setTimeout(() => {
      calculateScrollbarSize();
    }, 100);
  };

  const handleMainScroll = (event: Event) => {
    const sourceElement = event.target as HTMLDivElement;
    if (tagHeaderRef.value) {
      tagHeaderRef.value.scrollTop = sourceElement.scrollTop;
    }
    if (valuesHeaderRef.value) {
      valuesHeaderRef.value.scrollLeft = sourceElement.scrollLeft;
    }
    if (summaryFooterRef.value) {
      summaryFooterRef.value.scrollLeft = sourceElement.scrollLeft;
    }
    if (rightBorderRef.value) {
      rightBorderRef.value.scrollTop = sourceElement.scrollTop;
    }
  };

  const handleVerticalScroll = (event: Event) => {
    const sourceElement = event.target as HTMLDivElement;
    if (mainContentRef.value) {
      mainContentRef.value.scrollTop = sourceElement.scrollTop;
    }
  };

  const handleHorizontalScroll = (event: Event) => {
    const sourceElement = event.target as HTMLDivElement;
    if (mainContentRef.value) {
      mainContentRef.value.scrollLeft = sourceElement.scrollLeft;
    }
  };

  const enablePan = (event: MouseEvent) => {
    if (mainContentRef.value) {
      pan.value = [
        event.pageX,
        event.pageY,
        mainContentRef.value.scrollLeft,
        mainContentRef.value.scrollTop,
      ];
    }
  };

  const disablePan = () => {
    pan.value = null;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (pan.value) {
      const originalPageX = pan.value[0];
      const originalPageY = pan.value[1];
      const originalScrollLeft = pan.value[2];
      const originalScrollTop = pan.value[3];
      const diffX = originalPageX - event.pageX;
      const diffY = originalPageY - event.pageY;
      if (mainContentRef.value) {
        mainContentRef.value.scrollLeft = originalScrollLeft + diffX;
        mainContentRef.value.scrollTop = originalScrollTop + diffY;
      }
    }
  };

  const calculateScrollbarSize = () => {
    if (rightBorderRef.value) {
      scrollBarWidth.value = rightBorderRef.value.offsetWidth - rightBorderRef.value.clientWidth;
    }
    if (summaryFooterRef.value) {
      scrollBarHeight.value =
        summaryFooterRef.value.offsetHeight - summaryFooterRef.value.clientHeight;
    }
  };

  watch(
    () => mainContentRef.value,
    () => {
      calculateScrollbarSize();
    },
  );

  onMounted(() => {
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      calculateScrollbarSize();
    }, 100);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return {
    mainContentRef,
    tagHeaderRef,
    valuesHeaderRef,
    summaryFooterRef,
    rightBorderRef,
    calculateScrollbarSize,
    handleMainScroll,
    handleVerticalScroll,
    handleHorizontalScroll,
    enablePan,
    disablePan,
    handleMouseMove,
    scrollBarWidth,
    scrollBarHeight,
  };
};

export const useUnitValuesForm = (
  unitValues: Ref<UnitValue[] | undefined>,
  hierarchyTags: Ref<HierarchyTagStore[]>,
) => {
  const { setValues: _useFormSetValues } = useForm();

  const schema = computed(() => {
    if (!unitValues.value) {
      return null;
    }
    return yup.object(
      unitValues.value.reduce((acc, unitValue) => {
        acc[`uw_${getFullUnitValueId(unitValue)}`] = yup.object({
          duration: yup.number().nullable(),
          workforce: yup.number().nullable(),
          oai_adjusted_workforce: yup.number().nullable(),
          quantity: yup.number().nullable(),
          value: yup.number().nullable(),
        }) as ObjectSchema<{ quantity: number | null }>;
        return acc;
      }, {} as Record<string, ObjectSchema<{ quantity: number | null }>>),
    );
  });

  const initialValues = computed(() => {
    if (!unitValues.value) {
      return undefined;
    }
    return {
      ...unitValues.value.reduce((acc, unitValue) => {
        acc[`uw_${getFullUnitValueId(unitValue)}`] = unitValue;
        return acc;
      }, {} as Record<string, UnitValue>),
      tags: calculateRowTags(unitValues.value, hierarchyTags.value),
      types: calculateTypes(unitValues.value),
    };
  });

  const getUnitValuesListFromForm = (values: GenericObject) =>
    Object.entries(values)
      .filter(([key, unitValue]) => key.startsWith("uw_") && unitValue)
      .map(([, unitValue]) => unitValue as UnitValue);

  const setUnitValuesInForm = (
    values: GenericObject,
    setValues: typeof _useFormSetValues,
    newUnitValues: UnitValue[],
  ) => {
    const newUnitValuesMap = newUnitValues.reduce((acc, unitValue) => {
      acc[`uw_${getFullUnitValueId(unitValue)}`] = unitValue;
      return acc;
    }, {} as Record<string, UnitValue>);

    setValues({
      ...newUnitValuesMap,
      ...Object.keys(values)
        .filter((key) => key.startsWith("uw_") && !(key in newUnitValuesMap))
        .reduce((acc, key) => {
          acc[key] = undefined;
          return acc;
        }, {} as Record<string, undefined>),
      tags: calculateRowTags(newUnitValues, hierarchyTags.value),
      types: calculateTypes(newUnitValues),
    });
  };

  return {
    schema,
    initialValues,
    getUnitValuesListFromForm,
    setUnitValuesInForm,
  };
};
