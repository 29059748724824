<template>
  <ReportFormSection number="2" :label="t('analytics.reports.filters')">
    <div class="flex gap-2 flex-col">
      <ReportLocationFilterField
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
      <ReportProcessFilterField
        :filter="filters.processes"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, processes: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection number="3" :label="t('analytics.reports.aggregation')" :defaultOpen="false">
    <SelectList
      :options="aggregationOptions"
      :defaultSelected="aggregation"
      :onUpdate:selected="handleAggregationChange"
    />
  </ReportFormSection>
  <ReportFormSection number="4" :label="t('analytics.reports.plot_config')" :defaultOpen="false">
    <CycleTimesConfigFields
      :config="config"
      :filters="filters"
      :aggregation="aggregation"
      :hierarchyTags="hierarchyTags"
      @configChange="emit('change', { config: $event })"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import SelectList from "@/components/forms/SelectList.vue";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report } from "@/types/Report";
import {
  CycleTimesAggregation,
  CycleTimesReportConfig,
  CycleTimesReportFilters,
} from "@/types/reports/PlotCycleTimes";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import ReportProcessFilterField from "@/views/reports/filters/ReportProcessFilterField.vue";
import CycleTimesConfigFields from "@/views/reports/plots/cycle_times/CycleTimesConfigFields.vue";

const props = defineProps<{
  filters: CycleTimesReportFilters;
  config: CycleTimesReportConfig;
  aggregation: CycleTimesAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();

const aggregationOptions = computed(() => {
  const availableHierarchyTags = Array.from(
    new Set(props.hierarchyTags.map((tag) => tag.type).filter((tag) => tag !== "component")),
  ).sort((a, b) =>
    a === "building" ? -1 : b === "building" ? 1 : a === "level" ? -1 : b === "level" ? 1 : 0,
  );
  return availableHierarchyTags.map((tag) => ({
    name: t(`analytics.reports.by_${tag}`),
    value: tag,
  }));
});

const handleAggregationChange = (aggregation: CycleTimesAggregation) => {
  emit("change", { aggregation });
};
</script>
