import i18n from "@/i18n";

const locale = (i18n.global.locale as unknown as { value: string }).value;

const fixToPrecision = (value: number, precision: number) => parseFloat(value.toFixed(precision));

const parseText = (value: string, precision: number) => {
  const parsedValue = parseFloat(value.replaceAll(",", "."));
  if (Number.isNaN(parsedValue)) {
    return parsedValue;
  }
  return fixToPrecision(parsedValue, precision);
};

const getDecimalSeparator = () => {
  const n = 1.1;
  return n.toLocaleString(locale).substring(1, 2);
};

export default {
  fixToPrecision,
  parseText,
  getDecimalSeparator,
};
