<template>
  <div class="space-y-1 px-2">
    <div v-for="(item, index) in content" :key="item.name">
      <div v-if="item.name === this.projectOverviewString && this.currentProjectV2">
        <router-link
          :to="item.href"
          :class="[
            item.current
              ? 'bg-oaiGray-400 text-yellow-400'
              : 'text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500',
            'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              item.current ? 'text-yellow-400' : 'text-white-900 group-hover:text-yellow-400',
              'mr-3 h-8 w-6 shrink-0',
            ]"
            aria-hidden="true"
          />
          {{ this.currentProjectV2.name }}
        </router-link>
      </div>
      <div v-else>
        <div v-if="item.name !== 'Home'">
          <div v-if="index >= 1">
            <div v-if="content[index - 1].section !== content[index].section">
              <div class="py-2">
                <hr class="" style="border: 0; border-top: 1px solid #393939" />
              </div>
            </div>
            <Menu as="div" class="relative" v-if="item.options?.length">
              <MenuButton
                :class="[
                  item.current
                    ? 'bg-oaiGray-400 text-yellow-400'
                    : 'text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500',
                  'group flex items-center justify-between rounded-md px-2 py-2 text-sm font-medium w-full',
                ]"
              >
                <div class="flex items-center justify-between w-full">
                  <div class="flex items-center">
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                          ? 'text-yellow-400'
                          : 'text-white-900 group-hover:text-yellow-400',
                        'mr-3 h-8 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    <span> {{ item.name }} </span>
                  </div>
                  <NewFeatureBadge v-if="item.options.some((option) => option.new)" />
                </div>
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute left-0 z-20 mt-1 w-60 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
                >
                  <div class="divide-y">
                    <router-link
                      v-for="(option, index) in item.options"
                      :key="index"
                      :to="option.href"
                    >
                      <MenuItem
                        as="div"
                        :class="[
                          option.current
                            ? 'bg-oaiGray-400 text-yellow-400'
                            : 'hover:bg-yellow-100 text-oaiGray-500',
                          'group flex items-center justify-between rounded-md p-4 text-sm font-medium cursor-pointer',
                        ]"
                      >
                        <span>
                          {{ option.name }}
                        </span>
                        <NewFeatureBadge v-if="option.new" />
                      </MenuItem>
                    </router-link>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <div v-else>
              <router-link
                :to="item.href"
                :class="[
                  item.current
                    ? 'bg-oaiGray-400 text-yellow-400'
                    : 'text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500',
                  'group flex items-center justify-between rounded-md px-2 py-2 text-sm font-medium',
                ]"
              >
                <div class="flex items-center">
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-yellow-400'
                        : 'text-white-900 group-hover:text-yellow-400',
                      'mr-3 h-8 w-6 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </div>
                <NewFeatureBadge v-if="item.new" />
              </router-link>
            </div>
          </div>
          <div v-else>
            <router-link
              :to="item.href"
              :class="[
                item.current
                  ? 'bg-oaiGray-400 text-yellow-400'
                  : 'text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500',
                'group flex items-center justify-between rounded-md px-2 py-2 text-sm font-medium',
              ]"
            >
              <div class="flex items-center">
                <component
                  :is="item.icon"
                  :class="[
                    item.current ? 'text-yellow-400' : 'text-white-900 group-hover:text-yellow-400',
                    'mr-3 h-8 w-6 shrink-0',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </div>
              <NewFeatureBadge v-if="item.new" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, MenuItem, MenuItems, MenuButton } from "@headlessui/vue";
import NewFeatureBadge from "@/components/other/NewFeatureBadge.vue";

export default {
  name: "SidebarContent",
  props: ["content"],
  data() {
    return {
      projectOverviewString: this.$t("app_features.project_overview"),
    };
  },
  components: {
    NewFeatureBadge,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
  },
};
</script>
