<template>
  <Disclosure v-slot="{ open }" :defaultOpen="true">
    <DisclosureButton class="flex gap-2 w-full items-center hover:bg-gray-100 px-2">
      <ChevronRightIcon
        :class="{ 'rotate-90': open }"
        class="w-5 h-5 shrink-0"
        v-if="plannerItem.children && plannerItem.children.length > 0"
      />
      <div v-else class="w-5 h-5 shrink-0" />
      <div class="flex gap-2 text-xs flex-1 min-w-0 my-1">
        <div
          class="flex-1 truncate flex gap-1 whitespace-nowrap"
          :class="{ 'font-semibold': plannerItem.children && plannerItem.children.length > 0 }"
        >
          <div
            :key="tag._id"
            v-for="tag in tags"
            :class="[
              'px-2 py-0.5 rounded-md truncate',
              HIERARCHY_TAGS_META[tag.type].bgLight,
              HIERARCHY_TAGS_META[tag.type].text,
            ]"
            :title="tag.name"
          >
            {{ tag.name }}
          </div>
          <div v-if="tags.length === 0" class="truncate">
            {{ plannerItem.name }}
          </div>
        </div>
        <OaiTooltip cls="shrink-0 w-[80px] flex items-center cursor-pointer">
          <div
            class="w-full h-full flex items-center justify-center rounded text-white"
            :class="{
              'bg-green-200': percentage !== null && percentage === 1,
              'bg-gray-300': percentage !== null && percentage === 0,
            }"
            :style="{
              background:
                percentage !== null && percentage > 0 && percentage < 1
                  ? `linear-gradient(90deg, #e7bc66 ${percentage * 100}%, #d1d5db ${
                      percentage * 100
                    }%)`
                  : undefined,
            }"
          >
            {{ formatPercentage(percentage) }}
          </div>
          <template #tooltip>
            <div class="text-xs" v-if="plannerItemProgress">
              {{ formatNumber(plannerItemProgress.finished_working_days) }}
              /
              {{ formatNumber(plannerItemProgress.total_working_days) }}
              {{ t("analytics.planner.working_days_label") }}
            </div>
          </template>
        </OaiTooltip>
        <div class="flex items-center w-[80px] justify-end shrink-0">
          {{ formatPercentage(plannerItemProgress?.weight ?? null) }}
        </div>
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <div class="ml-4">
        <ProjectProgressItem
          :key="child._id"
          v-for="child in plannerItem.children"
          :plannerItem="child"
          :plannerItemProgressById="plannerItemProgressById"
          :hierarchyTags="hierarchyTags"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PlannerItemWithChildren } from "oai-planner";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "@/components/other/OaiTooltip.vue";
import { HIERARCHY_TAGS_META } from "@/constants/hierarchyTags";
import projectProgressService from "@/services/projectProgressService";
import { HierarchyTagStore, HierarchyType } from "@/types/HierarchyTag";
import { PlannerItemProgress } from "@/types/Plan";

const props = defineProps<{
  plannerItem: PlannerItemWithChildren;
  plannerItemProgressById: Record<string, PlannerItemProgress>;
  hierarchyTags: HierarchyTagStore[];
}>();

const { t, locale } = useI18n();

const precision = 1;

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const plannerItemProgress = computed(() => props.plannerItemProgressById[props.plannerItem._id]);

const percentage = computed(() => {
  if (!plannerItemProgress.value) {
    return null;
  }
  return projectProgressService.calculatePercentage(
    plannerItemProgress.value.finished_working_days,
    plannerItemProgress.value.total_working_days,
  );
});

const tags = computed(() => {
  const hierarchyTagsBySourceId = props.hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      const key = `${tag.type}_${sourceId}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(tag);
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);

  const types: HierarchyType[] = ["building", "level", "section", "component"];
  return types
    .map((type) => hierarchyTagsBySourceId[`${type}_${props.plannerItem.source_id}`]?.at(0))
    .filter((tag) => tag) as HierarchyTagStore[];
});
</script>
