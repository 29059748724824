<template>
  <Menu as="div" class="relative space-y-1 px-2 py-1">
    <div>
      <MenuButton id="settings-button" class="w-full">
        <div
          id="settings-button-div"
          class="group flex items-center rounded-md px-2 py-1 text-sm font-medium text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500"
        >
          <UserCircleIcon
            id="project-button-icon-main"
            class="mr-3 h-8 w-6 text-white-900 group-hover:text-yellow-400 shrink-0"
          ></UserCircleIcon>
          <div class="pr-2 flex-1 text-left">
            {{ $store.state.user?.name }}
          </div>
          <div class="icon-box">
            <ChevronUpIcon
              class="mr-3 h-8 w-6 text-white-900 group-hover:text-yellow-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="ring-black focus:outline-none absolute bottom-14 left-0 right-0 z-10 mx-2 mt-1 divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
      >
        <!-- Display to everyone-->
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-3 py-2 text-sm',
              ]"
            >
              <router-link to="/settings/user">
                <div class="flex items-center">
                  <UserIcon class="h-5 w-5 text-white-900 group-hover:text-yellow-400" />
                  <div class="ml-2">
                    {{ $t("app_features.settings.user") }}
                  </div>
                </div>
              </router-link>
            </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-3 py-2 text-sm',
              ]"
            >
              <router-link to="/settings/language">
                <div class="flex items-center">
                  <LanguageIcon class="h-5 w-5 text-white-900 group-hover:text-yellow-400" />
                  <div class="ml-2">
                    {{ $t("app_features.settings.language") }}
                  </div>
                </div>
              </router-link>
            </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div
              @click="logout()"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-3 py-2 text-sm cursor-pointer',
              ]"
            >
              <div class="flex items-center">
                <ArrowRightOnRectangleIcon
                  class="h-5 w-5 text-white-900 group-hover:text-yellow-400"
                />
                <div class="ml-2">
                  {{ $t("partials.sidebar.log_out") }}
                </div>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ChevronUpIcon,
  ArrowRightOnRectangleIcon,
  LanguageIcon,
  UserIcon,
  UserCircleIcon,
} from "@heroicons/vue/24/solid";
import { useQueryClient } from "@tanstack/vue-query";
import { useRouter } from "vue-router";
import { useSignOut } from "@/composables/auth";
import logger from "@/services/logger";

const router = useRouter();
const queryClient = useQueryClient();
const signOut = useSignOut();

const logout = async () => {
  try {
    router.replace("/log-in");
    await signOut(queryClient);
  } catch (error) {
    logger.error("Unable to sign out", error);
  }
};
</script>
