export const debounce = (func: CallableFunction, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: unknown[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line
      //  @ts-ignore
      func.apply(this, args);
    }, delay);
  };
};
