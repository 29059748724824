<template>
  <div class="flex content-start flex-1 flex-wrap flex-col sm:flex-row gap-4">
    <ReportForm
      :report="clonedReport"
      :hierarchyTags="hierarchyTags"
      class="h-min flex-1"
      @change="emit('change', $event)"
    />
    <div class="flex gap-4 flex-col flex-[3] sm:min-w-[550px] w-full">
      <div
        class="bg-white border border-gray-200 shadow rounded-lg relative"
        :class="{ 'overflow-hidden': report.type === 'query_value' }"
      >
        <ReportPlot
          :report="report"
          :data="finalReportSummary as ReportSummary"
          :key="reportPlotKey"
          :spacingX="8"
          :spacingY="12"
        />
        <div
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          v-if="!finalReportSummary"
        >
          <LoadingSpinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { useReportSummary } from "@/composables/report";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report, ReportSummary } from "@/types/Report";
import ReportForm from "@/views/reports/components/ReportForm.vue";
import ReportPlot from "@/views/reports/components/ReportPlot.vue";

const props = defineProps<{
  report: Report;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const reportPlotKey = ref(0);

const { reportSummary, isFetching } = useReportSummary(computed(() => props.report));

watch(
  () => reportSummary.value,
  () => {
    reportPlotKey.value += 1;
  },
);

// the form might do some unexpected mutations, would be nice to refactor it, so it doesn't mutate at all
const clonedReport = computed(() => structuredClone(props.report));

const finalReportSummary = computed(() => (isFetching.value ? null : reportSummary.value));
</script>
