<template>
  <div
    class="relative rounded-md bg-white py-1.5 pl-3 pr-1.5 shadow-sm ring-1 ring-inset ring-gray-300 w-full group/filter-box"
  >
    <div class="flex items-center justify-between">
      <div class="font-normal mb-1">
        {{ t("analytics.reports.location") }}
      </div>
      <div
        class="cursor-pointer p-1 invisible group-hover/filter-box:visible rounded-md hover:bg-red-200 active:bg-red-300 transition-colors duration-100"
        @click="handleLocationFilterRemoveFilter()"
      >
        <XMarkIcon class="h-4 w-4 select-none" aria-hidden="true" />
      </div>
    </div>
    <div class="text-sm flex items-center">
      <span class="mr-3 text-gray-400">
        {{ t("analytics.reports.is") }}
      </span>
      <LocationFilter
        class="lg:w-72 mt-2 lg:mt-0 flex-1"
        @update:selected="emit('change', $event)"
        :selected="filter"
        :groups="tagGroups"
        changeOnSubmit
        popoverClass="translate-x-0 lg:translate-x-0 xl:-translate-x-8 md:right-auto mt-3"
        :gridBoxClass="`${tagGroups.length >= 2 && 'xl:!grid-cols-2'} ${
          tagGroups.length >= 3 && '2xl:!grid-cols-3'
        }`"
      >
        <div
          class="flex py-0.5 px-4 border rounded-full max-w-60 cursor-pointer truncate border-gray-300"
        >
          <div
            v-if="Object.values(filter).some((mask) => mask.length)"
            class="flex justify-between"
          >
            <p
              class="text-gray-500 max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis mr-1"
            >
              {{
                Object.values(filter)
                  .flatMap((masks) => masks.map((item) => populateSectionMask(item)?.name))
                  .join(", ")
              }}
            </p>
            <span class="text-gray-500">
              ({{ Object.values(filter).reduce((acc, mask) => acc + mask.length, 0) }})
            </span>
          </div>
          <p v-else>...{{ t("analytics.reports.select_values") }}</p>
        </div>
      </LocationFilter>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import { HIERARCHY_TAGS_META } from "@/constants/hierarchyTags";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { ResourceGroup } from "@/types/Process";
import { ReportLocationFilter } from "@/types/reports/filters";

const props = defineProps<{
  filter: ReportLocationFilter;
  hierarchyTags: HierarchyTagStore[];
  ignoreLevel?: boolean;
}>();
const emit = defineEmits<{ (eventName: "change", report: ReportLocationFilter): void }>();

const { t } = useI18n();

const populateSectionMask = (id: string) => props.hierarchyTags.find((tag) => tag._id === id);

const handleLocationFilterRemoveFilter = () => {
  emit("change", {
    building: [],
    level: [],
    section: [],
  });
};

const tagGroups = computed(() => {
  const sortedTagGroups = props.hierarchyTags.reduce((acc, tag) => {
    if (tag.type === "component" || (props.ignoreLevel && tag.type === "level")) {
      return acc;
    }

    const group = acc.find((item) => item.field === tag.type);

    if (group) {
      group.options.push({ name: tag.name, value: tag._id });
    } else {
      acc.push({
        name: t(`analytics.reports.${tag.type}`),
        field: tag.type,
        options: [{ name: tag.name, value: tag._id }],
      });
    }

    return acc;
  }, [] as ResourceGroup[]);

  sortedTagGroups.sort(
    (a, b) => HIERARCHY_TAGS_META[a.field].order - HIERARCHY_TAGS_META[b.field].order,
  );

  return sortedTagGroups;
});
</script>
