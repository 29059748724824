<template>
  <div>
    <div>
      <label v-if="label || $slots.label" class="block text-sm font-medium leading-6 text-gray-900"
        >{{ label }}
        <slot name="label" />
      </label>
      <div :class="['relative mt-2 sm:mt-0 rounded-md shadow-sm']">
        <input
          type="text"
          :name="name"
          class="outline-none block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-sm sm:leading-6"
          :class="
            errorMessage
              ? ['text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 pr-3']
              : ['text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-orange-300 pr-3']
          "
          @beforeinput="handleBeforeInput"
          @input="handleInput"
          :value="valueText"
          autocomplete="one-time-code"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { useField } from "vee-validate";
import { onMounted, PropType, Ref, ref } from "vue";
import i18n from "@/i18n";
import numberService from "@/services/numberService";

const locale = (i18n.global.locale as unknown as Ref<string>).value;

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: false,
  },
  precision: {
    type: Number as PropType<number>,
    required: false,
    default: 2,
  },
});

const valueText = ref<string>("");

const { value, setValue, errorMessage } = useField<number | null>(props.name);

const formatNumber = (value: number | null) => {
  if (value === null || !Number.isFinite(value)) {
    return "";
  }
  return value.toLocaleString(locale, {
    minimumFractionDigits: props.precision,
    maximumFractionDigits: props.precision,
    useGrouping: false,
  });
};

const handleBeforeInput = (event: Event) => {
  const inputEvent = event as InputEvent;
  if (inputEvent.data && !inputEvent.data.match(/\d|-|\.|,/)) {
    event.preventDefault();
  }
};

const handleInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  valueText.value = value;
  setValue(value ? numberService.parseText(value, props.precision) : null);
};

onMounted(() => {
  valueText.value = formatNumber(value.value);
});
</script>
