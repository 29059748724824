<template>
  <div class="bg-gray-50 min-h-full">
    <Sidebar :content="sidebarContent">
      <div v-if="activeTag && cameraNavbarTabs.includes(activeTag)">
        <!-- Don't show camera navbar for analytics features -->
        <PageHeader v-if="currentStream">{{ currentStream.name }}</PageHeader>
        <div class="md:px-6 px-4">
          <CameraNavbar
            :initial-active-tag="activeTag"
            :stream="currentStream"
            v-if="currentStream"
          />
          <slot></slot>
        </div>
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  HomeIcon,
  CameraIcon,
  Bars3CenterLeftIcon,
  UserGroupIcon,
  DocumentChartBarIcon,
  Cog8ToothIcon,
  ChartBarIcon,
} from "@heroicons/vue/24/solid";
import { useHasPermission } from "oai-planner";
import { computed, Component as VueComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useCurrentProject, useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import CameraNavbar from "@/views/camera/components/CameraNavbar.vue";

type EntryOptions = {
  name: string;
  href: string;
  current: boolean;
};

type Entry = {
  name: string;
  href: string;
  icon: VueComponent;
  current: boolean;
  section: string;
  options?: EntryOptions[];
};

const cameraOptions = [
  "Live",
  "Period",
  "Compare",
  "Gallery",
  "DailyTimelapse",
  "ProjectTimelapse",
  "CustomizableTimelapse",
];

const props = defineProps<{ activeTag?: string }>();
const { t } = useI18n();
const { streams } = useStreams();
const hasWorkingHoursFeature = useHasProjectFeature("working_hours");
const currentProject = useCurrentProject();
const route = useRoute();

const isDailyReportUser = useHasPermission("daily_report_user");
const isPlannerUser = useHasPermission("planner_user");
const isProcessesUser = useHasPermission("processes_user");
const isSiteActivityUser = useHasPermission("site_activity_user");
const isProjectAdmin = useHasPermission("project_admin");
const isLiveCameraFeatureUser = useHasPermission("live_camera_feature_user");

const cameraNavbarTabs = computed(() => [
  t("app_features.live"),
  t("app_features.period"),
  t("app_features.gallery"),
  t("app_features.project_timelapse"),
  t("app_features.daily_timelapse"),
  t("app_features.customizable_timelapse"),
  t("app_features.compare"),
]);

const currentStream = computed(() =>
  streams.value.find(
    (stream) =>
      stream.camera_id === route.params.camera_id &&
      props.activeTag !== t("app_features.site_activity"),
  ),
);

const sidebarContent = computed(() => {
  const analyticsTabs: Entry[] = [];
  const settingsTabs: Entry[] = [];
  const sidebarContent: Entry[] = [];

  if (isDailyReportUser) {
    analyticsTabs.push({
      name: t("app_features.daily_report"),
      href: "/daily_report/overview",
      icon: DocumentChartBarIcon,
      current: false,
      section: "analytics",
    });
  }
  if (isPlannerUser) {
    analyticsTabs.push({
      name: t("app_features.planner"),
      href: `/planner`,
      icon: CalendarDaysIcon,
      current: false,
      section: "analytics",
    });
  }
  if (isProcessesUser) {
    analyticsTabs.push({
      name: t("app_features.processes"),
      icon: Bars3CenterLeftIcon,
      current: false,
      section: "analytics",
      options: [
        {
          name: t("analytics.processes.process_data_gantt"),
          href: "/processes",
          current: false,
        },
        {
          name: t("analytics.processes.process_data_table"),
          href: "/processes/table",
          current: false,
        },
        hasWorkingHoursFeature &&
          currentProject.process_groups.includes("default") && {
            name: t("analytics.processes.unit_values"),
            href: "/unit_values_editor",
            current: false,
          },
      ].filter((item) => item),
    } as Entry);
  }
  if (isProcessesUser) {
    analyticsTabs.push({
      name: t("analytics.reports.reports"),
      href: "/reports",
      current: false,
      section: "analytics",
      icon: ChartBarIcon,
    });
  }
  if (isSiteActivityUser) {
    analyticsTabs.push({
      name: t("app_features.site_activity"),
      href: "/labour/site_activity",
      icon: UserGroupIcon,
      current: false,
      section: "analytics",
    });
  }
  if (isProjectAdmin) {
    settingsTabs.push({
      name: t("app_features.project_settings"),
      href: "/settings/general",
      icon: Cog8ToothIcon,
      current: false,
      section: "project_settings",
    });
  }

  if (!currentProject) {
    return sidebarContent;
  }
  const customerName = currentProject.customer_name;
  const siteId = currentProject.site_id;

  let listEntry = {
    name: "Home",
    href: "/",
    icon: BuildingOfficeIcon,
    current: false,
    section: "overview",
  };
  sidebarContent.push(listEntry);

  listEntry = {
    name: t("app_features.project_overview"),
    href: `/${customerName}/${siteId}/dashboard`,
    icon: HomeIcon,
    current: props.activeTag === t("app_features.project_overview"),
    section: "overview",
  };

  sidebarContent.push(listEntry);

  if (isLiveCameraFeatureUser) {
    const sortedStreams = [...streams.value];
    sortedStreams.sort((a, b) => a.name.localeCompare(b.name));
    for (const stream of sortedStreams) {
      let cameraRoute = "";
      if (typeof route.name === "string" && cameraOptions.includes(route.name)) {
        if (!stream.aws_stream_id && ["Live", "Period"].includes(route.name)) {
          cameraRoute = "/project_timelapse";
        } else {
          const lastSegment = route.fullPath.split("/").pop();
          cameraRoute = `/${lastSegment}`;
        }
      } else {
        cameraRoute = !stream.aws_stream_id ? "/project_timelapse" : "/live";
      }
      const listEntry = {
        name: stream.name,
        href: `/${customerName}/${siteId}/${stream.camera_id}${cameraRoute}`,
        icon: CameraIcon,
        current: stream._id === currentStream.value?._id,
        section: "camera",
      };
      sidebarContent.push(listEntry);
    }
  }
  // Add analytics content
  for (const listEntry of [...analyticsTabs, ...settingsTabs]) {
    if (listEntry.options?.length) {
      listEntry.options.forEach((option) => {
        option.href = `/${customerName}/${siteId}${option.href}`;
      });
    } else {
      listEntry.href = `/${customerName}/${siteId}${listEntry.href}`;
    }

    // Check if item is active Tag
    if (listEntry.name === props.activeTag) {
      listEntry.current = true;
    }
    sidebarContent.push(listEntry);
  }

  return sidebarContent;
});
</script>
