<template>
  <div :style="fullWindow4to3RatioDimensionStyle">
    <OculaiVideoPlayer
      :loading="loading"
      :src="videoUrl"
      :noSrcMessage="$t('err.no_live_found')"
      :hls="true"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentCustomerName, useCurrentSiteId } from "oai-planner";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import { useFullWindow4to3RatioDimensionStyle } from "@/composables/screen";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";

const route = useRoute();
const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const videoUrl = ref<string | null>(null);
const loading = ref(false);

const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();

onMounted(async () => {
  loading.value = true;
  try {
    const cameraId = route.params.camera_id as string;
    const token = route.params.token as string;
    videoUrl.value = await StreamRepository.loadPublicLiveUrl(
      currentCustomerName,
      currentSiteId,
      cameraId,
      token,
    );
  } catch (error) {
    logger.error(error);
    videoUrl.value = null;
  } finally {
    loading.value = false;
  }
});
</script>
