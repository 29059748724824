import { useHasPermission } from "oai-planner";
import { computed, ref, Ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useDashboards } from "@/composables/dashboard";
import { useCurrentProject, useLogoUrl } from "@/composables/project";
import { useReports } from "@/composables/report";
import { calculate4to3Dimensions, useResizeObserver } from "@/composables/screen";
import { useStreams } from "@/composables/stream";
import {
  Dashboard,
  DashboardLayoutContext,
  OculaiLayout,
  OculaiLayoutItem,
} from "@/types/Dashboard";
import { createDefaultDashboard } from "@/views/dashboard/services/projectOverviewV2Layout";

export const normalWidgetWidth = 300;

const calculateLeftOverVerticalSpace = (elementRef: Ref<HTMLDivElement | null>) => {
  if (!elementRef.value) {
    return { width: 0, height: 0 };
  }

  const elementStyle = getComputedStyle(elementRef.value);
  const xPadding = parseFloat(elementStyle.paddingLeft) + parseFloat(elementStyle.paddingRight);
  const yPadding = parseFloat(elementStyle.paddingTop) + parseFloat(elementStyle.paddingBottom);

  const elements = [...elementRef.value.children].filter(
    (child) => child.getAttribute("data-oai-left-over-space-calculator") !== "ignore",
  );
  const elementsTotalHeight = elements.reduce((acc, child) => {
    const childStyle = getComputedStyle(child);
    return (
      acc +
      (child as HTMLElement).offsetHeight +
      parseFloat(childStyle.marginTop) +
      parseFloat(childStyle.marginBottom)
    );
  }, 0);

  const width = elementRef.value.offsetWidth - xPadding;
  const height = elementRef.value.offsetHeight - yPadding - elementsTotalHeight;

  return {
    width,
    height,
  };
};

export const useLeftOverVerticalSpaceCalculator = (elementRef: Ref<HTMLDivElement | null>) => {
  const dimensions = ref(calculateLeftOverVerticalSpace(elementRef));

  const calculate = () => {
    dimensions.value = calculateLeftOverVerticalSpace(elementRef);
  };

  useResizeObserver(elementRef, calculate);

  return dimensions;
};

export const useVideoPlayerDimensions = (elementRef: Ref<HTMLDivElement | null>) => {
  const leftOverSpaceDimensions = useLeftOverVerticalSpaceCalculator(elementRef);
  return computed(() =>
    calculate4to3Dimensions(
      Math.max(leftOverSpaceDimensions.value.width, 0),
      Math.max(leftOverSpaceDimensions.value.height, 0),
    ),
  );
};

export const useDashboardsWithDefault = (layoutContext: Ref<DashboardLayoutContext | null>) => {
  const { dashboards, isLoading, error } = useDashboards();
  const { t } = useI18n();

  const dashboardsWithDefault = computed(() => {
    if (
      !error.value &&
      layoutContext.value &&
      (dashboards.value.length === 0 || dashboards.value.every((dashboard) => !dashboard.default))
    ) {
      const defaultDashboard = createDefaultDashboard(
        t("dashboard.default_dashboard"),
        layoutContext.value,
      );
      return [...dashboards.value, defaultDashboard];
    }
    return dashboards.value;
  });

  return { dashboardsWithDefault, isLoading };
};

export const useLayoutContext = () => {
  const currentProjectV2 = useCurrentProject();
  const { streams, isLoading: areStreamsLoading, error: streamError } = useStreams();
  const { reports, isLoading: areReportsLoading, error: reportError } = useReports();
  const { logoUrl, isLogoUrlLoading } = useLogoUrl();
  const hasPlannerPermission = useHasPermission("planner_user");
  const hasProcessPermission = useHasPermission("processes_user");

  const layoutContext: Ref<DashboardLayoutContext | null> = computed(() => {
    if (streamError.value || reportError.value) {
      return null;
    }
    return {
      project: currentProjectV2,
      streams: streams.value,
      reports: reports.value,
      logoUrl: logoUrl.value,
      permissions: {
        hasProcessPermission,
        hasPlannerPermission,
      },
    };
  });

  const isLoading = computed(
    () => areStreamsLoading.value || areReportsLoading.value || isLogoUrlLoading.value,
  );

  return { layoutContext, isLoading };
};

export const useHasPermissionToEditDashboard = () => {
  const hasProjectAdminPermission = useHasPermission("project_admin");
  return (dashboard: Pick<Dashboard, "type">) =>
    hasProjectAdminPermission || dashboard.type === "user";
};

export const useEditableDashboards = () => {
  const { layoutContext, isLoading: isLayoutContextLoading } = useLayoutContext();
  const { dashboardsWithDefault, isLoading: areDashboardsLoading } =
    useDashboardsWithDefault(layoutContext);
  const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();
  const editableDashboards = computed(() =>
    dashboardsWithDefault.value.filter((dashboard) => hasPermissionToEditDashboard(dashboard)),
  );
  const isLoading = computed(() => isLayoutContextLoading.value || areDashboardsLoading.value);
  return { editableDashboards, isLoading };
};

export const useAspectRatioBugFix = (
  gridLayout: Ref<OculaiLayout>,
  setLayout: (layout: OculaiLayout) => void,
) => {
  const resizingWidget = ref<OculaiLayoutItem | null>(null);

  const handleResize = (widgetId: string) => {
    if (!resizingWidget.value) {
      const widget = gridLayout.value.find((widget) => widget.i === widgetId);
      resizingWidget.value = widget ? { ...widget } : null;
    }
  };

  const handleResized = (widgetId: string) => {
    const resizedWidget = gridLayout.value.find((widget) => widget.i === widgetId);
    if (resizingWidget.value && resizedWidget && resizingWidget.value.preserveAspectRatio) {
      const oldAspectRatio = resizingWidget.value.w / resizingWidget.value.h;
      const newAspectRatio = resizedWidget.w / resizedWidget.h;
      if (oldAspectRatio !== newAspectRatio) {
        const newH = Math.floor(resizedWidget.w * oldAspectRatio);
        const newLayout = gridLayout.value.map((widget) =>
          widget.i === widgetId ? { ...resizedWidget, h: newH } : widget,
        );
        nextTick(() => {
          setLayout(newLayout);
        });
      }
    }
    resizingWidget.value = null;
  };

  return {
    handleResize,
    handleResized,
  };
};
