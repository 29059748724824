<template>
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="[
      {
        'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode,
        'border-yellow': highlightSection === section,
      },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
    ]"
  >
    <div class="my-2">
      <div class="flex items-center justify-between px-2">
        <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
          {{ $t(`report.section_title.${section}`) }}
        </h3>
        <div class="flex items-center" v-if="!approved">
          <button
            class="flex items-center text-orange text-sm"
            @click="
              copyDataOpen = true;
              searchDate = yesterday;
            "
          >
            <CalendarIcon class="md:h-4 h-3 pr-1" />
            <span class="md:text-sm text-xs hover:underline">{{
              $t("report.general.old_data_label")
            }}</span>
          </button>
          <PencilIcon
            v-if="!editMode"
            class="md:w-4 md:h-4 h-3 text-oaiGray-300 cursor-pointer ml-3"
            @click="activeEditMode"
          />
        </div>
        <CopySectionData
          :open="copyDataOpen"
          :date="searchDate"
          @closeModal="copyDataOpen = false"
          @copySectionData="copySectionData($event), (copyDataOpen = false)"
        />
      </div>
      <div v-if="approved && entries.length === 0">
        <p class="md:text-center pl-2 pt-2 md:p-0 text-sm md:text-base">
          {{ $t("report.general.section_empty") }}
        </p>
      </div>
    </div>
    <div :class="[editMode ? 'editInput' : 'overviewInput']">
      <div class="md:flex md:gap-4 py-1 px-2 hidden" v-if="entries.length">
        <div
          v-for="(field, key) in sectionConfig"
          :key="key"
          :class="[
            field.width,
            'flex-wrap md:flex-nowrap font-medium text-gray-600 gap-2 md:gap-4 flex justify-between',
          ]"
        >
          {{ $t(`report.sections.${section}.${key}`) }}
        </div>
        <div class="w-6 px-2 mr-3">
          <span class="sr-only">delete</span>
        </div>
      </div>
      <div
        v-for="(entry, idx) in entries"
        :key="idx"
        :class="[
          'flex flex-wrap md:flex-nowrap px-2 py-2 gap-2 md:gap-4 relative tableRow border-t border-gray-200',
          {
            'items-center': !validationError,
          },
        ]"
      >
        <template v-for="(value, name, entryIdx) in entry" :key="entryIdx">
          <div
            v-if="hasKey(name)"
            :class="[
              sectionConfig[name]['width'],
              `order-${sectionConfig[name]['order']}`,
              'grid grid-cols-3 md:grid-cols-1',
            ]"
          >
            <span class="text-xs inline-flex md:hidden col-span-1 font-bold">
              {{ $t(`report.sections.${section}.${name}`) }}
            </span>
            <div v-if="sectionConfig[name]['field_type'] === 'combobox'" class="col-span-2">
              <SelectCombobox
                @updateEvent="$emit('updateComboBox', section, name, $event, idx)"
                @addCustomField="
                  $emit('addCustomField', $event, section, name),
                    $emit('updateComboBox', section, name, $event, idx)
                "
                @deleteCustomField="$emit('deleteCustomField', $event, section, name)"
                @nextFocus="nextFocus($event, idx, name)"
                @directFocus="focusInputField(section, idx, name)"
                :editMode="$props.editMode"
                :customFields="sortSavedOptions(customFields[name], name)"
                :initialSelectedOption="entry[name]"
                :error="validationError"
                :typeOfData="name"
                :keyData="section"
                :tablePosition="{ row: idx, firstCol: isFirstCol(name) }"
              />
            </div>
            <div v-if="sectionConfig[name]['field_type'] === 'number'" class="col-span-2">
              <input
                type="number"
                :name="name"
                :disabled="!editMode"
                :class="{ 'oai-inputs': editMode }"
                min="0"
                step="1"
                :value="entry[name]"
                @input="$emit('updateFieldValue', $event, section, idx)"
                :id="`input-${section}-${idx}-${name}`"
                @keydown.enter.prevent="nextFocus($event, idx, name)"
              />
              <!-- eslint-enable -->
              <small v-if="entry[name] < 0 && validationError" class="text-red block">{{
                $t("err.negative_value")
              }}</small>
              <small
                v-if="entry[name] === '' && entry[name] !== '0' && validationError"
                class="text-red block"
                >{{ $t("err.required") }}</small
              >
            </div>
            <div v-if="sectionConfig[name]['field_type'] === 'textarea'" class="col-span-2">
              <textarea
                rows="2"
                name="note"
                v-if="editMode"
                :value="entry[name]"
                @input="$emit('updateFieldValue', $event, section, idx)"
                :placeholder="$t('report.general.notes_placeholder')"
                :class="{ 'oai-inputs': editMode }"
                :id="`input-${section}-${idx}-${name}`"
                @keydown.enter.prevent="nextFocus($event, idx, name)"
              />
              <div v-if="!editMode" class="w-1/2 md:w-full">
                <p class="text-left text-xs">
                  {{ entry[name] !== "" ? entry[name] : "-" }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <button :class="['flex justify-end md:order-last order-first', editMode ? 'mr-3' : 'mr-9']">
          <XMarkIcon
            v-if="editMode"
            class="w-6 text-red-900 hover:text-red"
            @click="$emit('removeEntry', entry, section)"
          />
        </button>
      </div>
    </div>
    <div :class="['flex justify-center', { 'mt-5': editMode }]">
      <button
        type="button"
        v-if="(editMode || entries.length === 0) && !approved"
        @click="addNewEntry"
        class="relative inline-flex items-center p-2 shadow-sm border-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-700 hover:text-white focus:outline-none"
      >
        <PlusIcon class="w-6" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { XMarkIcon, PlusIcon, PencilIcon, CalendarIcon } from "@heroicons/vue/24/solid";
import { format, subDays, getISODay, parseISO } from "date-fns";
import { defineComponent, PropType } from "vue";
import { useTrackEvent } from "@/composables/tracking";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import logger from "@/services/logger";
import {
  ReportComboValues,
  ReportSectionConfig,
  ReportListBasedSection,
  ReportListBasedEntry,
} from "@/types/DailyReport";
import CopySectionData from "@/views/daily_report/components/SectionCopyData.vue";
import SelectCombobox from "@/views/daily_report/components/SelectSearchCombobox.vue";

export default defineComponent({
  props: {
    entries: {
      type: Array as PropType<ReportListBasedEntry[]>,
      required: true,
    },
    section: {
      type: String as PropType<ReportListBasedSection>,
      required: true,
    },
    customFields: {
      type: Object as PropType<Record<string, ReportComboValues[]>>,
      required: true,
    },
    sectionConfig: {
      type: Object as PropType<ReportSectionConfig>,
      required: true,
    },
    editMode: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    highlightSection: {
      type: String as PropType<string>,
      required: true,
    },
    approved: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    validationError: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: [
    "addHighlight",
    "activeEditMode",
    "addEntry",
    "removeEntry",
    "updateComboBox",
    "updateFieldValue",
    "addCustomField",
    "deleteCustomField",
    "updateSectionData",
  ],
  components: {
    PlusIcon,
    PencilIcon,
    CalendarIcon,
    XMarkIcon,
    SelectCombobox,
    CopySectionData,
  },
  data() {
    return {
      copyDataOpen: false,
      date: this.$route.params.date as string,
      searchDate: "",
    };
  },

  setup() {
    const trackEvent = useTrackEvent();
    return {
      trackEvent,
    };
  },
  computed: {
    yesterday() {
      const dateObj = parseISO(this.date);
      if (getISODay(dateObj) === 1) {
        return format(subDays(dateObj, 2), "yyyy-MM-dd");
      }
      return format(subDays(dateObj, 1), "yyyy-MM-dd");
    },
    configList() {
      const result = Object.keys(this.sectionConfig).map((key) => ({
        ...this.sectionConfig[key],
        key,
      }));
      result.sort((a, b) => a.order - b.order);
      return result;
    },
  },
  methods: {
    hasKey(key: string) {
      return Object.prototype.hasOwnProperty.call(this.sectionConfig, key);
    },
    sortSavedOptions(options: Record<string, unknown>[], sectionName: string) {
      let defaultList = options.filter((option) => option.mode === "default");
      const customList = options.filter((option) => option.mode === "custom");

      defaultList = defaultList.sort((a, b) =>
        this.$t(`report.sections.${this.section}.${sectionName}_entries.${a.value}`).localeCompare(
          this.$t(`report.sections.${this.section}.${sectionName}_entries.${b.value}`),
        ),
      );
      customList.sort((a, b) => (a.value as string).localeCompare(b.value as string));

      return defaultList.concat(customList);
    },
    isFirstCol(columnName: string) {
      if (this.configList.length > 0) {
        return this.configList[0].key === columnName;
      }
    },
    focusInputField(sectionTitle: string, row: number, columName: string) {
      let element = document.getElementById(`input-${sectionTitle}-${row}-${columName}`);

      if (element !== null) {
        try {
          if (element.nodeName === "DIV") {
            const inputFields = element.querySelectorAll("input");
            if (inputFields.length > 0) {
              element = inputFields[0];
            } else {
              return;
            }
          }
          element.focus();
        } catch (error) {
          logger.error(error);
        }
      }
    },
    nextFocus(event: KeyboardEvent, rowIdx: number, columName: string) {
      if (!event.shiftKey && !event.altKey && !event.ctrlKey) {
        if (this.configList.length > 0) {
          const configListCurrentIndex = this.configList.findIndex(
            (item) => item.order === this.sectionConfig[columName].order,
          );

          if (configListCurrentIndex + 1 < this.configList.length) {
            this.focusInputField(
              this.section,
              rowIdx,
              this.configList[configListCurrentIndex + 1].key as string,
            );
          } else {
            if (rowIdx + 1 < this.entries.length) {
              this.focusInputField(this.section, rowIdx + 1, this.configList[0].key as string);
            } else {
              this.addNewEntry();
            }
          }
        }
      }
    },
    activeEditMode() {
      this.$emit("activeEditMode");
      this.$emit("addHighlight");
    },

    addNewEntry() {
      this.$emit("activeEditMode");

      const newEntry = {} as Record<string, unknown>;

      for (const [key, v] of Object.entries(this.sectionConfig)) {
        const value = v as Record<string, unknown>;

        if (value["field_type"] === "combobox") {
          newEntry[key] = {
            mode: "custom",
            value: "",
          };
        }
        if (value["field_type"] === "number") {
          newEntry[key] = 0;
        }
        if (value["field_type"] === "text") {
          newEntry[key] = "";
        }
        if (value["field_type"] === "textarea") {
          newEntry[key] = "";
        }
      }
      this.$emit("addEntry", newEntry, this.section);
    },
    copySectionData(date: string) {
      const { customer_name, site_id } = this.$route.params;
      this.trackEvent("dcr_copy-from_apply");
      return DailyReportRepository.getSectionValueCopy(
        this.section,
        customer_name as string,
        site_id as string,
        date,
      )
        .then((response) => {
          if (response.data.length === 0) {
            this.showToast("warning", this.$t("err.saved_values"));
          } else {
            this.$emit("updateSectionData", response.data);
          }
        })
        .catch(() => {
          this.showToast("warning", this.$t("err.saved_values"));
        })
        .finally(() => this.$emit("activeEditMode"));
    },
  },
});
</script>

<style></style>
