export const HIERARCHY_TAGS = {
  building: "building",
  level: "level",
  section: "section",
  component: "component",
} as const;

export const HIERARCHY_TAGS_META = {
  building: {
    text: "text-gray-100",
    txtDark: "text-gray-100",
    bgLight: "bg-gray-400",
    bgDark: "bg-gray-500",
    order: 0,
  },
  level: {
    text: "text-gray-700",
    txtDark: "text-gray-100",
    bgLight: "bg-gray-300",
    bgDark: "bg-gray-400",
    order: 1,
  },
  section: {
    text: "text-gray-700",
    txtDark: "text-gray-100",
    bgLight: "bg-gray-200",
    bgDark: "bg-gray-300",
    order: 2,
  },
  component: {
    text: "text-gray-700",
    txtDark: "text-gray-100",
    bgLight: "bg-gray-100",
    bgDark: "bg-gray-200",
    order: 3,
  },
} as const;
