<template>
  <div
    class="rounded-xl px-2 py-1 text-gray-900 text-xs leading-none"
    style="background: linear-gradient(36deg, #ff9f10, #fcff00)"
  >
    {{ $t("app_features.new_feature_badge") }}
  </div>
</template>

<script lang="ts" setup></script>
