import { EventModel, SchedulerPro } from "@bryntum/schedulerpro";
import { addDays, differenceInHours, eachDayOfInterval, format, parse } from "date-fns";
import {
  getProcessClasses,
  useCurrentCustomerName,
  useCurrentSiteId,
  ProcessClass,
  useNonWorkingDaysByDaySet,
  useIsWorkingDay,
  useTodayMinusOneWorkingDay,
  CameraRepository,
  OutagesByRange,
  Stream,
} from "oai-planner";
import { computed, ref } from "vue";
import defaultThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import { useCurrentProject } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import { apiClient } from "@/repositories/clients";
import logger from "@/services/logger";
import { Data } from "@/views/process_table/types";

export const useProcessClasses = () => {
  const currentProject = useCurrentProject();

  return computed(() => {
    const processGroups = currentProject?.process_groups || null;
    return getProcessClasses(processGroups) as ProcessClass[];
  });
};

const getEarliestStartAndLatestEnd = (events: EventModel[]) => {
  let start: Date | null = null;
  let end: Date | null = null;
  for (const event of events) {
    const eventStart = event.startDate as Date;
    const eventEnd = event.endDate as Date;
    if (!start || eventStart < start) {
      start = eventStart;
    }
    if (!end || eventEnd > end) {
      end = eventEnd;
    }
  }
  return [start, end] as const;
};

export const useOutages = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const currentProject = useCurrentProject();

  const { streams } = useStreams();
  const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet(apiClient);
  const isWorkingDay = useIsWorkingDay(apiClient);
  const todayMinusOneWorkingDay = useTodayMinusOneWorkingDay(apiClient);

  const areOutagesShown = ref(false);

  const streamsByCameraId = computed(() =>
    streams.value.reduce((acc, stream) => {
      acc[stream.camera_id] = stream;
      return acc;
    }, {} as Record<string, Stream>),
  );

  const toggleOutages = (schedulerPro: SchedulerPro) => {
    const { timeRangeStore, resourceStore, eventStore } = schedulerPro.project;

    if (areOutagesShown.value) {
      areOutagesShown.value = false;
      const currentOutageTimeRanges = timeRangeStore.allRecords.filter(
        (item) => typeof item.id === "string" && item.id.startsWith("outage_"),
      );
      timeRangeStore.remove(currentOutageTimeRanges);
      resourceStore.allRecords.forEach((record) => record.set("getOutagesFn", null));
      return;
    }

    areOutagesShown.value = true;

    const totalTimeSpan = schedulerPro.eventStore.getTotalTimeSpan() as {
      startDate: Date;
      endDate: Date;
    };
    const loadOutagesEndDate =
      currentProject.status === "completed" ? totalTimeSpan.endDate : todayMinusOneWorkingDay.value;

    CameraRepository(apiClient)
      .loadOutagesByRange(
        currentCustomerName,
        currentSiteId,
        totalTimeSpan.startDate,
        loadOutagesEndDate,
      )
      .then((outages: OutagesByRange) => {
        if (!areOutagesShown.value) {
          return;
        }
        const getOutagesFn = (date: Date) => {
          if (!isWorkingDay.value(date)) {
            return [];
          }
          const result = (outages[format(date, "yyyy-MM-dd")] || []).map((item) => {
            const stream = streamsByCameraId.value[item.camera_id];
            return {
              ...item,
              camera_name: stream?.name || item.camera_id,
            };
          });
          result.sort(
            (a, b) =>
              a.camera_name.localeCompare(b.camera_name) ||
              a.start_time.getTime() - b.start_time.getTime(),
          );
          return result;
        };

        resourceStore.allRecords.forEach((record) => record.set("getOutagesFn", getOutagesFn));

        const processEventsByDay = (eventStore.allRecords as EventModel[])
          .filter((record) => record.getData("type")?.startsWith("process_"))
          .reduce((acc, event) => {
            eachDayOfInterval({
              start: event.startDate as Date,
              end: event.endDate as Date,
            }).forEach((day) => {
              const key = format(day, "yyyy-MM-dd");
              if (!(key in acc)) {
                acc[key] = [];
              }
              acc[key].push(event);
            });
            return acc;
          }, {} as Record<string, EventModel[]>);

        const items = Object.entries(outages)
          .map(([key, value]) => [key, value, parse(key, "yyyy-MM-dd", new Date())] as const)
          .filter(([key, value, date]) => {
            if (nonWorkingDaysByDaySet.value?.has(key) || !isWorkingDay.value(date)) {
              return false;
            }
            const out = value.filter((entry) => {
              const outageHours = differenceInHours(entry.end_time, entry.start_time);
              const tmlHours = differenceInHours(entry.tml_end, entry.tml_start);
              const factor = 0.5;
              return outageHours > tmlHours * factor;
            });
            return out.length > streams.value.length / 2;
          })
          .flatMap(([key, value, date]) => {
            const processEvents = processEventsByDay[key] || [];
            if (processEvents.length === 0) {
              return [
                {
                  id: `outage_${key}`,
                  startDate: date,
                  endDate: addDays(date, 1),
                  cls: "planner-outage-timerange",
                },
              ];
            }
            const [startDate, endDate] = getEarliestStartAndLatestEnd(processEvents);
            if (!startDate || !endDate) {
              return [];
            }
            const hasBeforeStart = value.some((entry) => entry.start_time < startDate);
            const hasAfterEnd = value.some((entry) => entry.end_time > endDate);
            return [
              hasBeforeStart && {
                id: `outage_${key}_start`,
                startDate: date,
                endDate: startDate,
                cls: "planner-outage-timerange",
              },
              hasAfterEnd && {
                id: `outage_${key}_end`,
                startDate: endDate,
                endDate: addDays(date, 1),
                cls: "planner-outage-timerange",
              },
            ].filter((item) => item);
          });
        timeRangeStore.add(items);
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  return { toggleOutages, areOutagesShown };
};

export const getProcessTypes = (value: Data[], processClasses: ProcessClass[]): string[] => {
  const encodedLabelsToProcessElement = processClasses.reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass.processElement;
    return acc;
  }, {} as Record<string, string>);

  return [
    ...new Set(
      value
        .filter((item) => item.encoded_label)
        .map((item) => encodedLabelsToProcessElement[item.encoded_label as string]),
    ),
  ];
};

export const loadAndSetProcessThumbnailUrl = (
  event: EventModel,
  tooltipElement: HTMLDivElement,
) => {
  if (event.getData("type")?.startsWith("process")) {
    const imageElement = tooltipElement.querySelector(
      ".oaiProcessThumbnail",
    ) as HTMLImageElement | null;
    if (imageElement) {
      const stream = event.getData("stream") as Stream | undefined;
      if (stream) {
        OpsProcessesRepository.loadProcessThumbnail(
          stream.customer_name,
          stream.site_id,
          event.id as string,
        )
          .then((blob) => {
            if (document.body.contains(imageElement)) {
              imageElement.src = blob ? URL.createObjectURL(blob) : defaultThumbnailUrl;
            }
          })
          .catch((error) => {
            logger.error(error);
            if (document.body.contains(imageElement)) {
              imageElement.src = defaultThumbnailUrl;
            }
          });
      } else {
        imageElement.src = defaultThumbnailUrl;
      }
    }
  }
};
