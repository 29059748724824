<template>
  <NavigationComponent :activeTag="$t('app_features.gallery')">
    <div class="flex-1 flex flex-col overflow-hidden">
      <GalleryContent />
    </div>
  </NavigationComponent>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import { useTrackEvent } from "@/composables/tracking";
import GalleryContent from "@/views/camera/components/GalleryContent.vue";

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_gallery_view");
});
</script>
