import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "oai-planner";
import { useI18n } from "vue-i18n";
import { useCustomToast } from "@/composables/toast";
import UnitValueRepository from "@/repositories/UnitValueRepository";
import logger from "@/services/logger";
import { UnitValue, UnitValueAggregate } from "@/types/UnitValue";

export const useUnitValues = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const { t } = useI18n();

  const { data, isLoading } = useQuery<UnitValue[]>({
    queryKey: ["unit-values", currentCustomerName, currentSiteId],
    queryFn: () => UnitValueRepository.loadUnitValues(currentCustomerName, currentSiteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("unit_values.loading_error"));
      return false;
    },
  });

  return { unitValues: data, isLoading };
};

export const useSaveUnitValues = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: saveUnitValues,
    isLoading: isUpdating,
    isError: isUpdateError,
  } = useMutation<UnitValue[], Error, UnitValue[]>({
    mutationFn: (unitValues) =>
      UnitValueRepository.saveUnitValues(currentCustomerName, currentSiteId, unitValues),
    onSuccess: (updatedUnitValues) => {
      queryClient.setQueryData(
        ["unit-values", currentCustomerName, currentSiteId],
        updatedUnitValues,
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("unit_values.saving_error"));
      return false;
    },
  });

  return { saveUnitValues, isUpdating, isUpdateError };
};

export const useUnitValueAggregates = (options?: { failSilently: boolean }) => {
  const hasPermission = useHasPermission("app_admin");
  const { data, isLoading } = useQuery<UnitValueAggregate[]>({
    queryKey: ["unit-value-aggregates"],
    queryFn: () => (hasPermission ? UnitValueRepository.loadUnitValueAggregates() : []),
    useErrorBoundary: (error) => {
      logger.error(error);
      if (!options || !options.failSilently) {
        useCustomToast().error("Unable to load unit value aggregates");
      }
      return false;
    },
  });

  return { unitValueAggregates: data, areUnitValueAggregatesLoading: isLoading };
};
