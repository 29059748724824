<template>
  <NavigationComponent :activeTag="$t('app_features.live')">
    <CameraItemLayout>
      <template #contentLeft>
        <div>
          <div v-if="loading" class="loadingCard is-loading pt-4">
            <div
              class="image group block w-full aspect-w-4 aspect-h-3 bg-gray-50 overflow-hidden"
            />
          </div>
        </div>
        <LiveBase v-if="params?.camera_id" :cameraId="params?.camera_id as string" />
      </template>
    </CameraItemLayout>
  </NavigationComponent>
  <LiveSwitch class="fixed lg:absolute live-container right-5 z-10" />
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import NavigationComponent from "@/components/layout/NavigationComponent.vue";
import LiveBase from "@/components/live_video/LiveBase.vue";
import LiveSwitch from "@/components/live_video/LiveSwitch.vue";
import { useTrackEvent } from "@/composables/tracking";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";

const loading = ref(false);
const { params } = useRoute();

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_live_view");
});
</script>

<style scoped>
.live-container {
  top: 18px;
}

@media (min-width: 1024px) {
  .live-container {
    top: 16px;
  }
}
</style>
