import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { apiClient } from "@/repositories/clients";
import { Dashboard, DashboardToCreate, DashboardToUpdate } from "@/types/Dashboard";

const mapDashboard = (dashboard: Dashboard<string>): Dashboard => ({
  ...dashboard,
  created: parseISO(dashboard.created),
  updated: parseISO(dashboard.updated),
});

const loadDashboards = (customerName: string, siteId: string): Promise<Dashboard[]> =>
  apiClient
    .get<void, AxiosResponse<Dashboard<string>[]>>(`/dashboard/${customerName}/${siteId}`)
    .then((response) => response.data.map((dashboard) => mapDashboard(dashboard)));

const deleteDashboard = (
  customerName: string,
  siteId: string,
  dashboardId: string,
): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(`/dashboard/${customerName}/${siteId}/${dashboardId}`)
    .then((response) => response.data);

const createDashboard = (
  customerName: string,
  siteId: string,
  dashboardToCreate: DashboardToCreate,
): Promise<Dashboard> =>
  apiClient
    .post<DashboardToCreate, AxiosResponse<Dashboard<string>>>(
      `/dashboard/${customerName}/${siteId}`,
      dashboardToCreate,
    )
    .then((response) => mapDashboard(response.data));

const updateDashboard = (
  customerName: string,
  siteId: string,
  dashboardId: string,
  dashboardToCreate: DashboardToUpdate,
): Promise<Dashboard | null> =>
  apiClient
    .patch<DashboardToUpdate, AxiosResponse<Dashboard<string> | null>>(
      `/dashboard/${customerName}/${siteId}/${dashboardId}`,
      dashboardToCreate,
    )
    .then((response) => (response.data ? mapDashboard(response.data) : null));

export default {
  loadDashboards,
  deleteDashboard,
  createDashboard,
  updateDashboard,
};
