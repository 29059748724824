<template>
  <Combobox as="div" v-model="selectedOption">
    <div class="relative" :id="`input-${keyData}-${tablePosition.row}-${typeOfData}`">
      <ComboboxInput
        required
        :disabled="!editMode"
        :class="[editMode ? 'oai-inputs' : 'overviewInput', 'w-full text-xs ']"
        :displayValue="
          (option) =>
            option.mode === 'custom'
              ? option.value
              : option.value !== undefined
              ? setSuperscriptDisplay(
                  keyData === 'processes' && typeOfData === 'process_type'
                    ? $t(`process_classes.${option.value}`)
                    : $t(`report.sections.${keyData}.${typeOfData}_entries.${option.value}`),
                )
              : query
        "
        :placeholder="$t('report.general.empty_combobox')"
        @change="query = $event.target.value"
        v-on:keydown.tab.prevent="switchTab($event)"
        v-on:keydown.enter.prevent="onEnter($event)"
        aria-autocomplete="one-time-code"
        autocomplete="one-time-code"
      />
      <ComboboxButton
        class="focus:outline-none absolute inset-y-0 right-0 flex items-center rounded-r-md px-2"
        v-if="editMode"
        @click="query = ''"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>
      <ComboboxOptions
        :disabled="!editMode"
        class="focus:outline-none absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500/5 text-sm"
        @click="$emit('updateEvent', selectedOption)"
      >
        <div v-if="filteredData.length === 0 && query === ''">
          <p class="py-2 pl-2">{{ $t("report.general.type_value") }}</p>
        </div>

        <ComboboxOption
          v-for="(option, index) in filteredData"
          :key="index"
          :value="option"
          as="template"
          v-slot="{ active, selected }"
          v-else
        >
          <li
            class="align-center relative flex cursor-default select-none items-center justify-between py-2 pl-3 pr-3"
            :class="{
              'bg-yellow-600 text-white': active,
              'text-gray-900': !active,
              'text-gray-900': selected || option.value === selectedOption.value,
            }"
          >
            <span v-if="option.mode === 'custom'">
              {{ option.value }}
            </span>
            <span
              v-else
              v-html="
                setSuperscriptOption(
                  keyData === 'processes' && typeOfData === 'process_type'
                    ? $t(`process_classes.${option.value}`)
                    : $t(`report.sections.${keyData}.${typeOfData}_entries.${option.value}`),
                )
              "
            >
            </span>
            <span
              class="items- center flex p-1 hover:bg-gray-100"
              v-if="option.mode === 'custom'"
              @click.stop="deleteCustom(option.value)"
            >
              <XMarkIcon v-if="option.mode === 'custom'" class="z-50 h-3 w-3 text-xs text-red" />
            </span>
          </li>
        </ComboboxOption>
        <ComboboxOption v-if="query !== '' && !isFound(query)" v-slot="{ active }">
          <div
            :class="{
              'relative cursor-default select-none bg-yellow-600 px-4 py-2 font-semibold text-white':
                active,
              'relative cursor-default select-none px-4 py-2 text-gray-900': !active,
            }"
          >
            <p @click="createCustomField(query)">
              {{ $t("report.general.create") }}:
              <span
                class="mx-1 rounded-md bg-gray-100 px-3 py-1 font-normal text-gray-500 hover:bg-yellow hover:text-white"
              >
                {{ query }}</span
              >
            </p>
          </div>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
  <small v-if="selectedOption.value === '' && error" class="text-red">{{
    $t("err.required")
  }}</small>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import logger from "@/services/logger";

export default {
  name: "SelectCombobox",
  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
    initialSelectedOption: {
      type: [Object, String],
      default: () => ({}),
    },
    typeOfData: {
      type: String,
    },
    keyData: {
      type: String,
    },
    editMode: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    tablePosition: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["updateEvent", "addCustomField", "deleteCustomField", "nextFocus", "directFocus"],
  data() {
    return {
      activeTag: this.$t("app_features.daily_report"),
      reports: [],
      query: "",
      options: this.customFields,
      errorMessage: "",
      selectedOption: this.initialSelectedOption,
    };
  },
  components: {
    ChevronUpDownIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    XMarkIcon,
  },
  computed: {
    filteredData() {
      if (this.query === "") {
        return this.customFields;
      } else {
        return this.customFields.filter((option) => {
          if (option.mode === "default") {
            const translation =
              this.keyData === "processes" && this.typeOfData === "process_type"
                ? this.$t(`process_classes.${option.value}`)
                : this.$t(
                    `report.sections.${this.keyData}.${this.typeOfData}_entries.${option.value}`,
                  );

            return translation.toLowerCase().includes(this.query.toLowerCase());
          } else {
            return option.value.toLowerCase().includes(this.query.toLowerCase());
          }
        });
      }
    },
  },
  watch: {
    initialSelectedOption: {
      handler(newValue) {
        this.selectedOption = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.tablePosition.firstCol && this.editMode) {
      this.$emit("directFocus");
    }
  },
  methods: {
    setSuperscriptDisplay(value_string) {
      if (value_string.includes("^")) {
        const components = value_string.split("^");
        if (components.length === 2) {
          let unicodeSuperscript = "";
          if (components[1] === "2") {
            unicodeSuperscript = "\u00B2";
          }
          if (components[1] === "3") {
            unicodeSuperscript = "\u00B3";
          }
          value_string = components[0] + unicodeSuperscript;
        }
      }

      return value_string;
    },
    setSuperscriptOption(value_string) {
      if (value_string.includes("^")) {
        const components = value_string.split("^");
        if (components.length === 2) {
          if (components[1] === "2" || components[1] === "3") {
            value_string = `<span>${components[0]}<sup>${components[1]}</sup></span>`;
          }
        }
      }

      return value_string;
    },
    switchTab(event) {
      if (this.selectedOption) {
        this.$emit("updateEvent", this.selectedOption);
      } else {
        this.$emit("updateEvent", { mode: "custom", value: "" });
      }
      this.query = "";
      this.$emit("nextFocus", event);
    },
    onEnter(event) {
      if (this.selectedOption) {
        this.$emit("updateEvent", this.selectedOption);
      } else {
        this.createCustomField(this.query);
      }
      this.query = "";
      this.$emit("nextFocus", event);
    },

    isFound(value) {
      return this.customFields.some((el) => {
        if (el.mode === "custom") {
          return el.value === value;
        } else {
          const translation =
            this.keyData !== "processes"
              ? this.$t(`report.sections.${this.keyData}.${this.typeOfData}_entries.${el.value}`)
              : this.$t(`process_classes.${el.value}`);
          return translation === value;
        }
      });
    },

    createCustomField(value) {
      const found = this.isFound(value);

      if (!found) {
        const newValue = { mode: "custom", value: value };
        const customFieldToAddPayload = {
          key: [this.keyData, this.typeOfData],
          value: newValue,
        };

        return DailyReportRepository.updateCustomField(
          this.currentCustomerName,
          this.currentSiteId,
          "add",
          customFieldToAddPayload,
        )
          .then((response) => {
            if (response.status === 200) {
              this.$emit("addCustomField", newValue);
            }
          })
          .catch((error) => {
            logger.error(error);
            if (error.toJSON().status) {
              this.errorMessage = "Item is already in list";
            }
          })
          .finally(() => {
            this.query = "";
            return false;
          });
      } else {
        this.query = "";
        return true;
      }
    },

    deleteCustom(value) {
      const valueToDelete = { mode: "custom", value: value };
      const customFieldToDeletePayload = {
        key: [this.keyData, this.typeOfData],
        value: valueToDelete,
      };

      return DailyReportRepository.updateCustomField(
        this.currentCustomerName,
        this.currentSiteId,
        "delete",
        customFieldToDeletePayload,
      ).then((response) => {
        if (response.status === 200) {
          this.$emit("deleteCustomField", valueToDelete);
        }
      });
    },
  },
};
</script>
