<template>
  <Popover class="relative" v-slot="{ open }">
    <PopoverButton v-if="!defaultOpen" class="w-full outline-none">
      <slot>
        <template
          :class="[
            'w-full group inline-flex items-center justify-between py-1.5 px-3 bg-white rounded-md text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 sm:text-sm text-xs sm:leading-6  text-gray-400',
            open ? 'ring-2 ring-yellow-500' : '',
          ]"
        >
          <span
            >{{ t(placeholder)
            }}<span class="ml-0.5" v-if="selectedCount">({{ selectedCount }})</span></span
          >
          <ChevronUpDownIcon
            class="ml-2 h-5 w-5 transition duration-150 ease-in-out"
            aria-hidden="true"
          />
        </template>
      </slot>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        focus
        class="absolute z-50 min-w-max h-max w-full sm:px-0 lg:max-w-5xl overflow-y-scroll xl:overflow-y-hidden PopoverPanelContent"
        :class="[
          !defaultOpen &&
            'md:right-0 lg:left-0 lg:-translate-x-1/4 lg:right-auto md:-translate-x-w-max mt-3',
          popoverClass,
        ]"
        v-slot="{ close }"
        :static="defaultOpen"
      >
        <div class="bg-gray-50 border border-gray-200 shadow rounded-lg p-4 mb-4" ref="popover">
          <div class="mb-4 relative xl:w-1/2 2xl:mr-3">
            <input
              ref="searchLocation"
              type="text"
              class="w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:ring-yellow-500 focus:border-yellow-500"
              :placeholder="t('buttons.search')"
              v-model="searchValue"
            />
            <XMarkIcon
              v-if="searchValue !== ''"
              class="w-4 absolute items-center bottom-0 right-1 top-1.5 text-gray-500"
              @click="resetSearchBar"
            />
          </div>
          <div
            :class="[
              {
                'grid-cols-1': (filteredGroups?.length ?? 0) === 1,
                'grid-cols-2': (filteredGroups?.length ?? 0) === 2,
                'xl:grid-cols-3': (filteredGroups?.length ?? 0) === 3,
                'xl:grid-cols-4': (filteredGroups?.length ?? 0) >= 4,
              },
              gridBoxClass,
            ]"
            class="sm:grid lg:grid-flow-auto gap-2 items-start mb-4"
          >
            <div
              class="overflow-y-auto pl-4 pr-2 lg:max-w-[280px] lg:w-[280px] shadow bg-white h-96 sm:max-w-1/2"
              v-for="(group, idx) in filteredGroups"
              :key="group.field + idx"
            >
              <div class="">
                <div class="flex px-3 items-center justify-between sticky top-0 bg-white z-50">
                  <p class="font-bold py-1">{{ group.name }}</p>
                  <button
                    v-if="groupHasSelectedValues(group)"
                    @click="clearSelectedInGroup(group.field)"
                    class="text-gray-500 text-xs hover:underline"
                    type="button"
                  >
                    {{ $t("buttons.clear") }}
                  </button>
                </div>
                <div
                  class="py-2 px-3 rounded-md w-full flex items-center hover:bg-yellow-200"
                  @click="
                    handleAllOptionsChange(group.field, !checkAll[group.field]);
                    checkAll[group.field] ? emits('userInput') : '';
                  "
                >
                  <div
                    v-if="
                      groupHasSelectedValues(group) &&
                      !checkAll[group.field] &&
                      selectedItems[group.field].size > 0
                    "
                    class="h-4 w-4 rounded bg-yellow-600 text-white ring-1 ring-yellow text-center leading-4 text-sm"
                  >
                    -
                  </div>
                  <input
                    type="checkbox"
                    :id="`${group.field}-all`"
                    :checked="checkAll[group.field]"
                    v-else
                    class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500"
                  />
                  <label :for="`${group.field}-all`" class="ml-3 text-sm" @click.prevent
                    >{{ t("analytics.planner.all") }}
                    <span>({{ group.options.length }})</span></label
                  >
                </div>
                <!-- Individual options -->
                <template v-for="(option, idx) in group.options" :key="idx">
                  <div
                    class="cursor-pointer py-2 px-3 rounded-md w-full flex items-center hover:bg-yellow-200"
                    @click="
                      handleOptionChange(group.field, option.value);
                      selectedItems[group.field]?.has(option.value) ? emits('userInput') : '';
                    "
                  >
                    <input
                      type="checkbox"
                      :id="`${group.field}-${idx}`"
                      :value="option.value"
                      :checked="selectedItems[group.field]?.has(option.value)"
                      class="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                    />
                    <label
                      :for="`${group.field}-${idx}`"
                      class="cursor-pointer ml-3 text-sm"
                      @click.prevent
                      >{{ option.name }}</label
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>

          <MainButton
            :label="t('buttons.apply')"
            class="sm:mr-0 !w-full"
            type="button"
            color="yellow"
            @click="handleChange(close)"
            v-if="changeOnSubmit"
          />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { ref, computed, PropType, watchEffect, watch, Ref } from "vue";
import { useI18n } from "vue-i18n";
import MainButton from "@/components/other/MainButton.vue";
import { LocationTypeFilter, ResourceGroup } from "@/types/Process";

const mapDefaultSelectedProps = (value: Record<string, string[]>): Record<string, Set<string>> => {
  const result: Record<string, Set<string>> = {};
  for (const key in value) {
    result[key] = new Set(value[key]);
  }
  return result;
};

const { t } = useI18n();
const props = defineProps({
  groups: {
    type: Array as PropType<ResourceGroup[]>,
    required: true,
  },
  selected: {
    type: Object as PropType<Record<string, string[]>>,
    default: () => ({}),
  },
  placeholder: {
    type: String as PropType<string>,
    default: "",
  },
  changeOnSubmit: {
    type: Boolean,
    default: false,
  },
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  popoverClass: {
    type: String,
    default: "",
  },
  tableWidth: {
    type: Number,
  },
  gridBoxClass: {
    type: String,
  },
});
const selectedItems = ref();
const searchValue = ref("");
const filteredGroups = ref([{ name: "", field: "", options: [{ value: "", name: "" }] }]) as Ref<
  LocationTypeFilter[]
>;

const searchLocation = ref<HTMLElement | null>(null);

const filterOrder = ["building", "level", "section", "component"];
const sortedGroups = computed(() => {
  return (props.groups || [])
    .slice()
    .sort((a, b) => filterOrder.indexOf(a.field) - filterOrder.indexOf(b.field));
});

watchEffect(() => {
  if (props) {
    selectedItems.value = mapDefaultSelectedProps(props.selected);

    if (searchValue.value) {
      filteredGroups.value = sortedGroups.value.map((group) => {
        const filteredOptions = group.options.filter((option) =>
          option.name.toLowerCase().includes(searchValue.value.toLowerCase()),
        );
        return { ...group, options: filteredOptions };
      });
    } else {
      filteredGroups.value = sortedGroups.value;
    }
  }
});

const checkAll = computed<Record<string, boolean>>(() => {
  const result: Record<string, boolean> = {};
  for (const key in selectedItems.value) {
    const filteredOptions =
      filteredGroups.value.find((group) => group.field === key)?.options ?? [];
    result[key] =
      filteredOptions.length > 0 &&
      filteredOptions.every((option) => selectedItems.value[key].has(option.value));
  }
  return result;
});

const resetSearchBar = () => {
  searchValue.value = "";
};

const emits = defineEmits(["update:selected", "userInput"]);

const groupHasSelectedValues = (group: {
  name: string;
  field: string;
  options: { value: string; name: string }[];
}) => {
  return filteredGroups.value
    ?.find((g) => g.field === group.field)
    ?.options.some((option) => selectedItems.value[group.field].has(option.value));
};

const handleAllOptionsChange = (group: string, checked: boolean) => {
  const filteredGroup: LocationTypeFilter | undefined = filteredGroups.value.find(
    (g) => g.field === group,
  );

  if (!filteredGroup) return;

  const selectedGroup = selectedItems.value[group];

  const filteredCheckedOptions = filteredGroup.options
    .filter((option) => selectedGroup.has(option.value))
    .map((option) => option.value);
  if (!checked) {
    filteredCheckedOptions.forEach((option) => selectedGroup.delete(option));
  } else {
    filteredGroup.options.forEach((option) => selectedGroup.add(option.value));
  }

  if (props.changeOnSubmit) {
    return;
  }

  const selects: Record<string, string[]> = {};
  for (const key in selectedItems.value) {
    selects[key] = Array.from(selectedItems.value[key]);
    const groupOptions = filteredGroups.value.find((g) => g.field === key)?.options ?? [];
    checkAll.value[key] = groupOptions.length === selects[key].length;
  }
  emits("update:selected", selects);
};

const handleOptionChange = (group: string, value: string) => {
  const selectedValue = selectedItems.value;
  if (selectedValue[group].has(value)) {
    selectedValue[group].delete(value);
  } else {
    selectedValue[group].add(value);
  }

  if (props.changeOnSubmit) {
    return;
  }

  const selects: Record<string, string[]> = {};
  for (const key in selectedValue) {
    selects[key] = Array.from(selectedValue[key]);
    checkAll.value[key] =
      filteredGroups.value.find((g) => g.field === key)?.options.length === selects[key].length;
  }
  emits("update:selected", selects);
};

const clearSelectedInGroup = (groupField: string) => {
  const selectedValue = selectedItems.value;
  selectedValue[groupField] = new Set();

  if (props.changeOnSubmit) {
    return;
  }

  const selects: Record<string, string[]> = {};
  for (const key in selectedValue) {
    selects[key] = Array.from(selectedValue[key]);
  }

  emits("update:selected", selectedValue);
};

const handleChange = (close: () => void) => {
  const selects: Record<string, string[]> = {};
  for (const key in selectedItems.value) {
    selects[key] = Array.from(selectedItems.value[key]);
  }
  emits("update:selected", selects);
  close();
};

const selectedCount = computed(() => {
  let count = 0;
  for (const key in selectedItems.value) {
    count += selectedItems.value[key].size;
  }
  return count;
});

watch(searchValue, (searchString) => {
  if (searchString) {
    filteredGroups.value = sortedGroups.value.map((group) => {
      const filteredOptions = group.options.filter((option) =>
        option.name.toLowerCase().includes(searchString.toLowerCase()),
      );
      checkAll.value[group.field] =
        filteredOptions.length === selectedItems.value[group.field].length;
      return { ...group, options: filteredOptions };
    });
  } else {
    filteredGroups.value = sortedGroups.value;
  }
});

const popover = ref();
watch(popover, (isOpen) => {
  if (!isOpen) {
    resetSearchBar();
  } else {
    searchLocation.value?.focus();
  }
});
</script>
