<template>
  <VideoPlayer
    v-if="showPlayer"
    :src="src!"
    controls
    class="video-js vjs-big-play-centered vjs-4-3"
    :class="{ oculaiVideoPlayerAddRightSpaceToControlBar: addRightSpaceToControlBar }"
    :playbackRates="hls ? [] : [0.25, 0.5, 1, 2, 5, 10]"
    muted
    fluid
    fill
    :type="hls ? 'application/x-mpegURL' : undefined"
    @error="handleError"
    :controlBar="{ volumePanel: false }"
    @mounted="handleMounted"
    :html5="{ nativeTextTracks: false }"
    :preload="autoPlay ? 'auto' : 'none'"
  />
  <template v-if="!showPlayer">
    <div
      v-if="loading && !hasError"
      class="flex items-center justify-center bg-oaiGray-900 relative vjs-waiting"
      :style="{ aspectRatio }"
    >
      <div class="vjs-loading-spinner" />
    </div>
    <div v-else class="flex items-start bg-oaiGray-900" :style="{ aspectRatio }">
      <div
        class="rounded-md bg-yellow-400/50 p-4 text-center text-xs sm:text-sm text-gray-50 flex-1 m-5"
      >
        {{ hasError || !noSrcMessage ? $t("video_player.unable_to_play_message") : noSrcMessage }}
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { VideoPlayer } from "@videojs-player/vue";
import mobile from "is-mobile";
import videojs from "video.js";
import { computed, ref, watch } from "vue";
import logger from "@/services/logger";
import { OculaiVideoPlayerProps } from "@/types/VideoPlayer";

const aspectRatio = "4 / 3";

import Player = videojs.Player;

const props = withDefaults(defineProps<OculaiVideoPlayerProps>(), { autoPlay: true });

const hasError = ref(false);

const showPlayer = computed(() => !props.loading && !!props.src && !hasError.value);

const handleError = (event: Event) => {
  hasError.value = true;
  const target = event.target as unknown as { player: Player };
  const error = target.player.error() as MediaError & { src?: string | null };
  error.src = props.src;
  logger.error("Unhandled error in video player", error);
};

const handleMounted = (event: { player: Player }) => {
  if (mobile() || !props.autoPlay) {
    return;
  }
  // based on https://videojs.com/blog/autoplay-best-practices-with-video-js/
  const playPromise = event.player.play();
  if (playPromise) {
    playPromise.catch((error) => {
      const knownErrors = [
        "The play() request was interrupted",
        "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
        "The fetching process for the media resource was aborted by the user agent at the user's request.",
        "The operation was aborted.",
        "Failed to load because no supported source was found.",
      ];
      if (knownErrors.every((errorMessage) => !error.message.includes(errorMessage))) {
        error.src = props.src;
        logger.error("Unable to auto play video", error);
      }
    });
  }
};

watch(
  () => [props.src, props.loading],
  () => {
    hasError.value = false;
  },
);
</script>

<style>
.oculaiVideoPlayerAddRightSpaceToControlBar .vjs-control-bar {
  padding-right: 25px;
}
</style>
