<template>
  <div class="flex items-center mt-2 2xl:mt-0 2xl:justify-end justify-center flex-wrap">
    <div v-for="processType in processTypes" :key="processType" class="mx-1.5 text-sm align-middle">
      <span :class="['w-3 h-3 mr-1 inline-block', `plannerProcessEvent_${processType}`]" />
      {{ $t(`process_classes.${processType}`) }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useProcessClasses } from "@/composables/process";

const processClasses = useProcessClasses();

const processTypes = computed(() => {
  return [...new Set(processClasses.value.map((item) => item.processElement))];
});
</script>
