<template>
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="[
      { 'border-yellow': highlightSection === 'header' },
      'border bg-white shadow sm:rounded-lg md:px-6 px-3 py-6 weather sm:flex items-center sm:justify-between',
    ]"
  >
    <div class="sm:mt-0 md:mt-6 md:basis-1/2 basis-1/4">
      <div class="flex">
        <h3
          class="md:text-lg leading-4 font-semibold text-gray-900 text-center sm:text-left capitalize"
        >
          {{ $t("report.section_title.working_hours") }}
        </h3>

        <button @click="$emit('activeEditMode')" class="pl-2" v-if="!editMode && !approved">
          <PencilIcon class="w-4 h-4 pt-1" />
        </button>
      </div>
      <div class="relative w-min">
        <div
          class="flex items-center mb-6 mt-3 sm:mb-0 w-min"
          :class="editMode ? 'editInput md:justify-start justify-center' : 'overviewInput w-min'"
        >
          <input
            required
            type="time"
            class="oai-inputs text-center min-w-min"
            name="start_time"
            :disabled="!editMode"
            :value="siteDuration.start_time"
            @change="$emit('updateSiteDuration', $event)"
          />
          <minus-icon class="pt-1 w-5 mx-3" />
          <input
            required
            class="oai-inputs text-center min-w-min"
            type="time"
            name="end_time"
            :disabled="!editMode"
            :value="siteDuration.end_time"
            @change="$emit('updateSiteDuration', $event)"
          />
        </div>
        <small
          v-if="validationError && !siteDuration.start_time"
          :class="['text-red absolute', !siteDuration.start_time && 'left-0']"
          >{{ $t("err.required") }}</small
        >
        <small
          v-if="validationError && !siteDuration.end_time"
          :class="['text-red absolute', !siteDuration.end_time && 'right-0']"
          >{{ $t("err.required") }}</small
        >

        <small
          v-if="
            validationError && siteDuration.start_time?.localeCompare(siteDuration.end_time) > 0
          "
          class="text-red block"
          >{{ $t("err.time") }}</small
        >
      </div>
    </div>
    <div
      id="chart-container"
      class="md:basis-1/4 basis-3/4"
      style="flex-basis: 60%"
      ref="container"
    ></div>
  </div>
</template>

<script lang="ts">
import { MinusIcon, PencilIcon } from "@heroicons/vue/24/solid";
import { useElementSize } from "@vueuse/core";
import { defineComponent } from "vue";
import { ref } from "vue";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import logger from "@/services/logger";
import { WeatherPlotResponse } from "@/types/DailyReport";

let Plotly: typeof import("plotly.js-dist-min") | null = null;
const container = ref(null as HTMLElement | null);
const { width } = useElementSize(container);

export default defineComponent({
  props: ["date", "siteDuration", "editMode", "highlightSection", "approved", "validationError"],
  emits: ["activeEditMode", "addHighlight", "updateSiteDuration"],
  components: {
    PencilIcon,
    MinusIcon,
  },
  data() {
    return {
      openLoader: false,
      open: false,
      observer: null,
      isContrastActive: false,
      loading: false,
    };
  },
  computed: {
    today() {
      return this.date.split("-").reverse().join(".");
    },
  },
  async mounted() {
    const [weatherResponse] = await Promise.all([this.getWeather(), this.loadPlotly()]);
    if (weatherResponse) {
      this.drawPlot(weatherResponse);
    }
  },
  beforeUnmount() {
    const graph = document.getElementById("chart-container");
    if (graph) {
      Plotly?.purge(graph);
    }
  },
  methods: {
    async loadPlotly() {
      Plotly = await import("plotly.js-dist-min").catch((error) => {
        logger.error(error);
        return null;
      });
    },
    getWeather() {
      const lang = localStorage.getItem("lang") || "de";

      return DailyReportRepository.getDailyReportWeatherPlot(
        this.currentCustomerName,
        this.currentSiteId,
        this.date,
        lang,
      ).catch((error) => {
        logger.error(error);
        return null;
      });
    },
    drawPlot(response: WeatherPlotResponse) {
      const data = JSON.parse(response["plot"]).data;
      const layout = JSON.parse(response["plot"]).layout;
      layout.height = 200;
      layout.width = width.value;

      const graphDiv = document.getElementById("chart-container");
      if (!graphDiv) {
        return;
      }
      const config = { responsive: true, displayModeBar: false };
      Plotly?.newPlot(graphDiv, data, layout, config);
    },
  },
});
</script>

<style scoped>
#chart-container {
  flex-basis: 60%;
}

@media (max-width: 48rem) {
  #chart-container {
    flex-basis: 80%;
  }
}
</style>
