<template>
  <BasicModal
    :open="open"
    customClass="xl:w-4/12 md:w-3/5 w-full"
    @closeModal="$emit('closeModal')"
  >
    <div class="py-3 sm:w-full">
      <h3 class="text-lg font-medium leading-6 text-gray-900 text-center">
        {{ $t("report.general.old_data_title") }}
      </h3>
      <div class="mt-3">
        <div class="flex sm:flex-row flex-col justify-center">
          <input
            type="date"
            max="9999-12-12T00:00:00.00"
            id="searchDate"
            v-model="searchDate"
            class="sm:w-1/2 form-control block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-xs border-gray-300 rounded-md sm:mr-2"
          />
          <MainButton
            :label="$t('buttons.copy')"
            type="button"
            color="yellow"
            class="sm:mr-0"
            @clicked="$emit('copySectionData', searchDate)"
          >
          </MainButton>
        </div>
      </div>
    </div>
  </BasicModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BasicModal from "@/components/modals/BasicModal.vue";
import MainButton from "@/components/other/MainButton.vue";

export default defineComponent({
  name: "CopySectionData",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  components: {
    BasicModal,
    MainButton,
  },
  emits: ["closeModal", "copySectionData"],
  data() {
    return {
      searchDate: "" as string,
    };
  },
  watch: {
    date(newValue) {
      this.searchDate = newValue;
    },
  },
});
</script>
