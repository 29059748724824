<template>
  <div
    ref="slot"
    :class="['group w-max relative', wrapperClass]"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <slot />
  </div>
  <span
    :class="[
      'pointer-events-none absolute w-max transition-opacity bg-gray-700 text-white px-1.5 rounded-sm py-0.5 text-[0.7rem] whitespace-pre-wrap transform',
      `${positionHorizontal}-0`,
      showTooltip ? 'opacity-90' : 'opacity-0',
      positionVertical === 'top' ? 'bottom-[120%]' : 'top-[120%]',
    ]"
    style="max-width: 220px"
  >
    {{ content }}
  </span>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";

defineProps({
  content: {
    type: String,
    default: "",
  },
  wrapperClass: {
    type: String,
    default: "",
  },
  positionHorizontal: {
    type: String as PropType<"left" | "right">,
    default: "left",
  },
  positionVertical: {
    type: String as PropType<"top" | "bottom">,
    default: "bottom",
  },
});

const showTooltip = ref(false);
</script>
