<template>
  <ReportFormSection number="2" :label="t('analytics.reports.filters')">
    <div class="flex gap-2 flex-col">
      <ReportLocationFilterField
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        :ignoreLevel="true"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection number="3" :label="t('analytics.reports.plot_config')" :defaultOpen="false">
    <MilestoneConfigFields
      :config="config"
      :filters="filters"
      @configChange="emit('change', { config: $event })"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report } from "@/types/Report";
import { MilestoneReportConfig, MilestoneReportFilters } from "@/types/reports/PlotMilestone";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import MilestoneConfigFields from "@/views/reports/plots/milestone/MilestoneConfigFields.vue";

defineProps<{
  filters: MilestoneReportFilters;
  config: MilestoneReportConfig;
  aggregation: null;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();
</script>
