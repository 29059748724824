<template>
  <QuickSearch v-if="isQuickSearchOpen" @closed="isQuickSearchOpen = false" />
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 z-40 flex lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600/75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="focus:outline-none relative flex w-full max-w-xs flex-1 flex-col bg-white">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="focus:outline-none ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="h-0 flex-1 overflow-y-auto bg-oaiGray-500 pb-4 pt-5">
              <div class="flex shrink-0 items-center px-4">
                <router-link to="/">
                  <img
                    class="h-8 w-auto"
                    src="../../assets/imgs/logo/multipledark.png"
                    alt="Workflow"
                  />
                </router-link>
              </div>

              <nav aria-label="Sidebar" class="mt-5">
                <!-- Sidebar Content small screen -->
                <SidebarContent :content="content" />
              </nav>
            </div>
            <div class="border-t border-oaiGray-400 bg-oaiGray-500">
              <!-- Dropdown Menu for Settings/Logout -->
              <SettingsDropdown></SettingsDropdown>
            </div>
          </div>
        </TransitionChild>
        <div class="w-14 shrink-0" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col border-r border-gray-300 bg-oaiGray-500">
        <div class="flex flex-1 flex-col overflow-y-auto">
          <div
            class="flex h-16 shrink-0 items-center border-b border-oaiGray-400 bg-oaiGray-500 px-4 justify-between"
          >
            <router-link to="/">
              <img
                class="h-8 w-auto"
                src="../../assets/imgs/logo/multipledark.png"
                alt="Workflow"
              />
            </router-link>
            <div
              class="text-gray-800 lg:text-white z-40 cursor-pointer"
              v-if="hasPermission('app_admin')"
              @click="isQuickSearchOpen = true"
            >
              <MagnifyingGlassIcon class="w-6 h-6" />
            </div>
          </div>
          <div class="flex flex-1 flex-col overflow-y-auto">
            <nav class="mt-5 flex-1" aria-label="Sidebar">
              <!-- Project-->
              <Menu as="div" class="relative space-y-1 px-2" v-if="!this.hideProjectsTab">
                <div>
                  <MenuButton id="project-button" class="w-full">
                    <div v-if="currentSidebarTab === 'Projekte'">
                      <div
                        id="project-button-div"
                        class="oai-dashboard group flex items-center rounded-md bg-oaiGray-400 px-2 py-2 text-sm font-medium text-yellow-400"
                      >
                        <RectangleStackIcon
                          id="project-button-icon-main"
                          class="mr-3 h-8 w-6 text-yellow-400"
                        ></RectangleStackIcon>
                        <div id="project-button-text" class="pr-2 text-yellow-400">
                          {{ $t("partials.sidebar.projects") }}
                        </div>
                        <ChevronDownIcon
                          id="project-button-icon-dropdown"
                          class="mr-3 h-5 w-5 text-white-900 group-hover:text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div v-else>
                      <div
                        id="project-button-div"
                        class="group flex items-center rounded-md px-2 py-2 text-sm font-medium text-white-900 hover:bg-yellow-50 hover:text-oaiGray-500"
                      >
                        <RectangleStackIcon
                          id="project-button-icon-main"
                          class="mr-3 h-8 w-6 text-white-900 group-hover:text-yellow-400"
                        ></RectangleStackIcon>
                        <div class="pr-2">
                          {{ $t("partials.sidebar.projects") }}
                        </div>
                        <ChevronDownIcon
                          id="project-button-icon-dropdown"
                          class="mr-3 h-5 w-5 text-white-900 group-hover:text-oaiGray-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="ring-black focus:outline-none absolute left-0 right-0 z-10 mx-2 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-opacity-5"
                  >
                    <div class="py-1">
                      <div v-for="item in $store.state.projects" :key="item._id">
                        <MenuItem v-slot="{ active }">
                          <router-link
                            :to="{
                              name: 'ProjectOverview',
                              params: {
                                customer_name: item.customer_name,
                                site_id: item.site_id,
                              },
                            }"
                            :class="[
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center py-2 pl-3 text-sm',
                            ]"
                          >
                            <component
                              :is="'BuildingOfficeIcon'"
                              :class="[
                                item === currentProjectV2
                                  ? 'text-yellow-400'
                                  : 'text-white-900 group-hover:text-yellow-400',
                                'mr-3 h-4 w-4 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </router-link>
                        </MenuItem>
                      </div>
                    </div>
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          to="/"
                          :class="[
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          ]"
                        >
                          {{ $t("partials.sidebar.show_all_projects") }}
                        </router-link>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>

              <!-- Sidebar Content large screen -->
              <SidebarContent :content="content" />
            </nav>
            <div class="border-t border-oaiGray-400">
              <!-- Dropdown Menu for Settings/Logout -->
              <SettingsDropdown></SettingsDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden flex items-center gap-4 bg-gray-50"
    >
      <button
        type="button"
        class="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-oaiGray-100 hover:text-yellow-400"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div
        class="text-gray-800 lg:text-white z-40 cursor-pointer"
        v-if="hasPermission('app_admin')"
        @click="isQuickSearchOpen = true"
      >
        <MagnifyingGlassIcon class="w-6 h-6" />
      </div>
    </div>
    <main class="lg:pl-64">
      <slot />
    </main>
  </div>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import {
  BuildingOfficeIcon,
  RectangleStackIcon,
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/vue/24/solid";
import QuickSearch from "@/components/layout/QuickSearch.vue";
import SettingsDropdown from "@/components/layout/SettingsDropdown.vue";
import SidebarContent from "@/components/layout/SidebarContent.vue";

export default {
  props: {
    hideProjectsTab: {
      type: Boolean,
    },
    content: {
      type: Array,
    },
    currentSidebarTab: {
      type: String,
    },
  },
  components: {
    MagnifyingGlassIcon,
    SettingsDropdown,
    SidebarContent,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    XMarkIcon,
    ChevronDownIcon,
    RectangleStackIcon,
    BuildingOfficeIcon,
    QuickSearch,
  },
  data() {
    return {
      chevronDownIcon: true,
      sidebarOpen: false,
      open: false,
      isQuickSearchOpen: false,
    };
  },
};
</script>
