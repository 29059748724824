<template>
  <ReportFormSection number="2" :label="t('analytics.reports.filters')">
    <div class="flex gap-2 flex-col">
      <ReportUnitValueTypeFilterField
        :filter="filters.unit_value_type"
        @change="emit('change', { filters: { ...filters, unit_value_type: $event } })"
      />
      <ReportLocationFilterField
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection number="3" :label="t('analytics.reports.aggregation')" :defaultOpen="false">
    <SelectList
      :options="aggregationOptions"
      :defaultSelected="aggregation"
      :onUpdate:selected="handleAggregationChange"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import SelectList from "@/components/forms/SelectList.vue";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { Report } from "@/types/Report";
import {
  UnitValuesAggregation,
  UnitValuesReportConfig,
  UnitValuesReportFilters,
} from "@/types/reports/PlotUnitValues";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import ReportUnitValueTypeFilterField from "@/views/reports/filters/ReportUnitValueTypeFilterField.vue";

const props = defineProps<{
  filters: UnitValuesReportFilters;
  config: UnitValuesReportConfig;
  aggregation: UnitValuesAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();

const aggregationOptions = computed(() => {
  const availableHierarchyTags = Array.from(
    new Set(props.hierarchyTags.map((tag) => tag.type).filter((tag) => tag !== "component")),
  ).sort((a, b) =>
    a === "building" ? -1 : b === "building" ? 1 : a === "level" ? -1 : b === "level" ? 1 : 0,
  );
  return availableHierarchyTags.map((tag) => ({
    name: t(`analytics.reports.by_${tag}`),
    value: tag,
  }));
});

const handleAggregationChange = (aggregation: UnitValuesAggregation) => {
  emit("change", { aggregation });
};
</script>
