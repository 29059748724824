<template>
  <div
    class="flex justify-center items-center h-screen"
    v-if="!authResolved && !$store.state.isSessionExpired"
  >
    <LoadingSpinner />
  </div>
  <div id="wrapper" v-if="authResolved && !$store.state.isSessionExpired">
    <router-view :key="$route.path" />
  </div>
  <ConfirmationModal />
  <SessionExpired v-if="$store.state.isSessionExpired" />
  <div id="tooltip"></div>
</template>

<script lang="ts" setup>
import "video.js/dist/video-js.css";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import "@/assets/styles/custom.css";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SessionExpired from "@/components/other/SessionExpired.vue";
import { useRefreshSession, useSaveReturnUrl } from "@/composables/auth";
import logger from "@/services/logger";

const authResolved = ref(false);
const router = useRouter();
const route = useRoute();

const refreshSession = useRefreshSession();
const saveReturnUrl = useSaveReturnUrl();

onMounted(async () => {
  let session: unknown | undefined = undefined;
  try {
    session = await refreshSession();
  } catch (error) {
    logger.error("Unable to refresh session", error as Error);
  } finally {
    if (!session) {
      await router.isReady();
      if (route.meta.requireLogin) {
        saveReturnUrl();

        router.replace("/log-in");
      }
    }
    setTimeout(() => {
      authResolved.value = true;
    }, 0);
  }
});
</script>
