import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { SiteActivityDateResponse } from "@/types/SiteActivity";

const loadSiteActivityDate = (
  customerName: string,
  siteId: string,
  date: string | null,
): Promise<SiteActivityDateResponse> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  return apiClient
    .get<void, AxiosResponse<SiteActivityDateResponse>>(
      `/site_activity/date/${customerName}/${siteId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

export default {
  loadSiteActivityDate,
};
